import { Vec3 } from 'curtainsjs';
import {
  EXPONENTIAL_WEIGHTS_9,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
} from '../ShaderHelpers.js';

const bloomShader = `#version 300 es
precision mediump float;

in vec3 vVertexPosition;
in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform sampler2D uBgTexture;
uniform float uAmount;
uniform float uIntensity;
uniform float uExposure;
uniform float uDirection;
uniform int uPass;
uniform int uFinal;
uniform vec3 uTint;
uniform float uTime;
${UNIVERSAL_UNIFORMS}

out vec4 fragColor;

float random(vec2 seed) {
  return fract(sin(dot(seed.xy, vec2(12.9898, 78.233))) * 43758.5453);
}

float luma(vec4 color) {
  return dot(color.rgb, vec3(0.299, 0.587, 0.114));
}

${EXPONENTIAL_WEIGHTS_9}

vec4 blur(vec2 uv, bool vertical, float radius, bool diamond) {
  vec4 color = vec4(0.0);
  float total_weight = 0.0;
  float aspectRatio = uResolution.x/uResolution.y;

  vec2 dir;
  if (diamond) {
    dir = vertical ? vec2(1, 1) : vec2(1, -1);
  } else {
    dir = vertical ? vec2(0, 1) : vec2(1, 0);
  }
  
  dir *= vec2(uDirection, 1. - uDirection);
  dir.x /= aspectRatio;
  
  vec4 center = texture(uTexture, uv);
  float center_weight = getExponentialWeight(0);
  color += center * center_weight;
  total_weight += center_weight;

  radius *= uAmount;
  
  for (int i = 1; i <= 8; i++) {
    float weight = getExponentialWeight(i);
    float offset = mix(0.015, 0.025, radius) * float(i)/8.;
    
    vec4 sample1 = texture(uTexture, uv + offset * dir);
    vec4 sample2 = texture(uTexture, uv - offset * dir);
    
    color += (sample1 + sample2) * weight;
    total_weight += 2.0 * weight;
  }

  return color / total_weight;
}

vec4 thresholdPass(vec4 color) {
  float gamma = 2.2;
  color.rgb = pow(color.rgb, vec3(1.0/gamma));
  color.rgb = 1.2 * (color.rgb - 0.5) + 0.5;
  vec4 bloom = color * smoothstep(uExposure - 0.1, uExposure, luma(color));
  return vec4(bloom.rgb, color.a);
}

vec4 blurPass(vec2 uv, bool vertical, float radius, float intensity, bool diamond) {
  float dither = (random(gl_FragCoord.xy) - 0.5) / 255.0;
  vec4 blurred = blur(uv, vertical, radius, diamond);
  blurred.rgb += dither;
  if(vertical && uPass != 6) {
    return (thresholdPass(texture(uBgTexture, uv)) * 0.5 + blurred * intensity);
  } else {
    return blurred;
  }
}

vec4 finalPass(vec4 bloomColor) {
  float dither = (random(gl_FragCoord.xy) - 0.5) / 255.0;
  bloomColor.rgb *= uTint;
  bloomColor.rgb += dither;
  vec4 sceneColor = texture(uBgTexture, vTextureCoord);
  vec4 finalColor = mix(sceneColor, sceneColor + bloomColor, uIntensity * 1.75);
  return finalColor;
}

vec4 getColor(vec4 color) {
  switch(uPass) {
    case 0: return thresholdPass(color); break;
    case 1: return blurPass(vTextureCoord, false, 40., 1.25, true); break;
    case 2: return blurPass(vTextureCoord, true, 40., 1.25, true); break;
    case 3: return blurPass(vTextureCoord, false, 15., 1.1, false); break;
    case 4: return blurPass(vTextureCoord, true, 15., 1.1, false); break;
    case 5: return blurPass(vTextureCoord, false, 7.5, 1., true); break;
    case 6: return blurPass(vTextureCoord, true, 7.5, 1., true); break;
    case 7: return finalPass(color); break;
  }
}

void main() {	
  vec2 uv = vTextureCoord;
  vec4 color = texture(uTexture, uv);

  // #ifelseopen
  if(uIntensity == 0.00) {
    fragColor = texture(uBgTexture, uv);
    return;
  }
  // #ifelseclose
    
  fragColor = getColor(color);
}
`;

const bloomParams = {
  fragmentShader: bloomShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.25,
    },
    pass: {
      name: 'uPass',
      type: '1i',
      value: 0,
    },
    direction: {
      name: 'uDirection',
      type: '1f',
      value: 0.5,
    },
    intensity: {
      name: 'uIntensity',
      type: '1f',
      value: 0.5,
    },
    exposure: {
      name: 'uExposure',
      type: '1f',
      value: 0.5,
    },
    final: {
      name: 'uFinal',
      type: '1i',
      value: 0,
    },
    tint: {
      name: 'uTint',
      type: '3f',
      value: new Vec3(1),
    },
    ...universalUniformParams,
  },
};

export const BLOOM = {
  id: 'bloom',
  label: 'Bloom',
  params: bloomParams,
  passes: [
    { prop: 'pass', value: 1, downSample: 0.5 },
    { prop: 'pass', value: 2, downSample: 0.5 },
    { prop: 'pass', value: 3, downSample: 0.25 },
    { prop: 'pass', value: 4, downSample: 0.25 },
    { prop: 'pass', value: 5, downSample: 0.5 },
    { prop: 'pass', value: 6, downSample: 0.5 },
    { prop: 'pass', value: 7, includeBg: true },
  ],
  aspectRatio: 1,
  properties: {
    amount: {
      label: 'Radius',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the size of the bloom',
    },
    intensity: {
      label: 'Intensity',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the amount of bloom',
    },
    exposure: {
      label: 'Threshold',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Sets the brightness threshold at which to bloom',
    },
    tint: {
      label: 'Tint',
      value: new Vec3(1),
      output: 'color',
    },
    direction: {
      label: 'Skew',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Skews the bloom on the X or Y axis',
    },
  },
};
