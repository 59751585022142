<script>
  import { StudioStore } from "../stores/StudioStore";
  import Icon from "./Icon.vue";
  import DropdownMenu from "./DropdownMenu.vue";
  import SliderParam from "./SliderParam.vue";
  import InputField from "./InputField.vue";
  import ParamLabel from "./ParamLabel.vue";
  import ColorInput from "./ColorInput.vue";
  import RadioToggle from "./RadioToggle.vue";
  import { hexToRgb} from '../scripts/ColorHelpers.js';
  import { EASING_FUNC } from "../scripts/Constants";
  import Button from "./Button.vue";
  import EasingIcon from "./EasingIcon.vue";
  import { Vec2, Vec3 } from 'curtainsjs';
  
  export default {
    components: {
      Icon,
      DropdownMenu,
      SliderParam,
      InputField,
      ParamLabel,
      ColorInput,
      Button,
      RadioToggle,
      EasingIcon
    },
    data() {
      return {
        duration: 250,
        delay: 0,
        ease: 'easeInOutQuad',
        type: 'appear',
        options: EASING_FUNC,
        properties: {},
        loopingOptions: {
          none: 'None',
          forwards: 'Forwards',
          reverse: 'Reverse',
        }
      }
    },
    watch: {
      "stateEffect.transition.ease"() {
        this.preview();
      },
      item() {
        if(!this.item) {
          StudioStore.state.openStateEffect = null;
        }
      },
    },
    mounted() {
      document.addEventListener("mousedown", this.handleClickOutside);
    },
    beforeUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
    },
    computed: {
      item() {return this.getStateEffectItem()},
      stateEffect() { return this.item?.states?.appear.find(n => n.id === StudioStore.state.openStateEffect?.id)},
      props() {return this.getValidProps()},
      params() {return this.item.getParams()},
      propParams() { return this.params.properties[this.stateEffect.prop]},
      offsetTop() { return `${this.stateEffect.offsetY - 15}px`},
      progressStyle() {
        return {
          width: (this.stateEffect.progress * 100) + '%'
        }
      },
      isWidthOrHeight() {
        return this.stateEffect.prop === 'width' || this.stateEffect.prop === 'height';
      },
      currentMode() {
        return this.stateEffect.prop === 'width' ? 
          this.item.widthMode : 
          this.item.heightMode;
      }
    },
    methods: {
      getValidProps() {
        let uniforms = this.params?.params.uniforms;
        for(let prop in this.params.properties) {
          if(uniforms[prop]) {
            if(uniforms[prop].type !== '1i' && !this.params.properties[prop].eventDisabled) {
              this.properties[prop] = this.params.properties[prop].specificLabel || this.params.properties[prop].label
            } 
          } else if (!this.params.properties[prop].eventDisabled) {
            this.properties[prop] = this.params.properties[prop].specificLabel || this.params.properties[prop].label
          }
        }
        return this.properties;
      },
      getStateEffectItem() {
        const appearItems = StudioStore.state.history.filter(n => n.states?.appear);
        let mapped = appearItems.map(n => {
          return {
            id: n.local.id,
            events: n.states.appear
          }
        });
        return StudioStore.state.history.find(n => n.local.id === mapped.find(n => n.events.find(n => n.id === StudioStore.state.openStateEffect?.id))?.id);
      },
      handleClickOutside(event) {
        if (!this.$el.contains(event.target) && !event.target.classList.contains('brush')) {
          setTimeout(() => {
            StudioStore.state.openStateEffect = null;
          }, 1);
        }
      },
      preview() {
        if(this.item) {
          this.item.getPlanes().forEach(plane => {
            plane.userData.createdAt = performance.now();
          });
          if(this.item.states) {
            this.item.states.appear.forEach(effect => {
              effect.resetState();
            });
          }
        }
      },
      handleChange(value, axis) {
        if(value !== undefined && !value.target && axis) {
          this.stateEffect.value[axis] = value;
        }
        StudioStore.saveDesignDebounced(null, true);
      },
      handlePropChange() {
        if(this.stateEffect && this.item) {
          const value = this.item[this.stateEffect.prop];
          if (value?.type === 'Vec2') {
            this.stateEffect.value = new Vec2(value._x, value._y);
          } else if (value?.type === 'Vec3') {
            this.stateEffect.value = new Vec3(value._x, value._y, value._z);
          } else if (Array.isArray(value)) {
            let rgb = hexToRgb(value[0]);
            this.stateEffect.value = new Vec3(...rgb.map(n => n/255));
          } else {
            this.stateEffect.value = value;
          }
          StudioStore.saveDesignDebounced(null, true);
        }
      },
      handleSwatchClick() {
        StudioStore.state.colorPicker = { item: this.stateEffect, prop: 'value', right: '63.5rem' };
      },
    }
  };
</script>

<template>
  
  <div v-if="item && stateEffect" class="modal modal__pop-in modal__state-effect" :style="{top: offsetTop}">
    <div class="parameter parameter__block mb-3">
      <label class="parameter-label flex align-center">Appear <Icon icon="info" size="14" class="ml-1" tooltip="This property will animate from the initial state defined below to the default state when this scene appears on the page." /></label>
    </div>
    <div class="parameter parameter__block">
      <ParamLabel 
        label="Property"
        tooltip="The property which the appear event will affect. Only one event of each type (Scroll/Appear) is allowed per property."
      >Property</ParamLabel>
      <DropdownMenu
        v-model="stateEffect.prop"
        @change="handlePropChange"
        :options="props"
      ></DropdownMenu>
    </div>

    <div class="parameter parameter__block" v-if="(propParams.control !== 'rotation' && propParams.value.type === 'Vec3' || propParams.value.length) && stateEffect.prop !== 'blendMode'">
      <ParamLabel
        label="Initial value"
        tooltip="This is the starting value for this property when the scene appears."
      >Initial value</ParamLabel>
      <ColorInput
        :fill="stateEffect.value"
        :vec3="stateEffect.value.type"
        @click-swatch="handleSwatchClick"
      ></ColorInput>
    </div>
    <div v-else-if="stateEffect.value.type === 'Vec2'"  class="parameter parameter__block">
      <ParamLabel
        label="Initial value"
        tooltip="This is the starting value for this property when the scene appears."
      >Initial value</ParamLabel>
      <div class="coords-input-group">
        <InputField
          :label="'X'"
          :value="stateEffect.value.x"
          output="percent"
          :slider="true"
          @change="handleChange($event, 'x')"
        />
        <InputField
          :label="'Y'"
          :value="stateEffect.value.y"
          output="percent"
          :slider="true"
          @change="handleChange($event, 'y')"
        />
      </div>
    </div>
    <div v-else-if="stateEffect.value.type === 'Vec3' && propParams.control === 'rotation'"  class="parameter parameter__block">
      <ParamLabel
        label="Initial value"
        tooltip="This is the starting value for this property when the scene appears."
      >Initial value</ParamLabel>
      <div class="coords-input-group">
        <InputField
          :label="'X'"
          :value="stateEffect.value.x"
          :output="propParams.output"
          :slider="true"
          @change="handleChange($event, 'x')"
        />
        <InputField
          :label="'Y'"
          :value="stateEffect.value.y"
          :output="propParams.output"
          :slider="true"
          @change="handleChange($event, 'y')"
        />
        <InputField
          :label="'Z'"
          :value="stateEffect.value.z"
          :output="propParams.output"
          :slider="true"
          @change="handleChange($event, 'z')"
        />
      </div>
    </div>
    <template v-else>
      <SliderParam
        v-if="!isWidthOrHeight"
        v-model="stateEffect.value"
        label="Initial value"
        tooltip="This is the starting value for this property when the scene appears."
        @update="handleChange"
        :min="propParams.min"
        :max="propParams.max"
        :step="propParams.step"
        :output="propParams.output"
      ></SliderParam>

      <SliderParam
        v-else
        v-model="stateEffect.value"
        label="Initial value"
        tooltip="This is the starting value for this property when the scene appears."
        @update="handleChange"
        :min="0"
        :max="currentMode === 'percent' ? 1 : 1000"
        :step="currentMode === 'percent' ? 0.01 : 1"
        :output="currentMode === 'percent' ? 'percent' : 'pixels'"
      ></SliderParam>
    </template>
    <div class="parameter parameter__block">
      <label class="icon-label slider-label">Ease</label>
      <div class="flex align-center">
        <DropdownMenu
          v-model="stateEffect.transition.ease"
          @change="handleChange"
          :options="options"
          :rolloverPreview="true"
        ></DropdownMenu>
      </div>
    </div>
    <div class="parameter parameter__block">
      <label class="icon-label slider-label">Duration</label>
      <SliderParam
        v-model="stateEffect.transition.duration"
        min="0"
        max="1000"
        step="25"
        output="milliseconds"
        @update="handleChange"
      ></SliderParam>
    </div>
    <div class="parameter parameter__block">
      <label class="icon-label slider-label">Delay</label>
      <SliderParam
        v-model="stateEffect.transition.delay"
        min="0"
        max="2500"
        step="25"
        output="milliseconds"
        @update="handleChange"
      ></SliderParam>
    </div>
    <!-- <div class="parameter parameter__block">
      <label class="icon-label slider-label">Looping</label>
      <div class="flex align-center">
        <DropdownMenu
          v-model="stateEffect.looping"
          @change="handleChange"
          :options="loopingOptions"
          :rolloverPreview="true"
        ></DropdownMenu>
      </div>
    </div> -->
    <div class="parameter parameter__block">
      <Button class="button secondary w-100 relative" @click="preview">
        <div class="button-preview-progress" :style="progressStyle"></div>
        <span class="relative">Preview</span>
      </Button>
    </div>
  </div>

</template>


<style scoped lang="scss">

.modal__state-effect {
  position: absolute;
  right: 29rem;
  top: 5.9rem;
  left: unset;
}

.button-preview-progress {
  position: absolute;
  height: 100%;
  left: 0;
  background-color: var(--accent-color);
  z-index: 0;
}

</style>