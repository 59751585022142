import { Vec2 } from 'curtainsjs';

export const FLOATING_POINT = 'half-float';

const BLEND_MODES = {
  NORMAL: 'src',
  ADD: 'src + dst',
  SUBTRACT: 'src - dst',
  MULTIPLY: 'src * dst',
  SCREEN: '1. - (1. - src) * (1. - dst)',
  OVERLAY: `vec3((dst.x <= 0.5) ? (2.0 * src.x * dst.x) : (1.0 - 2.0 * (1.0 - dst.x) * (1.0 - src.x)), (dst.y <= 0.5) ? (2.0 * src.y * dst.y) : (1.0 - 2.0 * (1.0 - dst.y) * (1.0 - src.y)), (dst.z <= 0.5) ? (2.0 * src.z * dst.z) : (1.0 - 2.0 * (1.0 - dst.z) * (1.0 - src.z)))`,
  DARKEN: 'min(src, dst)',
  LIGHTEN: 'max(src, dst)',
  COLOR_DODGE: `vec3((src.x == 1.0) ? 1.0 : min(1.0, dst.x / (1.0 - src.x)), (src.y == 1.0) ? 1.0 : min(1.0, dst.y / (1.0 - src.y)), (src.z == 1.0) ? 1.0 : min(1.0, dst.z / (1.0 - src.z)))`,
  COLOR_BURN: `vec3((src.x == 0.0) ? 0.0 : (1.0 - ((1.0 - dst.x) / src.x)), (src.y == 0.0) ? 0.0 : (1.0 - ((1.0 - dst.y) / src.y)), (src.z == 0.0) ? 0.0 : (1.0 - ((1.0 - dst.z) / src.z)))`,
  LINEAR_BURN: '(src + dst) - 1.0',
  HARD_LIGHT: `vec3((src.x <= 0.5) ? (2.0 * src.x * dst.x) : (1.0 - 2.0 * (1.0 - src.x) * (1.0 - dst.x)), (src.y <= 0.5) ? (2.0 * src.y * dst.y) : (1.0 - 2.0 * (1.0 - src.y) * (1.0 - dst.y)),  (src.z <= 0.5) ? (2.0 * src.z * dst.z) : (1.0 - 2.0 * (1.0 - src.z) * (1.0 - dst.z)))`,
  SOFT_LIGHT: `vec3((src.x <= 0.5) ? (dst.x - (1.0 - 2.0 * src.x) * dst.x * (1.0 - dst.x)) : (((src.x > 0.5) && (dst.x <= 0.25)) ? (dst.x + (2.0 * src.x - 1.0) * (4.0 * dst.x * (4.0 * dst.x + 1.0) * (dst.x - 1.0) + 7.0 * dst.x)) : (dst.x + (2.0 * src.x - 1.0) * (sqrt(dst.x) - dst.x))), (src.y <= 0.5) ? (dst.y - (1.0 - 2.0 * src.y) * dst.y * (1.0 - dst.y)) : (((src.y > 0.5) && (dst.y <= 0.25)) ? (dst.y + (2.0 * src.y - 1.0) * (4.0 * dst.y * (4.0 * dst.y + 1.0) * (dst.y - 1.0) + 7.0 * dst.y)) : (dst.y + (2.0 * src.y - 1.0) * (sqrt(dst.y) - dst.y))), (src.z <= 0.5) ? (dst.z - (1.0 - 2.0 * src.z) * dst.z * (1.0 - dst.z)) : (((src.z > 0.5) && (dst.z <= 0.25)) ? (dst.z + (2.0 * src.z - 1.0) * (4.0 * dst.z * (4.0 * dst.z + 1.0) * (dst.z - 1.0) + 7.0 * dst.z)) : (dst.z + (2.0 * src.z - 1.0) * (sqrt(dst.z) - dst.z))))`,
  DIFFERENCE: 'abs(dst - src)',
  EXCLUSION: 'src + dst - 2.0 * src * dst',
  LINEAR_LIGHT: '2.0 * src + dst - 1.0',
  PIN_LIGHT: `vec3((src.x > 0.5) ? max(dst.x, 2.0 * (src.x - 0.5)) : min(dst.x, 2.0 * src.x), (src.x > 0.5) ? max(dst.y, 2.0 * (src.y - 0.5)) : min(dst.y, 2.0 * src.y), (src.z > 0.5) ? max(dst.z, 2.0 * (src.z - 0.5)) : min(dst.z, 2.0 * src.z))`,
  VIDID_LIGHT: `vec3((src.x <= 0.5) ? (1.0 - (1.0 - dst.x) / (2.0 * src.x)) : (dst.x / (2.0 * (1.0 - src.x))), (src.y <= 0.5) ? (1.0 - (1.0 - dst.y) / (2.0 * src.y)) : (dst.y / (2.0 * (1.0 - src.y))), (src.z <= 0.5) ? (1.0 - (1.0 - dst.z) / (2.0 * src.z)) : (dst.z / (2.0 * (1.0 - src.z))))`,
};

let conditionals = '';

Object.keys(BLEND_MODES).forEach((mode, index) => {
  conditionals += `
    if(blendMode == ${index}) {
      return ${BLEND_MODES[mode]};
    }
  `;
});

export const BLEND = `
  vec3 blend (int blendMode, vec3 src, vec3 dst) {
    ${conditionals} 
  }
`;

export const EASING_FUNCTIONS = {
  LINEAR: 't',
  EASE_IN_QUAD: 't * t',
  EASE_OUT_QUAD: 't * (2.0 - t)',
  EASE_IN_OUT_QUAD: 't < 0.5 ? 2.0 * t * t : -1.0 + (4.0 - 2.0 * t) * t',
  EASE_IN_CUBIC: 't * t * t',
  EASE_OUT_CUBIC: '--t * t * t + 1.0',
  EASE_IN_OUT_CUBIC: 't < 0.5 ? 4.0 * t * t * t : (t - 1.0) * (2.0 * t - 2.0) * (2.0 * t - 2.0) + 1.0',
  EASE_IN_QUART: 't * t * t * t',
  EASE_OUT_QUART: '1.0 - (--t) * t * t * t',
  EASE_IN_OUT_QUART: 't < 0.5 ? 8.0 * t * t * t * t : 1.0 - 8.0 * (--t) * t * t * t',
  EASE_IN_QUINT: 't * t * t * t * t',
  EASE_OUT_QUINT: '1.0 + (--t) * t * t * t * t',
  EASE_IN_OUT_QUINT: 't < 0.5 ? 16.0 * t * t * t * t * t : 1.0 + 16.0 * (--t) * t * t * t * t',
  EASE_IN_CIRC: '1.0 - sqrt(1.0 - t * t)',
  EASE_OUT_CIRC: 'sqrt((2.0 - t) * t)',
  EASE_IN_OUT_CIRC:
    't < 0.5 ? (1.0 - sqrt(1.0 - 4.0 * t * t)) / 2.0 : (sqrt(-((2.0 * t) - 3.0) * ((2.0 * t) - 1.0)) + 1.0) / 2.0',
  EASE_IN_EXPO: 't == 0.0 ? 0.0 : pow(2.0, 10.0 * (t - 1.0))',
  EASE_OUT_EXPO: 't == 1.0 ? 1.0 : 1.0 - pow(2.0, -10.0 * t)',
  EASE_IN_OUT_EXPO:
    't == 0.0 ? 0.0 : t == 1.0 ? 1.0 : t < 0.5 ? pow(2.0, (20.0 * t) - 10.0) / 2.0 : (2.0 - pow(2.0, -20.0 * t + 10.0)) / 2.0',
  EASE_IN_SINE: '1.0 - cos((t * 3.141592654) / 2.0)',
  EASE_OUT_SINE: 'sin((t * 3.141592654) / 2.0)',
  EASE_IN_OUT_SINE: '-(cos(3.141592654 * t) - 1.0) / 2.0',
  EASE_IN_ELASTIC:
    't == 0.0 ? 0.0 : t == 1.0 ? 1.0 : -pow(2.0, 10.0 * t - 10.0) * sin((t * 10.0 - 10.75) * ((2.0 * 3.141592654) / 3.0))',
  EASE_OUT_ELASTIC:
    't == 0.0 ? 0.0 : t == 1.0 ? 1.0 : pow(2.0, -10.0 * t) * sin((t * 10.0 - 0.75) * ((2.0 * 3.141592654) / 3.0)) + 1.0',
  EASE_IN_OUT_ELASTIC:
    't == 0.0 ? 0.0 : t == 1.0 ? 1.0 : t < 0.5 ? -(pow(2.0, 20.0 * t - 10.0) * sin((20.0 * t - 11.125) * ((2.0 * 3.141592654) / 4.5))) / 2.0 : (pow(2.0, -20.0 * t + 10.0) * sin((20.0 * t - 11.125) * ((2.0 * 3.141592654) / 4.5))) / 2.0 + 1.0',
};

let easingConditionals = '';

Object.keys(EASING_FUNCTIONS).forEach((func, index) => {
  easingConditionals += `case ${index}: return ${EASING_FUNCTIONS[func]}; break;`;
  if (index < Object.keys(EASING_FUNCTIONS).length - 1) {
    easingConditionals += `\n`;
  }
});

export const EASE = `
  float ease (int easingFunc, float t) {
    switch(uEasing) {
      ${easingConditionals}
      default: return t; break;
    }
  }
`;

export const DISPLACE = `
  vec4 displacement (vec2 st, sampler2D tex, vec4 bg, float alpha, float disp) {
    vec2 displaced = vec2(st.x - disp*0.1, st.y - disp*0.1) + (bg.rb - bg.g) * disp * 0.2;
    return texture(tex, displaced);
  }
`;

export const UNIVERSAL_UNIFORMS = `
  uniform sampler2D uMaskTexture;
  uniform int uIsMask;
  uniform float uTrackMouse;
  uniform vec2 uMousePos;
  uniform vec2 uResolution;
  uniform float uParentTrackMouse;
`;

export const universalUniformParams = {
  isMask: {
    name: 'uIsMask',
    type: '1i',
    value: 0,
  },
  mousePos: {
    name: 'uMousePos',
    type: '2f',
    value: new Vec2(0.5),
  },
  resolution: {
    name: 'uResolution',
    type: '2f',
    value: new Vec2(1080),
  },
  trackMouse: {
    name: 'uTrackMouse',
    type: '1f',
    value: 0,
  },
  parentTrackMouse: {
    name: 'uParentTrackMouse',
    type: '1f',
    value: 0,
  },
};

export const interactiveProperties = {
  trackMouse: {
    header: 'Interactivity',
    label: 'Track mouse',
    value: 0,
    min: 0,
    max: 1,
    step: 0.01,
    output: 'percent',
    tooltip: 'The amount to which the mouse cursor controls the position of the effect',
  },
  mouseMomentum: {
    label: 'Momentum',
    value: 0,
    min: 0,
    max: 1,
    step: 0.01,
    output: 'percent',
    tooltip: 'The amount of drag or delay of the track mouse effect',
  },
};

export const mixRadiusProperties = {
  easing: {
    label: 'Easing',
    header: 'Mix',
    value: 0,
    options: Object.keys(EASING_FUNCTIONS).map((n, index) => n.split('_').join(' ').toLowerCase()),
    tooltip: 'Easing function for the effect as it falls off from the position to the radius edge.',
    responsiveDisabled: true,
  },
  mixRadius: {
    label: 'Radius',
    value: 1,
    min: 0,
    max: 1,
    step: 0.01,
    output: 'percent',
    tooltip:
      'Controls the falloff distance from the effects position. Along with mix easing, no visible effect when Radius is 100%.',
  },
  mixRadiusInvert: {
    label: 'Invert',
    tooltip: 'Negates the effect as it approaches the mouse position',
    value: 0,
    classic: true,
    options: {
      0: 'Off',
      1: 'On',
    },
  },
};

export const UNIVERSAL_INIT = `
precision mediump float;
`;

export const UNIVERSAL_HEADER = `
  vec2 textureCoord = vTextureCoord;
`;

export const CLIP_EFFECT = `
  vec4 clipColor = texture(uTexture, vTextureCoord);
  
  if(clipColor.a == 0.) {
    fragColor = vec4(0);
    return;
  }
`;

export const computeFragColor = col => `
  // #ifelseopen
  if(uIsMask == 1) {
    vec2 maskPos = mix(vec2(0), (uMousePos - 0.5), uParentTrackMouse);
    vec4 maskColor = texture(uMaskTexture, vTextureCoord - maskPos);
    ${col} = ${col} * (maskColor.a * maskColor.a);
  }
  // #ifelseclose
  
  fragColor = ${col};
`;

export const computeFragColorNoAlpha = col => `
  fragColor = mix(base, ${col}, visible);
`;

export const vertexShader = `#version 300 es
precision mediump float;

in vec3 aVertexPosition;
in vec2 aTextureCoord;

uniform mat4 uMVMatrix;
uniform mat4 uPMatrix;
uniform mat4 uTextureMatrix;


out vec2 vTextureCoord;
out vec3 vVertexPosition;

void main() {
  gl_Position = uPMatrix * uMVMatrix * vec4(aVertexPosition, 1.0);
  vTextureCoord = (uTextureMatrix * vec4(aTextureCoord, 0.0, 1.0)).xy;
}`;

export const vertexShaderNoMatrix = `#version 300 es
precision mediump float;

in vec3 aVertexPosition;
in vec2 aTextureCoord;

uniform mat4 uMVMatrix;
uniform mat4 uPMatrix;
uniform mat4 uTextureMatrix;

out vec2 vTextureCoord;
out vec3 vVertexPosition;

void main() {
    gl_Position = uPMatrix * uMVMatrix * vec4(aVertexPosition, 1.0);
    vTextureCoord = aTextureCoord;
}`;

// export const computeFragColor = (col) => `
//   gl_FragColor = mix(bg, ${col} * ${col}.a * opacity, visible);
// `;

// export const computeFragColorNoAlpha = (col) => `
//   gl_FragColor = mix(bg, ${col} * opacity, visible);
// `;

export const GAUSSIAN_WEIGHTS_36 = `
  float getGaussianWeight(int index) {
    switch(index) {
        case 0: return 0.00094768;
        case 1: return 0.00151965;
        case 2: return 0.00237008;
        case 3: return 0.00359517;
        case 4: return 0.0053041;
        case 5: return 0.00761097;
        case 6: return 0.01062197;
        case 7: return 0.01441804;
        case 8: return 0.01903459;
        case 9: return 0.0244409;
        case 10: return 0.03052299;
        case 11: return 0.03707432;
        case 12: return 0.04379813;
        case 13: return 0.05032389;
        case 14: return 0.05623791;
        case 15: return 0.06112521;
        case 16: return 0.06461716;
        case 17: return 0.06643724;
        case 18: return 0.06643724;
        case 19: return 0.06461716;
        case 20: return 0.06112521;
        case 21: return 0.05623791;
        case 22: return 0.05032389;
        case 23: return 0.04379813;
        case 24: return 0.03707432;
        case 25: return 0.03052299;
        case 26: return 0.0244409;
        case 27: return 0.01903459;
        case 28: return 0.01441804;
        case 29: return 0.01062197;
        case 30: return 0.00761097;
        case 31: return 0.0053041;
        case 32: return 0.00359517;
        case 33: return 0.00237008;
        case 34: return 0.00151965;
        case 35: return 0.00094768;
        default: return 0.0;
    }
  }
`;

export const GAUSSIAN_WEIGHTS_24 = `
  float getGaussianWeight(int index) {
    switch(index) {
        case 0: return 0.7978845608028654;
        case 1: return 0.795118932516684;
        case 2: return 0.7868794322038799;
        case 3: return 0.7733362336056986;
        case 4: return 0.7547664553859864;
        case 5: return 0.7315447328280048;
        case 6: return 0.704130653528599;
        case 7: return 0.6730536454899063;
        case 8: return 0.6388960110447045;
        case 9: return 0.6022748643096089;
        case 10: return 0.5638237508206051;
        case 11: return 0.5241747061566029;
        case 12: return 0.48394144903828673;
        case 13: return 0.443704309411472;
        case 14: return 0.40399737110811773;
        case 15: return 0.36529817077804383;
        case 16: return 0.3280201493519873;
        case 17: return 0.29250790855907144;
        case 18: return 0.2590351913317835;
        case 19: return 0.2278053882403838;
        case 20: return 0.19895427758549736;
        case 21: return 0.17255463765302306;
        case 22: return 0.1486223271179862;
        case 23: return 0.12712341303392466;
        default: return 0.0;
    }
  }
`;

export const GAUSSIAN_WEIGHTS_9 = `
  float getGaussianWeight(int index) {
    switch(index) {
        case 0: return 0.2270270270; // Center
        case 1: return 0.1945945946; // First ring
        case 2: return 0.1216216216;
        case 3: return 0.0540540541;
        case 4: return 0.0162162162;
        case 5: return 0.0034324324;
        case 6: return 0.0005135135;
        case 7: return 0.0000540541;
        case 8: return 0.0000040541;
        default: return 0.0;
    }
  }
`;

export const EXPONENTIAL_WEIGHTS_9 = `
  float getExponentialWeight(int index) {
    switch(index) {
        case 0: return 1.0000000000; // Center
        case 1: return 0.7165313106; // First ring
        case 2: return 0.5134171190;
        case 3: return 0.3678794412;
        case 4: return 0.2636050919;
        case 5: return 0.1888756057;
        case 6: return 0.1353352832;
        case 7: return 0.0969670595;
        case 8: return 0.0694877157;
        default: return 0.0;
    }
  }
`;

export const GRADIENT_16 = `
  uniform vec3 uColor0;
  uniform vec3 uColor1;
  uniform vec3 uColor2;
  uniform vec3 uColor3;
  uniform vec3 uColor4;
  uniform vec3 uColor5;
  uniform vec3 uColor6;
  uniform vec3 uColor7;
  uniform vec3 uColor8;
  uniform vec3 uColor9;
  uniform vec3 uColor10;
  uniform vec3 uColor11;
  uniform vec3 uColor12;
  uniform vec3 uColor13;
  uniform vec3 uColor14;
  uniform vec3 uColor15;

  vec3 getColor(int index) {
      switch(index) {
          case 0: return uColor0;
          case 1: return uColor1;
          case 2: return uColor2;
          case 3: return uColor3;
          case 4: return uColor4;
          case 5: return uColor5;
          case 6: return uColor6;
          case 7: return uColor7;
          case 8: return uColor8;
          case 9: return uColor9;
          case 10: return uColor10;
          case 11: return uColor11;
          case 12: return uColor12;
          case 13: return uColor13;
          case 14: return uColor14;
          case 15: return uColor15;
          default: return vec3(0.0);
      }
  }
`;

export const SIMPLEX_2S_NOISE = `
  // CC0 license https://creativecommons.org/share-your-work/public-domain/cc0/
  // Borrowed from Stefan Gustavson's noise code

  vec4 permute(vec4 t) {
      return t * (t * 34.0 + 133.0);
  }

  vec3 grad(float hash) {
    vec3 cube = mod(floor(hash / vec3(1.0, 2.0, 4.0)), 2.0) * 2.0 - 1.0;
    
    vec3 cuboct = cube;

    float index0 = step(0.0, 1.0 - floor(hash / 16.0)); // 1.0 if index == 0, else 0.0
    float index1 = step(0.0, floor(hash / 16.0) - 1.0); // 1.0 if index == 1, else 0.0

    cuboct.x *= 1.0 - index0;
    cuboct.y *= 1.0 - index1;
    cuboct.z *= 1.0 - (1.0 - index0 - index1);

    float type = mod(floor(hash / 8.0), 2.0);
    vec3 rhomb = (1.0 - type) * cube + type * (cuboct + cross(cube, cuboct));

    vec3 grad = cuboct * 1.22474487139 + rhomb;

    grad *= (1.0 - 0.042942436724648037 * type) * 3.5946317686139184;

    return grad;
}


  // BCC lattice split up into 2 cube lattices
  vec4 bccNoiseDerivativesPart(vec3 X) {
      vec3 b = floor(X);
      vec4 i4 = vec4(X - b, 2.5);
      
      // Pick between each pair of oppposite corners in the cube.
      vec3 v1 = b + floor(dot(i4, vec4(.25)));
      vec3 v2 = b + vec3(1, 0, 0) + vec3(-1, 1, 1) * floor(dot(i4, vec4(-.25, .25, .25, .35)));
      vec3 v3 = b + vec3(0, 1, 0) + vec3(1, -1, 1) * floor(dot(i4, vec4(.25, -.25, .25, .35)));
      vec3 v4 = b + vec3(0, 0, 1) + vec3(1, 1, -1) * floor(dot(i4, vec4(.25, .25, -.25, .35)));
      
      // Gradient hashes for the four vertices in this half-lattice.
      vec4 hashes = permute(mod(vec4(v1.x, v2.x, v3.x, v4.x), 289.0));
      hashes = permute(mod(hashes + vec4(v1.y, v2.y, v3.y, v4.y), 289.0));
      hashes = mod(permute(mod(hashes + vec4(v1.z, v2.z, v3.z, v4.z), 289.0)), 48.0);
      
      // Gradient extrapolations & kernel function
      vec3 d1 = X - v1; vec3 d2 = X - v2; vec3 d3 = X - v3; vec3 d4 = X - v4;
      vec4 a = max(0.75 - vec4(dot(d1, d1), dot(d2, d2), dot(d3, d3), dot(d4, d4)), 0.0);
      vec4 aa = a * a; vec4 aaaa = aa * aa;
      vec3 g1 = grad(hashes.x); vec3 g2 = grad(hashes.y);
      vec3 g3 = grad(hashes.z); vec3 g4 = grad(hashes.w);
      vec4 extrapolations = vec4(dot(d1, g1), dot(d2, g2), dot(d3, g3), dot(d4, g4));
      
      // Derivatives of the noise
      vec3 derivative = -8.0 * mat4x3(d1, d2, d3, d4) * (aa * a * extrapolations)
          + mat4x3(g1, g2, g3, g4) * aaaa;
      
      // Return it all as a vec4
      return vec4(derivative, dot(aaaa, extrapolations));
  }

  // Rotates domain, but preserve shape. Hides grid better in cardinal slices.
  // Good for texturing 3D objects with lots of flat parts along cardinal planes.
  vec4 bccNoiseDerivatives_XYZ(vec3 X) {
      X = dot(X, vec3(2.0/3.0)) - X;
      
      vec4 result = bccNoiseDerivativesPart(X) + bccNoiseDerivativesPart(X + 144.5);
      
      return vec4(dot(result.xyz, vec3(2.0/3.0)) - result.xyz, result.w);
  }

  // Gives X and Y a triangular alignment, and lets Z move up the main diagonal.
  // Might be good for terrain, or a time varying X/Y plane. Z repeats.
  vec4 bccNoiseDerivatives_XYBeforeZ(vec3 X) {
      
      // Not a skew transform.
      mat3 orthonormalMap = mat3(
          0.788675134594813, -0.211324865405187, -0.577350269189626,
          -0.211324865405187, 0.788675134594813, -0.577350269189626,
          0.577350269189626, 0.577350269189626, 0.577350269189626);
      
      X = orthonormalMap * X;
      vec4 result = bccNoiseDerivativesPart(X) + bccNoiseDerivativesPart(X + 144.5);
      
      return vec4(result.xyz * orthonormalMap, result.w);
  }
`;

export const PERLIN_NOISE = `

float hash31(vec3 p3) {
    p3  = fract(p3 * vec3(0.1031, 0.11369, 0.13787));
    p3 += dot(p3, p3.yzx + 19.19);
    return -1.0 + 2.0 * fract((p3.x + p3.y) * p3.z);
}

vec3 hash33(vec3 p3) {
    p3 = fract(p3 * vec3(0.1031, 0.11369, 0.13787));
    p3 += dot(p3, p3.yxz + 19.19);
    return -1.0 + 2.0 * fract(vec3(
        (p3.x + p3.y) * p3.z,
        (p3.x + p3.z) * p3.y,
        (p3.y + p3.z) * p3.x
    ));
}

float perlin_noise(vec3 p) {
    vec3 pi = floor(p);
    vec3 pf = p - pi;

    vec3 w = pf * pf * (3.0 - 2.0 * pf);

    float n000 = dot(pf - vec3(0.0, 0.0, 0.0), hash33(pi + vec3(0.0, 0.0, 0.0)));
    float n100 = dot(pf - vec3(1.0, 0.0, 0.0), hash33(pi + vec3(1.0, 0.0, 0.0)));
    float n010 = dot(pf - vec3(0.0, 1.0, 0.0), hash33(pi + vec3(0.0, 1.0, 0.0)));
    float n110 = dot(pf - vec3(1.0, 1.0, 0.0), hash33(pi + vec3(1.0, 1.0, 0.0)));
    float n001 = dot(pf - vec3(0.0, 0.0, 1.0), hash33(pi + vec3(0.0, 0.0, 1.0)));
    float n101 = dot(pf - vec3(1.0, 0.0, 1.0), hash33(pi + vec3(1.0, 0.0, 1.0)));
    float n011 = dot(pf - vec3(0.0, 1.0, 1.0), hash33(pi + vec3(0.0, 1.0, 1.0)));
    float n111 = dot(pf - vec3(1.0, 1.0, 1.0), hash33(pi + vec3(1.0, 1.0, 1.0)));

    float nx00 = mix(n000, n100, w.x);
    float nx01 = mix(n001, n101, w.x);
    float nx10 = mix(n010, n110, w.x);
    float nx11 = mix(n011, n111, w.x);

    float nxy0 = mix(nx00, nx10, w.y);
    float nxy1 = mix(nx01, nx11, w.y);

    float nxyz = mix(nxy0, nxy1, w.z);

    return nxyz;
}

`;

export const SIMPLEX_NOISE = `
vec3 mod289(vec3 x) {
  return x - floor(x * (1.0 / 289.0)) * 289.0;
}

vec4 mod289(vec4 x) {
  return x - floor(x * (1.0 / 289.0)) * 289.0;
}

vec4 permute(vec4 x) {
     return mod289(((x*34.0)+10.0)*x);
}

vec4 taylorInvSqrt(vec4 r) {
  return 1.79284291400159 - 0.85373472095314 * r;
}

float snoise(vec3 v) { 
  const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
  const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);

  vec3 i  = floor(v + dot(v, C.yyy) );
  vec3 x0 =   v - i + dot(i, C.xxx) ;

  vec3 g = step(x0.yzx, x0.xyz);
  vec3 l = 1.0 - g;
  vec3 i1 = min( g.xyz, l.zxy );
  vec3 i2 = max( g.xyz, l.zxy );

  vec3 x1 = x0 - i1 + C.xxx;
  vec3 x2 = x0 - i2 + C.yyy; // 2.0*C.x = 1/3 = C.y
  vec3 x3 = x0 - D.yyy;      // -1.0+3.0*C.x = -0.5 = -D.y

  i = mod289(i); 
  vec4 p = permute( permute( permute( 
             i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
           + i.y + vec4(0.0, i1.y, i2.y, 1.0 )) 
           + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));

  float n_ = 0.142857142857; // 1.0/7.0
  vec3  ns = n_ * D.wyz - D.xzx;

  vec4 j = p - 49.0 * floor(p * ns.z * ns.z);  //  mod(p,7*7)

  vec4 x_ = floor(j * ns.z);
  vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)

  vec4 x = x_ *ns.x + ns.yyyy;
  vec4 y = y_ *ns.x + ns.yyyy;
  vec4 h = 1.0 - abs(x) - abs(y);

  vec4 b0 = vec4( x.xy, y.xy );
  vec4 b1 = vec4( x.zw, y.zw );

  vec4 s0 = floor(b0)*2.0 + 1.0;
  vec4 s1 = floor(b1)*2.0 + 1.0;
  vec4 sh = -step(h, vec4(0.0));

  vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
  vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;

  vec3 p0 = vec3(a0.xy,h.x);
  vec3 p1 = vec3(a0.zw,h.y);
  vec3 p2 = vec3(a1.xy,h.z);
  vec3 p3 = vec3(a1.zw,h.w);

  vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
  p0 *= norm.x;
  p1 *= norm.y;
  p2 *= norm.z;
  p3 *= norm.w;

  vec4 m = max(0.5 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
  m = m * m;
  return 105.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1), 
                                dot(p2,x2), dot(p3,x3) ) );
}`;

export const NOISED = `
vec2 hash( in vec2 x )  // replace this by something better
{
    const vec2 k = vec2( 0.3183099, 0.3678794 );
    x = x*k + k.yx;
    return -1.0 + 2.0*fract( 16.0 * k*fract( x.x*x.y*(x.x+x.y)) );
}
vec3 noised( in vec2 p ) {
    vec2 i = floor( p );
    vec2 f = fract( p );
    vec2 u = f*f*f*(f*(f*6.0-15.0)+10.0);
    vec2 du = 30.0*f*f*(f*(f-2.0)+1.0);
    
    vec2 ga = hash( i + vec2(0.0,0.0));
    vec2 gb = hash( i + vec2(1.0,0.0));
    vec2 gc = hash( i + vec2(0.0,1.0));
    vec2 gd = hash( i + vec2(1.0,1.0));
    
    float va = dot( ga, f - vec2(0.0,0.0));
    float vb = dot( gb, f - vec2(1.0,0.0));
    float vc = dot( gc, f - vec2(0.0,1.0));
    float vd = dot( gd, f - vec2(1.0,1.0));
    return vec3( va + u.x*(vb-va) + u.y*(vc-va) + u.x*u.y*(va-vb-vc+vd),   // value
                ga + u.x*(gb-ga) + u.y*(gc-ga) + u.x*u.y*(ga-gb-gc+gd) +  // derivatives
                du * (u.yx*(va-vb-vc+vd) + vec2(vb,vc) - va));
}
`;

export const OK_HSL = `
// Copyright(c) 2021 Björn Ottosson
  //
  // Permission is hereby granted, free of charge, to any person obtaining a copy of
  // this softwareand associated documentation files(the "Software"), to deal in
  // the Software without restriction, including without limitation the rights to
  // use, copy, modify, merge, publish, distribute, sublicense, and /or sell copies
  // of the Software, and to permit persons to whom the Software is furnished to do
  // so, subject to the following conditions :
  // The above copyright noticeand this permission notice shall be included in all
  // copies or substantial portions of the Software.
  // THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
  // IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
  // FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.IN NO EVENT SHALL THE
  // AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
  // LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
  // OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE
  // SOFTWARE.

  #define M_PI 3.1415926535897932384626433832795

  float cbrt( float x )
  {
      return sign(x)*pow(abs(x),1.0/3.0);
  }

  float srgb_transfer_function(float a)
  {
    return .0031308 >= a ? 12.92 * a : 1.055 * pow(a, .4166666666666667) - .055;
  }

  float srgb_transfer_function_inv(float a)
  {
    return .04045 < a ? pow((a + .055) / 1.055, 2.4) : a / 12.92;
  }

  vec3 linear_srgb_to_oklab(vec3 c)
  {
    float l = 0.4122214708 * c.r + 0.5363325363 * c.g + 0.0514459929 * c.b;
    float m = 0.2119034982 * c.r + 0.6806995451 * c.g + 0.1073969566 * c.b;
    float s = 0.0883024619 * c.r + 0.2817188376 * c.g + 0.6299787005 * c.b;

    float l_ = cbrt(l);
    float m_ = cbrt(m);
    float s_ = cbrt(s);

    return vec3(
      0.2104542553 * l_ + 0.7936177850 * m_ - 0.0040720468 * s_,
      1.9779984951 * l_ - 2.4285922050 * m_ + 0.4505937099 * s_,
      0.0259040371 * l_ + 0.7827717662 * m_ - 0.8086757660 * s_
    );
  }

  vec3 oklab_to_linear_srgb(vec3 c)
  {
    float l_ = c.x + 0.3963377774 * c.y + 0.2158037573 * c.z;
    float m_ = c.x - 0.1055613458 * c.y - 0.0638541728 * c.z;
    float s_ = c.x - 0.0894841775 * c.y - 1.2914855480 * c.z;

    float l = l_ * l_ * l_;
    float m = m_ * m_ * m_;
    float s = s_ * s_ * s_;

    return vec3(
      +4.0767416621 * l - 3.3077115913 * m + 0.2309699292 * s,
      -1.2684380046 * l + 2.6097574011 * m - 0.3413193965 * s,
      -0.0041960863 * l - 0.7034186147 * m + 1.7076147010 * s
    );
  }

  // Finds the maximum saturation possible for a given hue that fits in sRGB
  // Saturation here is defined as S = C/L
  // a and b must be normalized so a^2 + b^2 == 1
  float compute_max_saturation(float a, float b)
  {
    // Max saturation will be when one of r, g or b goes below zero.

    // Select different coefficients depending on which component goes below zero first
    float k0, k1, k2, k3, k4, wl, wm, ws;

    if (-1.88170328 * a - 0.80936493 * b > 1.)
    {
      // Red component
      k0 = +1.19086277; k1 = +1.76576728; k2 = +0.59662641; k3 = +0.75515197; k4 = +0.56771245;
      wl = +4.0767416621; wm = -3.3077115913; ws = +0.2309699292;
    }
    else if (1.81444104 * a - 1.19445276 * b > 1.)
    {
      // Green component
      k0 = +0.73956515; k1 = -0.45954404; k2 = +0.08285427; k3 = +0.12541070; k4 = +0.14503204;
      wl = -1.2684380046; wm = +2.6097574011; ws = -0.3413193965;
    }
    else
    {
      // Blue component
      k0 = +1.35733652; k1 = -0.00915799; k2 = -1.15130210; k3 = -0.50559606; k4 = +0.00692167;
      wl = -0.0041960863; wm = -0.7034186147; ws = +1.7076147010;
    }

    // Approximate max saturation using a polynomial:
    float S = k0 + k1 * a + k2 * b + k3 * a * a + k4 * a * b;

    // Do one step Halley's method to get closer
    // this gives an error less than 10e6, except for some blue hues where the dS/dh is close to infinite
    // this should be sufficient for most applications, otherwise do two/three steps 

    float k_l = +0.3963377774* a + 0.2158037573* b;
    float k_m = -0.1055613458* a - 0.0638541728* b;
    float k_s = -0.0894841775* a - 1.2914855480* b;

    {
      float l_ = 1.+ S * k_l;
      float m_ = 1.+ S * k_m;
      float s_ = 1.+ S * k_s;

      float l = l_ * l_ * l_;
      float m = m_ * m_ * m_;
      float s = s_ * s_ * s_;

      float l_dS = 3.* k_l * l_ * l_;
      float m_dS = 3.* k_m * m_ * m_;
      float s_dS = 3.* k_s * s_ * s_;

      float l_dS2 = 6.* k_l * k_l * l_;
      float m_dS2 = 6.* k_m * k_m * m_;
      float s_dS2 = 6.* k_s * k_s * s_;

      float f = wl * l + wm * m + ws * s;
      float f1 = wl * l_dS + wm * m_dS + ws * s_dS;
      float f2 = wl * l_dS2 + wm * m_dS2 + ws * s_dS2;

      S = S - f * f1 / (f1 * f1 - 0.5 * f * f2);
    }

    return S;
  }

  // finds L_cusp and C_cusp for a given hue
  // a and b must be normalized so a^2 + b^2 == 1
  vec2 find_cusp(float a, float b)
  {
    // First, find the maximum saturation (saturation S = C/L)
    float S_cusp = compute_max_saturation(a, b);

    // Convert to linear sRGB to find the first point where at least one of r,g or b >= 1:
    vec3 rgb_at_max = oklab_to_linear_srgb(vec3( 1, S_cusp * a, S_cusp * b ));
    float L_cusp = cbrt(1. / max(max(rgb_at_max.r, rgb_at_max.g), rgb_at_max.b));
    float C_cusp = L_cusp * S_cusp;

    return vec2( L_cusp , C_cusp );
  }

  // Finds intersection of the line defined by 
  // L = L0 * (1 - t) + t * L1;
  // C = t * C1;
  // a and b must be normalized so a^2 + b^2 == 1
  float find_gamut_intersection(float a, float b, float L1, float C1, float L0, vec2 cusp)
  {
    // Find the intersection for upper and lower half seprately
    float t;
    if (((L1 - L0) * cusp.y - (cusp.x - L0) * C1) <= 0.)
    {
      // Lower half

      t = cusp.y * L0 / (C1 * cusp.x + cusp.y * (L0 - L1));
    }
    else
    {
      // Upper half

      // First intersect with triangle
      t = cusp.y * (L0 - 1.) / (C1 * (cusp.x - 1.) + cusp.y * (L0 - L1));

      // Then one step Halley's method
      {
        float dL = L1 - L0;
        float dC = C1;

        float k_l = +0.3963377774 * a + 0.2158037573 * b;
        float k_m = -0.1055613458 * a - 0.0638541728 * b;
        float k_s = -0.0894841775 * a - 1.2914855480 * b;

        float l_dt = dL + dC * k_l;
        float m_dt = dL + dC * k_m;
        float s_dt = dL + dC * k_s;


        // If higher accuracy is required, 2 or 3 iterations of the following block can be used:
        {
          float L = L0 * (1. - t) + t * L1;
          float C = t * C1;

          float l_ = L + C * k_l;
          float m_ = L + C * k_m;
          float s_ = L + C * k_s;

          float l = l_ * l_ * l_;
          float m = m_ * m_ * m_;
          float s = s_ * s_ * s_;

          float ldt = 3. * l_dt * l_ * l_;
          float mdt = 3. * m_dt * m_ * m_;
          float sdt = 3. * s_dt * s_ * s_;

          float ldt2 = 6. * l_dt * l_dt * l_;
          float mdt2 = 6. * m_dt * m_dt * m_;
          float sdt2 = 6. * s_dt * s_dt * s_;

          float r = 4.0767416621 * l - 3.3077115913 * m + 0.2309699292 * s - 1.;
          float r1 = 4.0767416621 * ldt - 3.3077115913 * mdt + 0.2309699292 * sdt;
          float r2 = 4.0767416621 * ldt2 - 3.3077115913 * mdt2 + 0.2309699292 * sdt2;

          float u_r = r1 / (r1 * r1 - 0.5 * r * r2);
          float t_r = -r * u_r;

          float g = -1.2684380046 * l + 2.6097574011 * m - 0.3413193965 * s - 1.;
          float g1 = -1.2684380046 * ldt + 2.6097574011 * mdt - 0.3413193965 * sdt;
          float g2 = -1.2684380046 * ldt2 + 2.6097574011 * mdt2 - 0.3413193965 * sdt2;

          float u_g = g1 / (g1 * g1 - 0.5 * g * g2);
          float t_g = -g * u_g;

          float b = -0.0041960863 * l - 0.7034186147 * m + 1.7076147010 * s - 1.;
          float b1 = -0.0041960863 * ldt - 0.7034186147 * mdt + 1.7076147010 * sdt;
          float b2 = -0.0041960863 * ldt2 - 0.7034186147 * mdt2 + 1.7076147010 * sdt2;

          float u_b = b1 / (b1 * b1 - 0.5 * b * b2);
          float t_b = -b * u_b;

          t_r = u_r >= 0. ? t_r : 10000.;
          t_g = u_g >= 0. ? t_g : 10000.;
          t_b = u_b >= 0. ? t_b : 10000.;

          t += min(t_r, min(t_g, t_b));
        }
      }
    }

    return t;
  }

  float find_gamut_intersection(float a, float b, float L1, float C1, float L0)
  {
    // Find the cusp of the gamut triangle
    vec2 cusp = find_cusp(a, b);

    return find_gamut_intersection(a, b, L1, C1, L0, cusp);
  }

  vec3 gamut_clip_preserve_chroma(vec3 rgb)
  {
    if (rgb.r < 1. && rgb.g < 1. && rgb.b < 1. && rgb.r > 0. && rgb.g > 0. && rgb.b > 0.)
      return rgb;

    vec3 lab = linear_srgb_to_oklab(rgb);

    float L = lab.x;
    float eps = 0.00001;
    float C = max(eps, sqrt(lab.y * lab.y + lab.z * lab.z));
    float a_ = lab.y / C;
    float b_ = lab.z / C;

    float L0 = clamp(L, 0., 1.);

    float t = find_gamut_intersection(a_, b_, L, C, L0);
    float L_clipped = L0 * (1. - t) + t * L;
    float C_clipped = t * C;

    return oklab_to_linear_srgb(vec3( L_clipped, C_clipped * a_, C_clipped * b_ ));
  }

  vec3 gamut_clip_project_to_0_5(vec3 rgb)
  {
    if (rgb.r < 1. && rgb.g < 1. && rgb.b < 1. && rgb.r > 0. && rgb.g > 0. && rgb.b > 0.)
      return rgb;

    vec3 lab = linear_srgb_to_oklab(rgb);

    float L = lab.x;
    float eps = 0.00001;
    float C = max(eps, sqrt(lab.y * lab.y + lab.z * lab.z));
    float a_ = lab.y / C;
    float b_ = lab.z / C;

    float L0 = 0.5;

    float t = find_gamut_intersection(a_, b_, L, C, L0);
    float L_clipped = L0 * (1. - t) + t * L;
    float C_clipped = t * C;

    return oklab_to_linear_srgb(vec3( L_clipped, C_clipped * a_, C_clipped * b_ ));
  }

  vec3 gamut_clip_project_to_L_cusp(vec3 rgb)
  {
    if (rgb.r < 1. && rgb.g < 1. && rgb.b < 1. && rgb.r > 0. && rgb.g > 0. && rgb.b > 0.)
      return rgb;

    vec3 lab = linear_srgb_to_oklab(rgb);

    float L = lab.x;
    float eps = 0.00001;
    float C = max(eps, sqrt(lab.y * lab.y + lab.z * lab.z));
    float a_ = lab.y / C;
    float b_ = lab.z / C;

    // The cusp is computed here and in find_gamut_intersection, an optimized solution would only compute it once.
    vec2 cusp = find_cusp(a_, b_);

    float L0 = cusp.x;

    float t = find_gamut_intersection(a_, b_, L, C, L0);

    float L_clipped = L0 * (1. - t) + t * L;
    float C_clipped = t * C;

    return oklab_to_linear_srgb(vec3( L_clipped, C_clipped * a_, C_clipped * b_ ));
  }

  vec3 gamut_clip_adaptive_L0_0_5(vec3 rgb, float alpha)
  {
    if (rgb.r < 1. && rgb.g < 1. && rgb.b < 1. && rgb.r > 0. && rgb.g > 0. && rgb.b > 0.)
      return rgb;

    vec3 lab = linear_srgb_to_oklab(rgb);

    float L = lab.x;
    float eps = 0.00001;
    float C = max(eps, sqrt(lab.y * lab.y + lab.z * lab.z));
    float a_ = lab.y / C;
    float b_ = lab.z / C;

    float Ld = L - 0.5;
    float e1 = 0.5 + abs(Ld) + alpha * C;
    float L0 = 0.5 * (1. + sign(Ld) * (e1 - sqrt(e1 * e1 - 2. * abs(Ld))));

    float t = find_gamut_intersection(a_, b_, L, C, L0);
    float L_clipped = L0 * (1. - t) + t * L;
    float C_clipped = t * C;

    return oklab_to_linear_srgb(vec3( L_clipped, C_clipped * a_, C_clipped * b_ ));
  }

  vec3 gamut_clip_adaptive_L0_L_cusp(vec3 rgb, float alpha)
  {
    if (rgb.r < 1. && rgb.g < 1. && rgb.b < 1. && rgb.r > 0. && rgb.g > 0. && rgb.b > 0.)
      return rgb;

    vec3 lab = linear_srgb_to_oklab(rgb);

    float L = lab.x;
    float eps = 0.00001;
    float C = max(eps, sqrt(lab.y * lab.y + lab.z * lab.z));
    float a_ = lab.y / C;
    float b_ = lab.z / C;

    // The cusp is computed here and in find_gamut_intersection, an optimized solution would only compute it once.
    vec2 cusp = find_cusp(a_, b_);

    float Ld = L - cusp.x;
    float k = 2. * (Ld > 0. ? 1. - cusp.x : cusp.x);

    float e1 = 0.5 * k + abs(Ld) + alpha * C / k;
    float L0 = cusp.x + 0.5 * (sign(Ld) * (e1 - sqrt(e1 * e1 - 2. * k * abs(Ld))));

    float t = find_gamut_intersection(a_, b_, L, C, L0);
    float L_clipped = L0 * (1. - t) + t * L;
    float C_clipped = t * C;

    return oklab_to_linear_srgb(vec3( L_clipped, C_clipped * a_, C_clipped * b_ ));
  }

  float toe(float x)
  {
    float k_1 = 0.206;
    float k_2 = 0.03;
    float k_3 = (1. + k_1) / (1. + k_2);
    return 0.5 * (k_3 * x - k_1 + sqrt((k_3 * x - k_1) * (k_3 * x - k_1) + 4. * k_2 * k_3 * x));
  }

  float toe_inv(float x)
  {
    float k_1 = 0.206;
    float k_2 = 0.03;
    float k_3 = (1. + k_1) / (1. + k_2);
    return (x * x + k_1 * x) / (k_3 * (x + k_2));
  }

  vec2 to_ST(vec2 cusp)
  {
    float L = cusp.x;
    float C = cusp.y;
    return vec2( C / L, C / (1. - L) );
  }

  // Returns a smooth approximation of the location of the cusp
  // This polynomial was created by an optimization process
  // It has been designed so that S_mid < S_max and T_mid < T_max
  vec2 get_ST_mid(float a_, float b_)
  {
    float S = 0.11516993 + 1. / (
      +7.44778970 + 4.15901240 * b_
      + a_ * (-2.19557347 + 1.75198401 * b_
        + a_ * (-2.13704948 - 10.02301043 * b_
          + a_ * (-4.24894561 + 5.38770819 * b_ + 4.69891013 * a_
            )))
      );

    float T = 0.11239642 + 1. / (
      +1.61320320 - 0.68124379 * b_
      + a_ * (+0.40370612 + 0.90148123 * b_
        + a_ * (-0.27087943 + 0.61223990 * b_
          + a_ * (+0.00299215 - 0.45399568 * b_ - 0.14661872 * a_
            )))
      );

    return vec2( S, T );
  }

  vec3 get_Cs(float L, float a_, float b_)
  {
    vec2 cusp = find_cusp(a_, b_);

    float C_max = find_gamut_intersection(a_, b_, L, 1., L, cusp);
    vec2 ST_max = to_ST(cusp);
    
    // Scale factor to compensate for the curved part of gamut shape:
    float k = C_max / min((L * ST_max.x), (1. - L) * ST_max.y);

    float C_mid;
    {
      vec2 ST_mid = get_ST_mid(a_, b_);

      // Use a soft minimum function, instead of a sharp triangle shape to get a smooth value for chroma.
      float C_a = L * ST_mid.x;
      float C_b = (1. - L) * ST_mid.y;
      C_mid = 0.9 * k * sqrt(sqrt(1. / (1. / (C_a * C_a * C_a * C_a) + 1. / (C_b * C_b * C_b * C_b))));
    }

    float C_0;
    {
      // for C_0, the shape is independent of hue, so vec2 are constant. Values picked to roughly be the average values of vec2.
      float C_a = L * 0.4;
      float C_b = (1. - L) * 0.8;

      // Use a soft minimum function, instead of a sharp triangle shape to get a smooth value for chroma.
      C_0 = sqrt(1. / (1. / (C_a * C_a) + 1. / (C_b * C_b)));
    }

    return vec3( C_0, C_mid, C_max );
  }

  vec3 okhsl_to_srgb(vec3 hsl)
  {
    float h = hsl.x;
    float s = hsl.y;
    float l = hsl.z;

    if (l == 1.0)
    {
      return vec3( 1., 1., 1. );
    }

    else if (l == 0.)
    {
      return vec3( 0., 0., 0. );
    }

    float a_ = cos(2. * M_PI * h);
    float b_ = sin(2. * M_PI * h);
    float L = toe_inv(l);

    vec3 cs = get_Cs(L, a_, b_);
    float C_0 = cs.x;
    float C_mid = cs.y;
    float C_max = cs.z;

    float mid = 0.8;
    float mid_inv = 1.25;

    float C, t, k_0, k_1, k_2;

    if (s < mid)
    {
      t = mid_inv * s;

      k_1 = mid * C_0;
      k_2 = (1. - k_1 / C_mid);

      C = t * k_1 / (1. - k_2 * t);
    }
    else
    {
      t = (s - mid)/ (1. - mid);

      k_0 = C_mid;
      k_1 = (1. - mid) * C_mid * C_mid * mid_inv * mid_inv / C_0;
      k_2 = (1. - (k_1) / (C_max - C_mid));

      C = k_0 + t * k_1 / (1. - k_2 * t);
    }

    vec3 rgb = oklab_to_linear_srgb(vec3( L, C * a_, C * b_ ));
    return vec3(
      srgb_transfer_function(rgb.r),
      srgb_transfer_function(rgb.g),
      srgb_transfer_function(rgb.b)
    );
  }

  vec3 srgb_to_okhsl(vec3 rgb)
  {
    vec3 lab = linear_srgb_to_oklab(vec3(
      srgb_transfer_function_inv(rgb.r),
      srgb_transfer_function_inv(rgb.g),
      srgb_transfer_function_inv(rgb.b)
      ));

    float C = sqrt(lab.y * lab.y + lab.z * lab.z);
    float a_ = lab.y / C;
    float b_ = lab.z / C;

    float L = lab.x;
    float h = 0.5 + 0.5 * atan(-lab.z, -lab.y) / M_PI;

    vec3 cs = get_Cs(L, a_, b_);
    float C_0 = cs.x;
    float C_mid = cs.y;
    float C_max = cs.z;

    // Inverse of the interpolation in okhsl_to_srgb:

    float mid = 0.8;
    float mid_inv = 1.25;

    float s;
    if (C < C_mid)
    {
      float k_1 = mid * C_0;
      float k_2 = (1. - k_1 / C_mid);

      float t = C / (k_1 + k_2 * C);
      s = t * mid;
    }
    else
    {
      float k_0 = C_mid;
      float k_1 = (1. - mid) * C_mid * C_mid * mid_inv * mid_inv / C_0;
      float k_2 = (1. - (k_1) / (C_max - C_mid));

      float t = (C - k_0) / (k_1 + k_2 * (C - k_0));
      s = mid + (1. - mid) * t;
    }

    float l = toe(L);
    return vec3( h, s, l );
  }


  vec3 okhsv_to_srgb(vec3 hsv)
  {
    float h = hsv.x;
    float s = hsv.y;
    float v = hsv.z;

    float a_ = cos(2. * M_PI * h);
    float b_ = sin(2. * M_PI * h);
    
    vec2 cusp = find_cusp(a_, b_);
    vec2 ST_max = to_ST(cusp);
    float S_max = ST_max.x;
    float T_max = ST_max.y;
    float S_0 = 0.5;
    float k = 1.- S_0 / S_max;

    // first we compute L and V as if the gamut is a perfect triangle:

    // L, C when v==1:
    float L_v = 1. - s * S_0 / (S_0 + T_max - T_max * k * s);
    float C_v = s * T_max * S_0 / (S_0 + T_max - T_max * k * s);

    float L = v * L_v;
    float C = v * C_v;

    // then we compensate for both toe and the curved top part of the triangle:
    float L_vt = toe_inv(L_v);
    float C_vt = C_v * L_vt / L_v;

    float L_new = toe_inv(L);
    C = C * L_new / L;
    L = L_new;

    vec3 rgb_scale = oklab_to_linear_srgb(vec3( L_vt, a_ * C_vt, b_ * C_vt ));
    float scale_L = cbrt(1. / max(max(rgb_scale.r, rgb_scale.g), max(rgb_scale.b, 0.)));

    L = L * scale_L;
    C = C * scale_L;

    vec3 rgb = oklab_to_linear_srgb(vec3( L, C * a_, C * b_ ));
    return vec3(
      srgb_transfer_function(rgb.r),
      srgb_transfer_function(rgb.g),
      srgb_transfer_function(rgb.b)
    );
  }

  vec3 srgb_to_okhsv(vec3 rgb)
  {
    vec3 lab = linear_srgb_to_oklab(vec3(
      srgb_transfer_function_inv(rgb.r),
      srgb_transfer_function_inv(rgb.g),
      srgb_transfer_function_inv(rgb.b)
      ));

    float C = sqrt(lab.y * lab.y + lab.z * lab.z);
    float a_ = lab.y / C;
    float b_ = lab.z / C;

    float L = lab.x;
    float h = 0.5 + 0.5 * atan(-lab.z, -lab.y) / M_PI;

    vec2 cusp = find_cusp(a_, b_);
    vec2 ST_max = to_ST(cusp);
    float S_max = ST_max.x;
    float T_max = ST_max.y;
    float S_0 = 0.5;
    float k = 1. - S_0 / S_max;

    // first we find L_v, C_v, L_vt and C_vt

    float t = T_max / (C + L * T_max);
    float L_v = t * L;
    float C_v = t * C;

    float L_vt = toe_inv(L_v);
    float C_vt = C_v * L_vt / L_v;

    // we can then use these to invert the step that compensates for the toe and the curved top part of the triangle:
    vec3 rgb_scale = oklab_to_linear_srgb(vec3( L_vt, a_ * C_vt, b_ * C_vt ));
    float scale_L = cbrt(1. / max(max(rgb_scale.r, rgb_scale.g), max(rgb_scale.b, 0.)));

    L = L / scale_L;
    C = C / scale_L;

    C = C * toe(L) / L;
    L = toe(L);

    // we can now compute v and s:

    float v = L / L_v;
    float s = (S_0 + T_max) * C_v / ((T_max * S_0) + T_max * k * C_v);

    return vec3 (h, s, v );
  }
`;


