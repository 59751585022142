<script>
import router from '../router';


export default {
  components: {},
  data() {
      return {}
  },
  mounted() {},
 
}
</script>

<template>
    <div class="admin-container">
      <div class="home-header">
        <div class="flex align-center mx-auto">
          <div class="home-logo mr-2">
            <svg width="30px" height="30px" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M298.219 257.12 300 43.718C300 14.46 278.01.34 258.018.34c-23.59-1.243-35.984 15.615-41.982 30.971l-84.592 242.82L176.5 48C182.5 19 164 .05 134.79.05c-32.61-.206-71.413.29-93.808.29 0 0-15.493.66-27.988 12.16C-.162 24.61.106 40.387.106 40.387-.357 63.15.847 156.9.847 156.9.847 257.12 58 289.298 99.94 295.571c24.007 3.59 41.128-6.88 51.237-21.44l137.828-202.99c-24.157 58.337-69.97 165.148-72.251 170.58C203.542 275.727 231.748 300 256.237 300c30.769 0 42.149-28.587 41.982-42.88Z" fill="#fff"/></svg>
          </div>
          <img class="wordmark" src="/images/uswordmark.svg">
        </div>
      </div>
      <div class="design-listing-container">
        <div class="terms-container">
    <h1 class="h2">Terms of Service</h1>
    <p>By accessing and using Unicorn Studio, you agree to be bound by these Terms of Service ("Terms").</p>
    <p>Unicorn Studio is provided by George Hastings, referred to as "we", "us", or "our".</p>
    <h2 class="h3">Description of Service</h2>
    <p>Unicorn Studio is a SaaS-based design tool focused on creating animated and interactive designs, videos, and website embeds.</p>
    <p>We reserve the right to modify or discontinue the service with or without notice.</p>
    <h2 class="h3">Registration and Account Security</h2>
    <p>Users must register to use Unicorn Studio, providing accurate, current, and complete information.</p>
    <p>You are responsible for maintaining the confidentiality of your account and password.</p>
    <h2 class="h3">User Conduct</h2>
    <p>You agree not to use Unicorn Studio for any unlawful purpose or in any way that violates these Terms.</p>
    <p>You are responsible for all content you create, share, or display within Unicorn Studio.</p>
    <h2 class="h3">User-Uploaded Content</h2>
    <p>Unicorn Studio allows users to upload content such as images, videos, and fonts. By uploading content, you agree that:</p>
    <ul>
        <li><p>We are not responsible for any user-uploaded content that may be explicit, offensive, or illegal.</p></li>
        <li><p>You have the appropriate licenses to upload any fonts and that we are not responsible for any misuse of the uploaded fonts.</p></li>
    </ul>
    <h2 class="h3">Intellectual Property</h2>
    <p>All rights, title, and interest in and to Unicorn Studio and its original content, features, code, and functionality are and will remain the exclusive property of George Hastings and his licensors. </p>
    <h2 class="h3">Payment and Subscription</h2>
    <p>Access to certain features of Unicorn Studio requires payment of fees. All fees are non-refundable and exclusive of taxes.</p>
    <p>Subscription plans, including the free plan and the paid "Legend" plan, may be subject to limitations and changes, with notice provided to users.</p>
    <p>The "Legend" plan offers advanced features and benefits that are not available in the free plan. Details of these features and benefits will be provided at the time of subscription.</p>
    <h2 class="h3">Commercial Use Restriction</h2>
    <p>The use of Unicorn Studio for commercial purposes is restricted to users subscribed to the "Legend" plan. Commercial use includes, but is not limited to, the creation of designs, videos, and website embeds intended for sale, promotion, or any other business-related activities. Users on the free plan are prohibited from using Unicorn Studio for commercial purposes.</p>
    <h2 class="h3">Watermark and Attribution</h2>
    <p>The free version of Unicorn Studio includes a watermark on all exported content. Users agree not to:</p>
    <ul>
        <li><p>Remove, obscure, or attempt to circumvent the watermark</p></li>
        <li><p>Modify exported content to hide or remove the watermark</p></li>
        <li><p>Use technical means to extract unwatermarked content</p></li>
    </ul>
    <p>Removal of the watermark is only permitted for users with an active "Legend" plan subscription.</p>
    <h2 class="h3">Redistribution and Resale Restrictions</h2>
    <p>Unless you have an active "Legend" plan subscription, you may not:</p>
    <ul>
        <li><p>Sell, license, or redistribute content created with Unicorn Studio</p></li>
        <li><p>Include Unicorn Studio content in commercial products or services</p></li>
        <li><p>Use Unicorn Studio content for commercial purposes</p></li>
        <li><p>Bundle or package Unicorn Studio content for redistribution</p></li>
    </ul>
    <h2 class="h3">Commercial License Permissions</h2>
    <p>Users with an active "Legend" plan subscription are granted the following commercial rights:</p>
    <ul>
        <li><p>Create and sell content made with Unicorn Studio</p></li>
        <li><p>Use Unicorn Studio content in commercial projects, websites, and applications</p></li>
        <li><p>Remove the watermark from exported content</p></li>
        <li><p>Create content for clients and commercial entities</p></li>
        <li><p>Include Unicorn Studio content in products or services that you sell</p></li>
    </ul>
    <p>Note: Even with a commercial license, you may not:</p>
    <ul>
        <li><p>Resell or redistribute Unicorn Studio itself</p></li>
        <li><p>Create derivative tools or services that compete with Unicorn Studio</p></li>
    </ul>
    <h2 class="h3">Contribution License</h2>
    <p>By submitting suggestions or feedback regarding Unicorn Studio, you agree that we can use these suggestions and feedback for any purpose without compensation to you.</p>
    <h2 class="h3">Cancellation and Termination</h2>
    <p>You are free to stop using Unicorn Studio at any time. We reserve the right to suspend or terminate your account if you violate these Terms. Further, we reserve the right to deny access to Unicorn Studio for any reason or for no reason at our sole discretion.</p>
    <p>If you cancel your "Legend" plan subscription, you will retain access to the "Legend" features until the end of the current billing period.</p>
    <h2 class="h3">Disclaimer of Warranties</h2>
    <p>Unicorn Studio is provided "as is" and "as available" without any warranties, expressed or implied.</p>
    <h2 class="h3">Limitation of Liability</h2>
    <p>George Hastings shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from the use or inability to use Unicorn Studio.</p>
    <h2 class="h3">Changes to Terms</h2>
    <p>We reserve the right to modify these Terms at any time. Your continued use of Unicorn Studio after such changes constitutes your acceptance of the new Terms.</p>
    <p>Violation of these terms may result in immediate termination of your account and legal action.</p>
    <h2 class="h3">Contact Information</h2>
    <p>If you have any questions about these Terms, please contact us at george@unicorn.studio</p>
</div>
 
      </div>
    </div>

</template>


<style lang="scss" scoped>

.terms-container {
  max-width: 60rem;
  width: 100%;
  margin: 3rem auto 0;
  border-radius: 0.5rem;
  padding: var(--unit5);
}

.h3 {
  margin-top: 3rem;;
}

p {
  color: var(--font-secondary-color);
  line-height: 2rem;
}

.link {
  color: var(--primary-color);
}

.home-header {
  display: flex;
  max-width: 144rem;
  widows: 100%;
  padding: 2rem;
  margin: 0 auto;
  justify-content: space-between;
  z-index: 1;
}


</style>