<script>
import ColorInput from "./ColorInput.vue";
import SliderParam from "./SliderParam.vue";
import InputField from "./InputField.vue";
import RadioToggle from "./RadioToggle.vue";
import ClassicToggle from "./ClassicToggle.vue";
import PlayButton from "./PlayButton.vue";
import Button from "./Button.vue";
import CoordInput from "./CoordInput.vue";
import ParamLabel from "./ParamLabel.vue";
import StateEffects from "./StateEffects.vue";
import AlignmentControls from "./AlignmentControls.vue";
import Icon from "./Icon.vue";
import DropdownMenu from './DropdownMenu.vue';
import { EFFECTS } from "../scripts/Shaders.js";
import { StudioStore } from "../stores/StudioStore.js";
import { DesignsStore } from "../stores/DesignsStore.js";
import TextureInput from './TextureInput.vue';
import { Vec2, Vec3 } from 'curtainsjs'

export default {
  components: {
    ColorInput,
    InputField,
    SliderParam,
    CoordInput,
    TextureInput,
    Button,
    Icon,
    ParamLabel,
    AlignmentControls,
    ClassicToggle,
    StateEffects,
    RadioToggle,
    DropdownMenu,
    PlayButton
  },
  props: ['background', 'childEffect', 'label', 'randomizer'],
  data() {
    return {
      pickColor: null,
      propsKey: 1
    };
  },
  created() {
    Object.entries(this.effect)
        .forEach(([prop, _]) => this.handleConditional(prop))
  },
  computed: {
    effect() {
      return this.background || StudioStore.getCustomCodeItem() || this.childEffect || StudioStore.getSelectedItem() || StudioStore.state.effect;
    },
    params() {
      return EFFECTS[this.effect.type];
    },
    childOfSelectedParent() {
      return this.effect.getParent() ? StudioStore.isSelected(this.effect.getParent()) : false
    },
    computedProps() {
      return Object.entries(this.effect)
        .filter(([prop, _]) => this.isPropertyVisible(prop))
        .map(([prop, _]) => ({
          propName: prop,
          ...this.params.properties[prop]
        }));
    },
    customCodeItemId() {
      return StudioStore.state.customCodeItemId;
    }
  },
  watch: {
    randomizer() {
      this.randomize();
    },
    "effect.fill.length"() {
      this.propsKey++;
    }
  },
  methods: {
    isPropertyVisible(prop) {
      const property = this.params.properties[prop];
      return property && !property.hidden && this.handleConditional(property);
    },
    handleConditional(prop) {
      if(prop.conditional && this.effect.getPlane()) {
        if(prop.conditional.notEqual) {
          return this.effect.getPlane().uniforms[prop.conditional.prop].value !== prop.conditional.value
        } else {
          return this.effect[prop.conditional.prop] === prop.conditional.value
        }
      } else {
        return true
      }
    },
    randomize() {
      for (let prop in this.effect) {
        let val = this.effect[prop];
        if (this.effect.visible && !this.effect.locked) {
          if (this.effect[prop].type === "Vec2") {
            this.effect[prop] = new Vec2(
              randomInt(0, 100) / 100,
              randomInt(0, 100) / 100
            );
          } else if (this.params.properties[prop].options) {
            this.effect[prop] =
              this.params.properties[prop].options[
                randomInt(0, this.params.properties[prop].options.length)
              ].value;
          } else {
            this.effect[prop] =
              (this.params.properties[prop].max -
                this.params.properties[prop].min) *
                Math.random() +
              this.params.properties[prop].min;
          }

          this.$emit("update-value", this.effect.id, prop, val);
        }
      }
    },
    handleCoordChange(val, prop, axis) {
      if(this.effect && this.effect[prop]) {
        if (!val.target && this.effect[prop][axis] !== undefined) {
            if(this.effect[prop].type === 'Vec2') {
              if(axis === 'x') {
                this.effect[prop] = new Vec2(val, this.effect[prop]._y);
              } else {
                this.effect[prop] = new Vec2(this.effect[prop]._x, val);
              }
            } else if(this.effect[prop].type === 'Vec3') {
              if(axis === 'x') {
                this.effect[prop] = new Vec3(val, this.effect[prop]._y, this.effect[prop]._z);
              } else if(axis === 'y') {
                this.effect[prop] = new Vec3(this.effect[prop]._x, val, this.effect[prop]._z);
              } else {
                this.effect[prop] = new Vec3(this.effect[prop]._x, this.effect[prop]._y, val);
              }
            }

            this.$emit('update-value');
        }
      }
    },

    handleMaskChange() {
      //this.effect.isMask = this.effect.isMask ? 0 : 1;
      this.$emit('update-value');
      StudioStore.refreshPlanes(null, null, true);
    },
    handleColorChange(e, prop) {
      if(!(e instanceof Event)) {
        this.effect[prop] = e;
        this.$emit('update-value');
      }
    },
    toggleEditor() {
      StudioStore.state.customCodeItemId = StudioStore.state.customCodeItemId ? null : this.effect.local.id;
    },
    clearItemTexture() {
      this.effect.texture = null;
      this.$emit('update-value');
      StudioStore.refreshPlanes(null, this.effect);
      StudioStore.save();
    },
    handleAlphaChange(e) {
      if(!(e instanceof Event)) {
        this.effect.backgroundAlpha = e;
        this.$emit('update-value');
      }
    },
    handleDropdownChange(prop) {
      const effect = this.effect;
      if(effect.type === 'sdf_shape' && effect.shape === "20" && prop === 'precision' && effect.texture.svgSrc) {
        let precision = +effect.precision;
        let params = {
          scale: 1,
          range: 32,
          svgSize: 128,
          msdfSize: 128,
        }
        if(precision === 0) {
          params = {
            scale: 1,
            range: 16,
            svgSize: 64,
            msdfSize: 64,
          }
        } else if(precision === 2) {
          params = {
            scale: 1,
            range: 64,
            svgSize: 256,
            msdfSize: 256,
          }
        }
        DesignsStore.createMsdf(
          effect.texture.svgSrc, params
        ).then(url => {
          if(!effect) return;
          effect.texture.src = url;
          effect.getPlane().loadImage(
            url,
            {
              sampler: 'uCustomTexture',
              premultipliedAlpha: false,
            },
            tex => {
              StudioStore.renderFrame();
              this.$emit('update');
            }
          );
        });
      }
    }
  },
};
</script>

<template :key="propsKey">
  <div class="effect-properties control-section-wrapper" ref="effectWindow">
    <div class="effect-header">
      <div class="effect-title">
        <PlayButton 
          :animation="params?.animation"
          :visible="effect.visible"
          :animating="effect.animating"
          @toggle-animation="effect.toggleProp('animating'); $emit('update-value')"
        />
        <div class="effect-label parameter-label">{{ effect.isBackground ? 'Background' : params?.label }}</div>
      </div>
      <div class="effect-actions">
        <div
          v-if="effect.type === 'custom'"
          class="button button__icon"
          :class="{'button__icon-active': customCodeItemId}"
          @click="toggleEditor"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 256 256"><path d="M69.12,94.15,28.5,128l40.62,33.85a8,8,0,1,1-10.24,12.29l-48-40a8,8,0,0,1,0-12.29l48-40a8,8,0,0,1,10.24,12.3Zm176,27.7-48-40a8,8,0,1,0-10.24,12.3L227.5,128l-40.62,33.85a8,8,0,1,0,10.24,12.29l48-40a8,8,0,0,0,0-12.29ZM162.73,32.48a8,8,0,0,0-10.25,4.79l-64,176a8,8,0,0,0,4.79,10.26A8.14,8.14,0,0,0,96,224a8,8,0,0,0,7.52-5.27l64-176A8,8,0,0,0,162.73,32.48Z"></path></svg>
        </div>
        <div
          v-if="childOfSelectedParent"
          class="button button__icon browse-effects"
          @click="$emit('delete-item', effect)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 256 256"
          >
            <rect width="256" height="256" fill="none"></rect>
            <line
              x1="40"
              y1="128"
              x2="216"
              y2="128"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="24"
            ></line>
          </svg>
        </div>
      </div>
    </div>
    
    <StateEffects :item="effect" />

    <AlignmentControls :item="effect" @change="$emit('update-value')" />

    <!-- <ClassicToggle 
      label="Downsample"
      v-model="effect.downSample"
      tooltip="If this effect does not need to be full resolution, downsample to boost performance"
      @change="$emit('update-value')"
    ></ClassicToggle> -->

    <ClassicToggle 
      v-if="effect.parentLayer"
      label="Mask"
      v-model="effect.isMask"
      tooltip="When mask is enabled on an effect, it applies to the underlying layer of the parent element rather than the parent element itself."
      @change="handleMaskChange"
    ></ClassicToggle>
  
    <template v-for="prop in computedProps">
      <div :key="prop.propName" class="parameter parameter__block" v-if="(prop.control !== 'rotation' && prop.value.type === 'Vec3' || prop.value.length) && prop.propName !== 'blendMode'">
        <ParamLabel
            :prop="prop.propName"
            :label="params.properties[prop.propName].label"
            :tooltip="params.properties[prop.propName].tooltip"
            @reset-breakpoint-prop="$emit('update-value')"
          >{{params.properties[prop.propName].label}}</ParamLabel>
        <div style="max-width: var(--param-width)" class="flex align-center w-100">
          <ColorInput
            :fill="effect[prop.propName]"
            :vec3="effect[prop.propName].type"
            :alpha="params.properties[prop.propName].alpha"
            @change="handleColorChange($event, prop.propName)"
            @click-swatch="$emit('edit-fill', { item: effect, prop: prop.propName })"
          ></ColorInput>
          <InputField
            v-if="params.properties[prop.propName].alpha"
            class="ml-3"
            :alpha="params.properties[prop.propName].alpha"
            label='A'
            :value="effect[params.properties[prop.propName].alpha]"
            @change="handleAlphaChange"
            :slider="true"
            output="percent"
          />
        </div>
      </div>
      <div v-else-if="prop.value.type === 'Vec2'" :key="prop.propName"  class="parameter parameter__block">
        <ParamLabel
            :prop="prop.propName"
            :label="params.properties[prop.propName].label"
            :tooltip="params.properties[prop.propName].tooltip"
            @reset-breakpoint-prop="$emit('update-value')"
          >{{params.properties[prop.propName].label}}</ParamLabel>
        <div class="coords-input-group">
          <InputField
            :label="'X'"
            :value="effect[prop.propName].x"
            output="percent"
            :slider="true"
            @change="handleCoordChange($event, prop.propName, 'x')"
          />
          <InputField
            :label="'Y'"
            :value="effect[prop.propName].y"
            output="percent"
            :slider="true"
            @change="handleCoordChange($event, prop.propName, 'y')"
          />
        </div>
      </div>
      <div v-else-if="prop.value.type === 'Vec3' && prop.control === 'rotation'" :key="prop.propName" class="parameter parameter__block">
        <ParamLabel
            :prop="prop.propName"
            :label="params.properties[prop.propName].label"
            :tooltip="params.properties[prop.propName].tooltip"
          >{{params.properties[prop.propName].label}}</ParamLabel>
        <div class="coords-input-group">
          <InputField
            :label="'X'"
            :value="effect[prop.propName].x"
            :output="prop.output"
            :slider="true"
            @change="handleCoordChange($event, prop.propName, 'x')"
          />
          <InputField
            :label="'Y'"
            :value="effect[prop.propName].y"
            :output="prop.output"
            :slider="true"
            @change="handleCoordChange($event, prop.propName, 'y')"
          />
          <InputField
            :label="'Z'"
            :value="effect.rotZ || effect[prop.propName].z || 0"
            :output="prop.output"
            :slider="true"
            @change="handleCoordChange($event, prop.propName, 'z')"
          />
        </div>
      </div>
      <TextureInput 
        v-else-if="params.properties[prop.propName].output === 'texture'"
        :key="prop.propName"
        v-model="effect[prop.propName]"
        :label="params.properties[prop.propName].label"
        :tooltip="params.properties[prop.propName].tooltip"
        @replace-image="$emit('replace-image', effect.local.id, 'svg')"
      />
      <RadioToggle
        v-else-if="params.properties[prop.propName].options && params.properties[prop.propName].radio"
        v-model="effect[prop.propName]"
        :label="params.properties[prop.propName].label"
        :options="params.properties[prop.propName].options"
        :key="params.properties[prop.propName].label"
        :tooltip="params.properties[prop.propName].tooltip"
        @change="$emit('update-value')"
      ></RadioToggle>
      <ClassicToggle
        v-else-if="params.properties[prop.propName].options && params.properties[prop.propName].classic"
        v-model="effect[prop.propName]"
        :label="params.properties[prop.propName].label"
        :locked="prop.locked"
        :options="params.properties[prop.propName].options"
        :key="params.properties[prop.propName].label"
        :tooltip="params.properties[prop.propName].tooltip"
        @change="$emit('update-value')"
        @toggle-lock="prop.locked = $event"
      ></ClassicToggle>
      <template v-else-if="params.properties[prop.propName].options">
        <div v-if="params.properties[prop.propName].header" class="parameter parameter__block" :key="prop.propName"><label class="parameter-label mt-2">{{params.properties[prop.propName].header}}</label></div>
        <div :key="prop.propName" class="parameter parameter__block">
          <ParamLabel
            :prop="prop.propName"
            :label="params.properties[prop.propName].label"
            :tooltip="params.properties[prop.propName].tooltip"
          >{{params.properties[prop.propName].label}}</ParamLabel>
          <DropdownMenu
            v-model="effect[prop.propName]"
            :label="params.properties[prop.propName].label"
            :locked="prop.locked"
            :options="params.properties[prop.propName].options"
            :rolloverPreview="prop.propName !== 'precision'"
            @change="handleDropdownChange(prop.propName)"
            @update:modelValue="$emit('update-value')"
            @toggle-lock="prop.locked = $event"
          ></DropdownMenu>
        </div>
      </template>
      <SliderParam
        v-else
        :key="params.properties[prop.propName].label"
        :id="prop.propName"
        :label="params.properties[prop.propName].label"
        :header="params.properties[prop.propName].header"
        v-model="effect[prop.propName]"
        :min="params.properties[prop.propName].min"
        :max="params.properties[prop.propName].max"
        :step="params.properties[prop.propName].step"
        :prop="prop.propName"
        :output="params.properties[prop.propName].output"
        :locked="prop.locked"
        :quadratic="params.properties[prop.propName].quadratic"
        :tooltip="params.properties[prop.propName].tooltip"
        @update="$emit('update-value')"
        @toggle-lock="prop.locked = $event"
      ></SliderParam>
    </template>
  </div>
</template>

<style lang="scss">
.effect-label {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
}

.effect-properties {
  padding: 1.5rem;
  width: 100%;
  background-color: var(--bg-color);
  color: var(--font-color);

  .button-group {
    margin-top: 1.5rem;
  }

  .parameter {
    padding-left: 0;
  }

  &.effect-properties__video-export {
    left: 0;
    top: calc(100% + 1rem);
  }
}

.effect-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 0 1rem;
}

.effect-title {
  display: flex;
  align-items: center;
  user-select: none;
}

.effect-actions {
  display: flex;
  align-items: center;
  color: var(--font-secondary-color);

  .button + .button {
    margin-left: 0.5rem;
  }

  .button:hover {
    color: var(--font-color);
  }
}

.button.effect-animate-button {
  position: relative;
  padding: 0.4rem;
  border-radius: 50%;
  border: 1px solid var(--border-color);
}

.effect-animate-button__animating {
  border: 1px solid transparent;

  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: 50%;
    border: 2px dotted var(--primary-color);
    animation: spin 8s linear infinite;
  }

  &.effect-animate-button__animating-hidden {
    &:before {
      border: 2px dotted var(--font-secondary-color);
    }
  }
}

@keyframes spin {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
</style>