<script>
import Button from './Button.vue';
import Icon from './Icon.vue';
import { StudioStore } from '../stores/StudioStore.js';
import { getAnchorOffsets } from '../scripts/layerTypes/Element';

export default {
  components: {
    Button,
    Icon,
  },
  props: ['item'],
  data() {
    return {
      pickColor: null,
      propsKey: 1,
      AlignmentType: {
        LEFT: 'left',
        RIGHT: 'right',
        TOP: 'top',
        BOTTOM: 'bottom',
        CENTER_VERTICAL: 'centerVertical',
        CENTER_HORIZONTAL: 'centerHorizontal',
      },
    };
  },
  computed: {
    items() {
      return StudioStore.state.selectedItems;
    },
  },
  methods: {
    calculateAnchorAdjustedPosition(item) {
      const { width, height } = item.getAbsoluteDimensions();
      const relativeWidth = width / StudioStore.state.canvasWidth;
      const relativeHeight = height / StudioStore.state.canvasHeight;

      // Get anchor point offsets - now these are relative positions (0 to 1)
      const anchorOffsets = getAnchorOffsets(item.anchorPoint || 'topLeft');

      return {
        width: relativeWidth,
        height: relativeHeight,
        anchorXOffset: anchorOffsets.x,
        anchorYOffset: anchorOffsets.y,
      };
    },

    alignItem(item, alignmentType) {
      const isHorizontal = [
        this.AlignmentType.LEFT,
        this.AlignmentType.RIGHT,
        this.AlignmentType.CENTER_HORIZONTAL,
      ].includes(alignmentType);

      if (item.layerType === 'effect' && item.pos) {
        // Effect alignment remains unchanged since it doesn't use anchor points
        if (isHorizontal) {
          item.pos.x =
            alignmentType === this.AlignmentType.LEFT ? 0 : alignmentType === this.AlignmentType.RIGHT ? 1 : 0.5;
        } else {
          item.pos.y =
            alignmentType === this.AlignmentType.TOP ? 0 : alignmentType === this.AlignmentType.BOTTOM ? 1 : 0.5;
        }
      } else if (item.isElement && item.left !== undefined && item.top !== undefined) {
        const { width, height, anchorXOffset, anchorYOffset } = this.calculateAnchorAdjustedPosition(item);

        // Calculate the offset needed to align the item based on its anchor point
        if (isHorizontal) {
          switch (alignmentType) {
            case this.AlignmentType.LEFT:
              // Align the item's left edge to 0, accounting for the anchor point
              item.left = width * anchorXOffset;
              break;
            case this.AlignmentType.RIGHT:
              // Align the item's right edge to 1, accounting for the anchor point
              item.left = 1 - width * (1 - anchorXOffset);
              break;
            case this.AlignmentType.CENTER_HORIZONTAL:
              // Align the item's center to 0.5, accounting for the anchor point
              item.left = 0.5 - width * (0.5 - anchorXOffset);
              break;
          }
        } else {
          switch (alignmentType) {
            case this.AlignmentType.TOP:
              // Align the item's top edge to 0, accounting for the anchor point
              item.top = height * anchorYOffset;
              break;
            case this.AlignmentType.BOTTOM:
              // Align the item's bottom edge to 1, accounting for the anchor point
              item.top = 1 - height * (1 - anchorYOffset);
              break;
            case this.AlignmentType.CENTER_VERTICAL:
              // Align the item's center to 0.5, accounting for the anchor point
              item.top = 0.5 - height * (0.5 - anchorYOffset);
              break;
          }
        }
      }
    },

    alignItems(alignmentType) {
      if (this.item) {
        this.alignItem(this.item, alignmentType);
        this.$emit('change');
        return;
      }

      this.items.forEach(item => {
        this.alignItem(item, alignmentType);
      });

      this.$emit('change');
    },
  },
};
</script>

<template :key="propsKey">
  <div class="flex font-secondary-color mb-3">
    <Button class="icon" @click="alignItems(AlignmentType.LEFT)"><Icon icon="alignLeft" /></Button>
    <Button class="icon" @click="alignItems(AlignmentType.CENTER_HORIZONTAL)"
      ><Icon icon="alignCenterHorizontal"
    /></Button>
    <Button class="icon" @click="alignItems(AlignmentType.RIGHT)"><Icon icon="alignRight" /></Button>
    <Button class="icon" @click="alignItems(AlignmentType.TOP)"><Icon icon="alignTop" /></Button>
    <Button class="icon" @click="alignItems(AlignmentType.CENTER_VERTICAL)"><Icon icon="alignCenterVertical" /></Button>
    <Button class="icon" @click="alignItems(AlignmentType.BOTTOM)"><Icon icon="alignBottom" /></Button>
  </div>
</template>
