import {
  computeFragColor,
  vertexShader,
  EASE,
  mixRadiusProperties,
  EASING_FUNCTIONS,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  EXPONENTIAL_WEIGHTS_9,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const radialBlurShader = `#version 300 es
  precision highp float;

  in vec3 vVertexPosition;
  in vec2 vTextureCoord;

  uniform sampler2D uTexture;
  uniform sampler2D uBgTexture;
  uniform float uAmount;
  uniform float uMixRadius;
  uniform int uMixRadiusInvert;
  uniform int uEasing;
  uniform vec2 uPos;
  uniform int uPass;
  ${UNIVERSAL_UNIFORMS}
  ${EASE}

  out vec4 fragColor;

  float random(vec2 seed) {
    return fract(sin(dot(seed.xy, vec2(12.9898, 78.233))) * 43758.5453);
  }

  const int kernelSize = 24;
  ${EXPONENTIAL_WEIGHTS_9}

  vec4 RadialBlur(sampler2D tex, vec2 uv) {
    vec4 color = vec4(0.0);
    float total_weight = 0.0;
    
    vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
    vec2 dir = uv - pos;
    float aspectRatio = uResolution.x/uResolution.y;
    dir.x *= aspectRatio;
    
    // Calculate distance for mix radius
    float dist = ease(uEasing, max(0.,1.-distance(uv * vec2(aspectRatio, 1), pos * vec2(aspectRatio, 1)) * 4. * (1. - uMixRadius)));
    
    if (uMixRadiusInvert == 1) {
      dist = max(0., (0.5 - dist));
    }

    float amount = (uAmount + 0.2) * dist * 0.01;

    if(uPass == 1) {
      amount *= 0.5;
    }
    if(uPass == 2) {
      amount *= 0.25;
    }
    
    vec4 center = texture(tex, uv);
    float center_weight = getExponentialWeight(0);
    color += center * center_weight;
    total_weight += center_weight;
    
    for (int i = 1; i <= 8; i++) {
      float weight = getExponentialWeight(i);
      float offset = float(i) * amount;
      
      vec2 sampleDir = normalize(dir) * offset;
      sampleDir.x /= aspectRatio;
      
      vec4 sample1 = texture(tex, uv + sampleDir);
      vec4 sample2 = texture(tex, uv - sampleDir);
      
      color += (sample1 + sample2) * weight;
      total_weight += 2.0 * weight;
    }

    color = color / total_weight;
    
    float dither = (random(gl_FragCoord.xy) - 0.5) / 255.0;
    color.rgb += dither;
    
    return color;
  }

  vec4 downSamplePass(vec2 uv) {
    return texture(uTexture, uv);
  }

  vec4 radialBlurPass(vec2 uv) {
    return RadialBlur(uTexture, uv);
  }

  vec4 finalPass(vec2 uv) {
    vec4 blurredColor = texture(uTexture, uv);
    vec4 originalColor = texture(uBgTexture, uv);
    
    vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
    float aspectRatio = uResolution.x/uResolution.y;
    float dist = ease(uEasing, max(0.,1.-distance(uv * vec2(aspectRatio, 1), pos * vec2(aspectRatio, 1)) * 4. * (1. - uMixRadius)));
    if (uMixRadiusInvert == 1) {
      dist = max(0., (0.5 - dist));
    }

    float mixValue = (uAmount * dist) == 0.00 ? 0.0 : 1.0;
    return mix(originalColor, blurredColor, mixValue);
  }

  void main() {  
    vec2 uv = vTextureCoord;
    
    if(uAmount == 0.00) {
      fragColor = texture(uBgTexture, uv);
      return;
    }

    switch(uPass) {
      case 0: fragColor = radialBlurPass(uv); break;
      case 1: fragColor = radialBlurPass(uv); break;
      case 2: fragColor = radialBlurPass(uv); break;
      case 3: fragColor = finalPass(uv); break;
    }
  }
`;

const params = {
  fragmentShader: radialBlurShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
  },
  uniforms: {
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.5,
    },
    mixRadius: {
      name: 'uMixRadius',
      type: '1f',
      value: 1,
    },
    mixRadiusInvert: {
      name: 'uMixRadiusInvert',
      type: '1i',
      value: 0,
    },
    easing: {
      name: 'uEasing',
      type: '1i',
      value: 0,
    },
    pass: {
      name: 'uPass',
      type: '1i',
      value: 0,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    ...universalUniformParams,
  },
};

export const ZOOM_BLUR = {
  id: 'zoomBlur',
  label: 'Zoom blur',
  params: params,
  passes: [
    { prop: 'pass', value: 1, downSample: 0.25 },
    { prop: 'pass', value: 2, downSample: 0.5 },  // Apply blur on downsampled texture
    { prop: 'pass', value: 3, includeBg: true },  // Final pass at full resolution
  ],
  aspectRatio: 1,
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    amount: {
      label: 'Amount',
      value: 0.2,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the amount of blur',
    },
    ...mixRadiusProperties,
   
    ...interactiveProperties,
  },
};
