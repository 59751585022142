import { deserializeNestedArray, serializeNestedArray, generateUUID } from '../Helpers.js';
import { MaskCommand, MoveCommand, VisibilityToggleCommand } from '../Commands';
import { StudioStore } from '../../stores/StudioStore.js';
import { Vec2, Vec3 } from 'curtainsjs';
import { watch } from 'vue';

const breakpoints = [
  {
    name: 'Desktop',
    max: Infinity,
    min: 992,
  },
  {
    name: 'Tablet',
    max: 991,
    min: 576,
  },
  {
    name: 'Mobile',
    max: 575,
    min: 0,
  },
];

export class Layer {
  local = {
    id: '',
    watches: 0,
    propertiesToWatch: [],
    initialBreakpoint: '',
    isSettingBreakpointValues: false,
  };
  breakpoints = [];

  constructor(args, id) {
    this.visible = args.visible !== undefined ? args.visible : !args.hidden || true;
    this.locked = args.locked || false;
    this.aspectRatio = args.aspectRatio || 1;
    this.layerName = args.layerName || '';
    this.breakpoints = this.unpackBreakpoints(args.breakpoints || []);

    breakpoints.forEach(n => {
      if (!this.getBreakpoint(n.name)) {
        this.breakpoints.push({
          name: n.name,
          max: n.max,
          min: n.min,
          props: {},
        });
      }
    });

    // Add cleanup logic for Desktop breakpoint
    const desktopBreakpoint = this.getBreakpoint('Desktop');
    const nonDesktopBreakpoints = this.breakpoints.filter(bp => bp.name !== 'Desktop');
    const hasNonDesktopProps = nonDesktopBreakpoints.some(bp => Object.keys(bp.props).length > 0);
    
    if (desktopBreakpoint && !hasNonDesktopProps) {
      desktopBreakpoint.props = {};
    }

    this.local.initialBreakpoint = this.getCurrentBreakpoint().name;
    this.local.id = id || generateUUID();
  }

  getIndex() {
    return StudioStore.state.history.map(n => n.local.id).indexOf(this.local.id);
  }

  deselect() {
    this.local.isSelected = false;
    if (this.justCreated) {
      this.justCreated = false;
    }
  }

  getPlane() {
    return StudioStore.state.curtain.planes.find(n => n.userData.id === this.local.id);
  }

  getPlanes() {
    return StudioStore.state.curtain.planes.filter(n => n.userData.id === this.local.id);
  }

  getMaskedItem() {
    if (this.mask) {
      return StudioStore.state.history.filter(n => n.visible && !n.parentLayer)[this.getIndex() - 1];
    } else {
      return false;
    }
  }

  toggleMask() {
    const command = new MaskCommand(this.mask ? 0 : 1, this.mask ? 1 : 0, this);
    StudioStore.performAction(command);
  }

  toggleVisibility() {
    const command = new VisibilityToggleCommand(this, !this.visible, this.visible);
    StudioStore.performAction(command);
  }

  toggleProp(prop) {
    this[prop] = !this[prop];
    if (prop === 'locked' && this[prop]) {
      StudioStore.setSelectedItem('');
    }
    if (prop === 'animating') {
      this.time = 0;
      if (this.updateUniforms) {
        this.updateUniforms();
      }
    }
  }

  moveToPosition(newpos, oldpos, bottom, top) {
    const command = new MoveCommand(newpos, oldpos, bottom, top);
    StudioStore.performAction(command);
  }

  getChildEffectItems() {
    if (this.effects && this.effects.length) {
      const childEffects = StudioStore.state.history.filter(n => n.visible && this.effects.includes(n.parentLayer));
      const orderedChildEffects = this.effects
        .map(effectId => childEffects.find(n => n.parentLayer === effectId))
        .filter(effect => effect !== undefined);
      return orderedChildEffects;
    } else {
      return [];
    }
  }

  isAnimating() {
    const conditions = {
        isBaseAnimating: this.animating,
        isTrackingMouse: 'trackMouse' in this && this.trackMouse !== 0,
        isTilting: 'axisTilt' in this && this.axisTilt !== 0,
        isMouseEffect: this.type === 'mouse' || this.type === 'waterRipple',
        hasActiveStates: false
    };

    if (this.states) {
        const stateConditions = {
            hasAppearEffects: this.states.appear.some(effect => effect.initialStateSet && !effect.complete),
            hasScrollEffects: this.states.scroll.length > 0,
            hasHoverEffects: this.states.hover.length > 0
        };
        
        conditions.hasActiveStates = stateConditions.hasAppearEffects || 
                                   stateConditions.hasScrollEffects || 
                                   stateConditions.hasHoverEffects;

    }
    
    return conditions.isBaseAnimating || 
           conditions.isTrackingMouse || 
           conditions.isTilting || 
           conditions.isMouseEffect ||
           conditions.hasActiveStates;
  }

  setupWatchers(properties) {
    const layer = StudioStore.state.history.find(n => n.local.id === this.local.id);
    if(!layer) {
      return;
    }
    properties.forEach(prop => {
      watch(() => layer[prop], (newVal, oldVal) => {
        if (this.local.isSettingBreakpointValues) {
          return;
        }
        
        if (newVal === undefined || oldVal === undefined) {
          return false;
        }

        const base = this.getBreakpoint('Desktop');
        const currentBreakpoint = this.getCurrentBreakpoint();

        let updateDesktop = !base.props.hasOwnProperty(prop) || this.local.initialBreakpoint !== 'Desktop';
        const nonDeskTopBreakpoint = currentBreakpoint && currentBreakpoint.name !== 'Desktop';
        const editingDesktop = currentBreakpoint && currentBreakpoint.name === 'Desktop' && base.props.hasOwnProperty(prop);

        if (this.hasValueChanged(newVal, oldVal)) {
          if (editingDesktop || nonDeskTopBreakpoint && updateDesktop) {
            base.props[prop] = oldVal.clone ? oldVal.clone() : oldVal;
          }

          if (nonDeskTopBreakpoint) {
            const breakpoint = this.getBreakpoint(currentBreakpoint.name);
            if (breakpoint) {
              breakpoint.props[prop] = newVal.clone ? newVal.clone() : newVal;
            }
          }
        }
      });
    });
  }

  hasValueChanged(newVal, oldVal) {
    if (newVal == null || oldVal == null) {
      return false;
    }
    if (newVal.equals) {
      return !newVal.equals(oldVal);
    }
    if (Array.isArray(newVal) && Array.isArray(oldVal)) {
      if (newVal.length !== oldVal.length) {
        return true;
      }
      return newVal.some((val, index) => this.hasValueChanged(val, oldVal[index]));
    } else {
      return newVal !== oldVal;
    }
  }

  getBreakpoint(name) {
    return this.breakpoints.find(bp => bp.name === name);
  }

  getCurrentBreakpoint() {
    const width = StudioStore.state.currentSize.realDimensions[0];
    const isNamedBreakpoint = breakpoints.map(n => n.name).includes(StudioStore.state.size);
    return isNamedBreakpoint && breakpoints.find(bp => width <= bp.max && width >= bp.min);
  }

  isBreakpoint(prop) {
    const breakpoint = this.getCurrentBreakpoint();
    if (breakpoint && breakpoint.name !== 'Desktop') {
      let layerBreakpoint = this.getBreakpoint(breakpoint.name);
      let base = this.getBreakpoint('Desktop');
      if (layerBreakpoint) {
        return (
          layerBreakpoint.props.hasOwnProperty(prop) &&
          this.hasValueChanged(layerBreakpoint.props[prop], base.props[prop])
        );
      }
    }
    return false;
  }

  setBreakpointProp(prop, value) {
    if (value instanceof Vec2 || value instanceof Vec3) {
      this[prop].copy(value);
    } else if (value.type) {
      this[prop].x = value._x;
      this[prop].y = value._y;
      if (value._z !== undefined) {
        this[prop].z = value._z;
      }
    } else {
      this[prop] = value;
    }
  }

  resetBreakpointProp(prop) {
    const breakpoint = this.getCurrentBreakpoint();
    let base = this.getBreakpoint('Desktop');
    if (breakpoint) {
      let layerBreakpoint = this.getBreakpoint(breakpoint.name);
      if (layerBreakpoint && layerBreakpoint.props.hasOwnProperty(prop)) {
        this.local.isSettingBreakpointValues = true;
        // Remove the property from the current breakpoint
        delete layerBreakpoint.props[prop];

        if (prop === 'width') {
          delete layerBreakpoint.props.widthMode;
          this.widthMode = this.getInheritedProp('widthMode', breakpoint.name) || base.props.widthMode || this.widthMode;
        }
        if (prop === 'height') {
          delete layerBreakpoint.props.heightMode;
          this.heightMode = this.getInheritedProp('heightMode', breakpoint.name) || base.props.heightMode || this.heightMode;
        }
        if (prop === 'fontSize') {
          delete layerBreakpoint.props.fontSizeMode;
          this.fontSizeMode = this.getInheritedProp('fontSizeMode', breakpoint.name) || base.props.fontSizeMode || this.fontSizeMode;
        }

        this[prop] = this.getInheritedProp(prop, breakpoint.name);


        this.local.isSettingBreakpointValues = false;

        if (this.render) {
          this.render();
        }
        if (this.updateUniforms) {
          this.updateUniforms();
        }

        requestAnimationFrame(() => {
          if(breakpoint.name === 'Tablet' && base.props.hasOwnProperty(prop)) {
            delete base.props[prop];

            if (prop === 'width') {
              delete base.props.widthMode;
            }
            if (prop === 'height') {
              delete base.props.heightMode;
            }
            if (prop === 'fontSize') {
              delete base.props.fontSizeMode;
            }
          }
          
          // Check if there are any other properties left
          if (!Object.keys(layerBreakpoint.props).length) {
            // Remove the breakpoint if it has no properties
            this.breakpoints = this.breakpoints.filter(n => n.name !== layerBreakpoint.name);
          }
        });
      }
    }
  }

  getInheritedProp(propName, currentBreakpointName) {
    const currentIndex = breakpoints.findIndex(bp => bp.name === currentBreakpointName);
    for (let i = currentIndex; i >= 0; i--) {
      const layerBreakpoint = this.getBreakpoint(breakpoints[i].name);
      if (layerBreakpoint && layerBreakpoint.props.hasOwnProperty(propName)) {
        return layerBreakpoint.props[propName];
      }
    }
    return null;
  }

  setBreakpointValues() {
    const currentBreakpoint = this.getCurrentBreakpoint();
    if (currentBreakpoint) {
        if (currentBreakpoint.name === 'Desktop') {
            this.local.initialBreakpoint = 'Desktop';
        }
        
        // Add a flag to prevent watcher reactions
        this.local.isSettingBreakpointValues = true;
        
        let inheritedValues = {};
        for (let prop of this.local.propertiesToWatch) {
            const inheritedValue = this.getInheritedProp(prop, currentBreakpoint.name);
            if (inheritedValue !== undefined && inheritedValue !== null) {
                inheritedValues[prop] = inheritedValue;
            }
        }

        for (let prop in inheritedValues) {
            if (this[prop] !== undefined && this[prop] !== inheritedValues[prop]) {
                this.setBreakpointProp(prop, inheritedValues[prop]);
            }
        }

        // Reset the flag after updates
        requestAnimationFrame(() => {
          this.local.isSettingBreakpointValues = false;
        });
        if (this.render) {
            this.render();
        }
        if (this.updateUniforms) {
            this.updateUniforms();
        }
    }
  }

  watchWindow() {
    watch(
      () => StudioStore.state.currentSize.realDimensions[0],
      (newVal, oldVal) => {
        if (this.hasValueChanged(newVal, oldVal)) {
          this.setBreakpointValues();
        }
      }
    );
  }

  initializeBreakpoints() {
    this.setupWatchers(this.local.propertiesToWatch);
    this.watchWindow();
  }

  prepBreakpoints(bps) {
    // Use map to transform the array and return a new array
    const newbps = bps.map(breakpoint => {
      // Use object destructuring to create a new object
      const newProps = {};

      // Transform each property in the breakpoint's props
      for (let prop in breakpoint.props) {
        if (breakpoint.props[prop] instanceof Array) {
          newProps[prop] = serializeNestedArray(breakpoint.props[prop]);
        } else if (breakpoint.props[prop]?.type === 'Vec2') {
          newProps[prop] = {
            type: 'Vec2',
            _x: breakpoint.props[prop]._x,
            _y: breakpoint.props[prop]._y,
          };
        } else if (breakpoint.props[prop]?.type === 'Vec3') {
          newProps[prop] = {
            type: 'Vec3',
            _x: breakpoint.props[prop]._x,
            _y: breakpoint.props[prop]._y,
            _z: breakpoint.props[prop]._z,
          };
        } else {
          // Preserve non-Vec2/Vec3 props
          newProps[prop] = breakpoint.props[prop];
        }
      }

      // Return a new breakpoint object with transformed properties
      return {
        ...breakpoint,
        props: newProps,
      };
    });

    return newbps;
  }

  unpackBreakpoints(bps) {
    bps.forEach(breakpoint => {
      for (let prop in breakpoint.props) {
        if (breakpoint.props[prop]?.type === 'Vec2') {
          breakpoint.props[prop] = new Vec2(breakpoint.props[prop]._x, breakpoint.props[prop]._y);
        } else if (breakpoint.props[prop]?.type === 'Vec3') {
          breakpoint.props[prop] = new Vec3(
            breakpoint.props[prop]._x,
            breakpoint.props[prop]._y,
            breakpoint.props[prop]._z
          );
        } else if (typeof breakpoint.props[prop] === 'object') {
          breakpoint.props[prop] = deserializeNestedArray(breakpoint.props[prop]);
        }
      }
    });
    return bps;
  }

  copy(id, obj, dontInit) {
    let layer = obj || this;
    let copy = new layer.constructor(layer, id, dontInit);
    copy.breakpoints = this.unpackBreakpoints(this.prepBreakpoints(copy.breakpoints));
    return copy;
  }
}
