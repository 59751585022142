import { BLEND } from './ShaderHelpers';
import { Vec2, Vec3 } from 'curtainsjs';

export const elementVertexShader = `#version 300 es
precision mediump float;

in vec3 aVertexPosition;
in vec2 aTextureCoord;

uniform mat4 uMVMatrix;
uniform mat4 uPMatrix;
uniform mat4 uTextureMatrix;
uniform vec2 uMousePos;

out vec2 vTextureCoord;
out vec3 vVertexPosition;

void main() {
    float angleX = uMousePos.y * 0.5 - 0.25; // Vertical mouse movement tilts around the X-axis
    float angleY = (1.-uMousePos.x) * 0.5 - 0.25; // Horizontal mouse movement tilts around the Y-axis

    // Rotation matrices around X and Y axes
    mat4 rotateX = mat4(1.0, 0.0, 0.0, 0.0,
                        0.0, cos(angleX), -sin(angleX), 0.0,
                        0.0, sin(angleX), cos(angleX), 0.0,
                        0.0, 0.0, 0.0, 1.0);
    mat4 rotateY = mat4(cos(angleY), 0.0, sin(angleY), 0.0,
                        0.0, 1.0, 0.0, 0.0,
                        -sin(angleY), 0.0, cos(angleY), 0.0,
                        0.0, 0.0, 0.0, 1.0);

    mat4 rotationMatrix = rotateX * rotateY;
    gl_Position = uPMatrix * uMVMatrix * vec4(aVertexPosition, 1.0);
    vVertexPosition = (rotationMatrix * vec4(aVertexPosition, 1.0)).xyz;
    vTextureCoord = (uTextureMatrix * vec4(aTextureCoord, 0.0, 1.0)).xy;
}`;

const elementFragmentShaderNoDispNoBlend = `#version 300 es
precision mediump float;
in vec2 vTextureCoord;
in vec3 vVertexPosition;

uniform sampler2D uBgTexture;
uniform sampler2D uTexture;
uniform vec2 uMousePos;
uniform vec2 uResolution;
uniform float uOpacity;
uniform float uAxisTilt;
uniform int uSampleBg;
uniform float uTrackMouse;

vec2 perspectiveUV(vec2 uv) {
  float aspectRatio = uResolution.x/uResolution.y;
  vec2 centeredUV = uv - 0.5;
  centeredUV.x *= aspectRatio;
  float strength = 1.0 + (vVertexPosition.z * uAxisTilt);
  vec2 perspectiveUV = centeredUV / strength;
  perspectiveUV.x /= aspectRatio;
  perspectiveUV += 0.5;
  return perspectiveUV;
}

out vec4 fragColor;

void main() {
  vec2 uv = vTextureCoord;
  vec2 pos = mix(vec2(0), (uMousePos - 0.5), uTrackMouse);

  // #ifelseopen
  if(uAxisTilt > 0.00) {
    uv = perspectiveUV(uv) - pos;
  } else {
    uv = uv - pos;
  }
  // #ifelseclose

  vec4 color = texture(uTexture, uv);
  vec4 background = vec4(0);

  if(uSampleBg == 1) {
    background = texture(uBgTexture, vTextureCoord);
  }

  color = mix(background, color / max(color.a, 0.0001), color.a * uOpacity);


  fragColor = color;
}

`;

export const basicNoDispNoBlendParams = {
  fragmentShader: elementFragmentShaderNoDispNoBlend,
  vertexShader: elementVertexShader,
  crossorigin: 'Anonymous',
  texturesOptions: {
    premultiplyAlpha: true,
  },
  uniforms: {
    opacity: {
      name: 'uOpacity',
      type: '1f',
      value: 1,
    },
    mousePos: {
      name: 'uMousePos',
      type: '2f',
      value: new Vec2(0.5),
    },
    trackMouse: {
      name: 'uTrackMouse',
      type: '1f',
      value: 0,
    },
    axisTilt: {
      name: 'uAxisTilt',
      type: '1f',
      value: 0,
    },
    resolution: {
      name: 'uResolution',
      type: '2f',
      value: new Vec2(1080, 1080),
    },
    sampleBg: {
      name: 'uSampleBg',
      type: '1i',
      value: 1,
    },
  },
};

export const elementFragmentShader = `#version 300 es
precision mediump float;
in vec2 vTextureCoord;
in vec3 vVertexPosition;

uniform vec2 uResolution;
uniform vec2 uMousePos;
uniform sampler2D uBgTexture;
uniform sampler2D uPreviousLayerTexture;
uniform sampler2D uTexture;
uniform float uOpacity;
uniform int uBlendMode;
uniform float uBgDisplace;
uniform float uDisplace;
uniform float uDispersion;
uniform float uTrackMouse;
uniform float uAxisTilt;
uniform int uSampleBg;
uniform float uMask;
uniform vec3 uMaskBg;
uniform float uMaskAlpha;
uniform int uMaskDepth;

${BLEND}

const float STEPS = 24.0;
const float PI = 3.1415926;

vec3 chromaticAbberation(vec2 st, float angle, float amount, float blend) {
  float aspectRatio = uResolution.x/uResolution.y;
  float rotation = angle * 360.0 * PI / 180.0;
  vec2 aberrated = amount * vec2(0.1 * sin(rotation) * aspectRatio, 0.1 * cos(rotation));
  aberrated *= distance(st, vec2(0.5)) * 2.0;

  vec4 red = vec4(0);
  vec4 blue = vec4(0);
  vec4 green = vec4(0);

  float invSteps = 1.0 / STEPS;
  float invStepsHalf = invSteps * 0.5;

  for(float i = 1.0; i <= STEPS; i++) {
    vec2 offset = aberrated * (i * invSteps);
    red += texture(uBgTexture, st - offset) * invSteps;
    blue += texture(uBgTexture, st + offset) * invSteps;
    green += texture(uBgTexture, st - offset * 0.5) * invStepsHalf;
    green += texture(uBgTexture, st + offset * 0.5) * invStepsHalf;
  }

  return vec3(red.r, green.g, blue.b);
}

vec3 refrakt(vec3 eyeVector, vec3 normal, float iorRatio) {
  float dotProduct = dot(eyeVector, normal);
  float k = 1.0 - iorRatio * iorRatio * (1.0 - dotProduct * dotProduct);
  
  if (k < 0.0) {
    return reflect(eyeVector, normal);
  } else {
    return iorRatio * eyeVector - (iorRatio * dotProduct + sqrt(k)) * normal;
  }
}

vec4 displacement (vec2 st, vec4 bg, vec4 color) {
  if(uBgDisplace == 1.0) {
    vec2 refraction = refrakt(vec3(vTextureCoord, 0.5), color.rgb, uDisplace-0.5).xy;
    vec2 displaced = vTextureCoord + mix(vec2(0), refraction * 0.1, uDisplace);
    vec4 bgDisp = texture(uBgTexture, displaced);

    bgDisp.rgb = uDispersion > 0. ? chromaticAbberation(displaced, atan(displaced.y, displaced.x)-0.25, uDisplace*0.2, 1.0) : bgDisp.rgb;

    return bgDisp * color.a;
  } else {
    vec2 normal = vec2(bg.r * 2.0 - 1.0, bg.g * 2.0 - 1.0) * 0.1; // Convert the color range from [0, 1] to [-1, 1]
    if(uMask == 1.) {
      return texture(uTexture, st + normal * uDisplace) * texture(uTexture, st + normal * uDisplace).a;
    } else {
      return texture(uTexture, st + normal * uDisplace);
    }
  }
}

vec2 perspectiveUV(vec2 uv) {
  float aspectRatio = uResolution.x/uResolution.y;
  vec2 centeredUV = uv - 0.5;
  centeredUV.x *= aspectRatio;
  float strength = 1.0 + (vVertexPosition.z * uAxisTilt);
  vec2 perspectiveUV = centeredUV / strength;
  perspectiveUV.x /= aspectRatio;
  perspectiveUV += 0.5;
  return perspectiveUV;
}

out vec4 fragColor;

vec3 linearize(vec3 color) {
  return pow(color, vec3(2.2));
}

vec3 delinearize(vec3 color) {
  return pow(color, vec3(1.0 / 2.2));
}


void main() {
  vec2 uv = vTextureCoord;
  vec2 pos = mix(vec2(0), (uMousePos - 0.5), uTrackMouse);
  uv = perspectiveUV(uv) - pos;
  vec4 background = vec4(0);

  if(uSampleBg == 1) {
    background = texture(uBgTexture, vTextureCoord);
  }
  
  vec4 color = texture(uTexture, uv);

  // #ifelseopen
  if (uDisplace > 0.00) {
    color = displacement(uv, background, color);
  }
  // #ifelseclose

  if (uMask == 1.00) {
    if(uMaskDepth == 2) {
      background *= (1. - color.a);
      color = background;
    } else {
      background *= color.a;
      color.rgb = background.rgb;
      color.rgb = uMaskAlpha > 0. ? mix(uMaskBg, color.rgb, color.a) : color.rgb;
      color.a = min(1., color.a + uMaskAlpha);
  
      if(uMaskDepth == 1) {
        vec4 previousLayer = texture(uPreviousLayerTexture, vTextureCoord);
        color = mix(previousLayer, color/max(color.a, 0.0001), color.a * uOpacity);
      }
    }
    fragColor = color;
    return;
  }

  if (uBlendMode > 0) {
    float originalAlpha = color.a;
    float blendedAlpha = color.a + background.a * (1.0 - color.a);
    color.rgb = mix(background.rgb, color.rgb, blendedAlpha);
    vec3 blendedColor = blend(uBlendMode, color.rgb, background.rgb);
    color = mix(background, vec4(blendedColor, originalAlpha), originalAlpha * uOpacity);
  } else {
    color = mix(background, color/max(color.a, 0.0001), color.a * uOpacity);
  }

  fragColor = color;
}
`;

export const basicParams = {
  fragmentShader: elementFragmentShader,
  vertexShader: elementVertexShader,
  crossorigin: 'Anonymous',
  texturesOptions: {
    premultiplyAlpha: true,
  },
  uniforms: {
    dispersion: {
      name: 'uDispersion',
      type: '1f',
      value: 0,
    },
    displace: {
      name: 'uDisplace',
      type: '1f',
      value: 0,
    },
    bgDisplace: {
      name: 'uBgDisplace',
      type: '1f',
      value: 0,
    },
    resolution: {
      name: 'uResolution',
      type: '2f',
      value: new Vec2(1080, 1080),
    },
    mousePos: {
      name: 'uMousePos',
      type: '2f',
      value: new Vec2(0.5),
    },
    opacity: {
      name: 'uOpacity',
      type: '1f',
      value: 1,
    },
    trackMouse: {
      name: 'uTrackMouse',
      type: '1f',
      value: 0,
    },
    axisTilt: {
      name: 'uAxisTilt',
      type: '1f',
      value: 0,
    },
    mask: {
      name: 'uMask',
      type: '1f',
      value: 0,
    },
    maskAlpha: {
      name: 'uMaskAlpha',
      type: '1f',
      value: 0,
    },
    maskDepth: {
      name: 'uMaskDepth',
      type: '1i',
      value: 0,
    },
    maskBackground: {
      name: 'uMaskBg',
      type: '3f',
      value: new Vec3(0),
    },
    sampleBg: {
      name: 'uSampleBg',
      type: '1i',
      value: 1,
    },
    blendMode: {
      name: 'uBlendMode',
      type: '1i',
      value: 0,
    },
  },
};
