import { Vec2, Vec3 } from 'curtainsjs';
import { StudioStore } from '../stores/StudioStore';

export const generateUUID = () => {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

function interpolate(start, end, progress) {
  return start + (end - start) * progress;
}

function weightedLerp(current, last, amount) {
  for (let i = 0; i < amount; i++) {
    current = (current + last) / 2;
  }
  return +((current + last) / 2).toFixed(4);
}

export function getEaseFunction(ease) {
  switch (ease) {
    case 'linear':
      return x => x;
    case 'easeInQuad':
      return x => x * x;
    case 'easeOutQuad':
      return x => 1 - (1 - x) * (1 - x);
    case 'easeInOutQuad':
      return x => (x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2);
    case 'easeInCubic':
      return x => x * x * x;
    case 'easeInOutCubic':
      return x => (x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2);
    case 'easeOutCubic':
      return x => 1 - Math.pow(1 - x, 3);
    case 'easeInQuart':
      return x => x * x * x * x;
    case 'easeOutQuart':
      return x => 1 - Math.pow(1 - x, 4);
    case 'easeInOutQuart':
      return x => (x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2);
    case 'easeInQuint':
      return x => x * x * x * x * x;
    case 'easeOutQuint':
      return x => 1 - Math.pow(1 - x, 5);
    case 'easeInOutQuint':
      return x => (x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2);
    case 'easeOutElastic':
      return x => {
        const c4 = (2 * Math.PI) / 3;
        return x === 0 ? 0 : x === 1 ? 1 : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
      };
    case 'easeInElastic':
      return x => {
        const c4 = (2 * Math.PI) / 3;
        return x === 0 ? 0 : x === 1 ? 1 : -Math.pow(2, 10 * x - 10) * Math.sin((x * 10 - 10.75) * c4);
      };
    case 'easeInOutElastic':
      return x => {
        const c5 = (2 * Math.PI) / 4.5;
        return x === 0
          ? 0
          : x === 1
          ? 1
          : x < 0.5
          ? -(Math.pow(2, 20 * x - 10) * Math.sin((20 * x - 11.125) * c5)) / 2
          : (Math.pow(2, -20 * x + 10) * Math.sin((20 * x - 11.125) * c5)) / 2 + 1;
      };
    case 'easeInSine':
      return x => 1 - Math.cos((x * Math.PI) / 2);
    case 'easeOutSine':
      return x => Math.sin((x * Math.PI) / 2);
    case 'easeInOutSine':
      return x => -(Math.cos(Math.PI * x) - 1) / 2;
    case 'easeInCirc':
      return x => 1 - Math.sqrt(1 - Math.pow(x, 2));
    case 'easeOutCirc':
      return x => Math.sqrt(1 - Math.pow(x - 1, 2));
    case 'easeInOutCirc':
      return x =>
        x < 0.5 ? (1 - Math.sqrt(1 - Math.pow(2 * x, 2))) / 2 : (Math.sqrt(1 - Math.pow(-2 * x + 2, 2)) + 1) / 2;
    case 'easeInExpo':
      return x => (x === 0 ? 0 : Math.pow(2, 10 * x - 10));
    case 'easeOutExpo':
      return x => (x === 1 ? 1 : 1 - Math.pow(2, -10 * x));
    case 'easeInOutExpo':
      return x =>
        x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ? Math.pow(2, 20 * x - 10) / 2 : (2 - Math.pow(2, -20 * x + 10)) / 2;
    default:
      return x => x; // Linear as default
  }
}

function cloneVector(vector, invertY) {
  let result = vector;
  if (vector.type === 'Vec2') {
    result = new Vec2(vector._x, vector._y);
    if (invertY) {
      result.y = 1 - result.y;
    }
  } else if (vector.type === 'Vec3') {
    result = new Vec3(vector._x, vector._y, vector._z);
  }
  return result;
}

function packageValue(value) {
  if (value?.type === 'Vec2') {
    return {
      type: 'Vec2',
      _x: value._x,
      _y: value._y,
    };
  } else if (value?.type === 'Vec3') {
    return {
      type: 'Vec3',
      _x: value._x,
      _y: value._y,
      _z: value._z,
    };
  } else {
    return value;
  }
}

export class StateEffectAppear {
  type = 'appear';
  constructor({ prop, value, transition, id }, itemId) {
    if (id) {
      this.id = id;
    } else {
      this.id = generateUUID();
    }
    this.prop = prop;
    this.transition = transition;
    this.complete = false;
    this.progress = 0;
    this.itemId = itemId;
    this.initialStateSet = false;
    this.value = cloneVector(value, this.prop === 'pos');
  }

  initializeElementState() {
    this.item = StudioStore.getItemById(this.itemId);
    if(this.prop in this.item) {
      this.item.updateStateEffectProperty(this.prop, this.value);
    }
    this.initialStateSet = true;
  }

  initializeEffectState(plane) {
    this.item = StudioStore.getItemById(plane.userData.id);
    const uniform = plane.uniforms[this.prop];

    if (uniform) {
      const isVector = typeof this.value === 'object';
      if (isVector) {
        uniform.value = cloneVector(this.value, this.prop === 'pos');
      } else {
        uniform.value = this.value;
      }
    }
    this.initialStateSet = true;
  }

  package() {
    return {
      id: this.id,
      prop: this.prop,
      value: packageValue(this.value),
      transition: this.transition,
    };
  }

  updateEffect(plane, endVal) {
    if (endVal === undefined) {
      return false;
    }
    const isVector = typeof this.value === 'object' && ('x' in this.value || 'y' in this.value || 'z' in this.value);
    if (this.complete || !plane.userData.createdAt) {
      return false;
    } else if (!this.initialStateSet) {
      if(this.itemId) {
        this.initializeElementState();
      } else {
        this.initializeEffectState(plane);
      }
      if (isVector) {
        this.endVal = cloneVector(endVal, this.prop === 'pos');
        this.startVal = cloneVector(this.value, this.prop === 'pos');
      } else {
        this.endVal = endVal;
        this.startVal = this.value;
      }
      return false;
    }

    let currentTime = performance.now();

    const uniform = plane.uniforms[this.prop];
    const easeFunction = getEaseFunction(this.transition.ease);
    const effectStart = plane.userData.createdAt + this.transition.delay;
    const progress = Math.max(0, Math.min(1, (currentTime - effectStart) / this.transition.duration));

    this.progress = easeFunction(progress);

    let startVal = this.value;

    if (!uniform && !this.item.isElement) {
      return false;
    };

    let easing = this.progress;

    if (!uniform && this.item.isElement && this.prop in this.item) {
      this.item.updateStateEffectProperty(
        this.prop, 
        interpolate(this.startVal, this.endVal, easing)
      );
      
      if (progress >= 1) {
        this.complete = true;
        this.progress = 0;
        this.item.removeStateEffectProperty(this.prop);
      }
  
      this.lastTick = currentTime;
  
      return true;
    }

    if (this.progress > 0 && this.progress <= 1) {
      if (isVector) {
        uniform.value.x = interpolate(startVal.x, this.endVal.x, easing);

        if (this.prop === 'pos') {
          uniform.value.y = interpolate(1 - startVal.y, this.endVal.y, easing);
        } else {
          uniform.value.y = interpolate(startVal.y, this.endVal.y, easing);
        }
        if (startVal.type === 'Vec3') {
          uniform.value.z = interpolate(startVal.z, this.endVal.z, easing);
        }
      } else {
        uniform.value = interpolate(startVal, this.endVal, easing);
      }
    } else {
      if (isVector) {
        uniform.value = cloneVector(this.value, this.prop === 'pos');
      } else {
        uniform.value = this.value;
      }
    }
    if (progress >= 1) {
      this.complete = true;
      this.progress = 0;
    }

    this.lastTick = currentTime;

    return true;
  }

  resetState() {
    this.progress = 0;
    this.complete = false;
    this.initialStateSet = false;
  }
}

export class StateEffectScroll {
  type = 'scroll';

  constructor({
    prop,
    value,
    range,
    offset,
    momentum,
    id,
    mode = 'scrollIntoView',
    delta = 0.01,
    absScrollValue = true,
  }, itemId) {
    if (id) {
      this.id = id;
    } else {
      this.id = generateUUID();
    }
    this.prop = prop;
    this.progress = 0;
    this.momentum = momentum;
    this.range = range;
    this.offset = offset;
    this.mode = mode;
    this.delta = delta;
    this.sceneTop = 0;
    this.startScroll = 0;
    this.endScroll = 0;
    this.lastScrollTop = 0;
    this.absScrollValue = absScrollValue;

    this.value = cloneVector(value, this.prop === 'pos');
  }

  package() {
    return {
      id: this.id,
      prop: this.prop,
      value: packageValue(this.value),
      range: this.range,
      offset: this.offset,
      momentum: this.momentum,
      mode: this.mode,
      complete: false,
      delta: this.delta,
      absScrollValue: this.absScrollValue,
    };
  }

  updateEffect(plane, startVal, { top, height, width, scroll }) {
    if (startVal === undefined) {
      return false;
    }

    
    const uniform = plane.uniforms[this.prop];
    const isVector = this.value.type && uniform.value.type;

    if (!uniform) return false;

    const viewportHeight = window.innerHeight;
    const scrollTop = scroll + 1 || window.scrollY || window.pageYOffset;

    if (this.mode === 'scrollIntoView') {
      const startScroll = top - viewportHeight * this.offset;
      const endScroll = startScroll + (viewportHeight + height) * this.range;

      let progress = (scrollTop - startScroll) / (endScroll - startScroll);

      this.startScroll = startScroll / window.innerHeight;
      this.endScroll = endScroll / window.innerHeight;
      this.sceneHeight = height / window.innerHeight;
      this.sceneWidth = width / window.innerHeight;

      this.sceneTop = top;

      progress = Math.max(0, Math.min(1, progress));

      this.progress = progress;

      let endVal = this.value;

      let easing = progress;
      if (this.lastTick !== undefined) {
        easing = weightedLerp(easing, this.lastTick, this.momentum * 2);
      }

      if (Math.abs(this.lastTick - easing) < 0.001) {
        return false;
      }

      if (isVector) {
        uniform.value.x = interpolate(startVal.x, endVal.x, easing);
        uniform.value.y = interpolate(startVal.y, endVal.y, easing);

        if (startVal.type === 'Vec3') {
          uniform.value.z = interpolate(startVal.z, endVal.z, easing);
        }
      } else {
        uniform.value = interpolate(startVal, endVal, easing);
      }

      this.lastTick = easing;
    } else if (this.mode === 'whileScrolling') {
      const scrollDelta = scrollTop - this.lastScrollTop;
      this.lastScrollTop = scrollTop;

      let easing = scrollDelta * this.delta;
      let endVal = this.value;

      if (this.absScrollValue) {
        easing = Math.abs(easing);
      }

      if (this.lastTick !== undefined) {
        easing = weightedLerp(easing, this.lastTick, this.momentum * 2);

        if (Math.abs(easing) < 0.001) {
          return false;
        }
      }

      if (isVector) {
        uniform.value.x = interpolate(startVal.x, endVal.x, easing);
        uniform.value.y = interpolate(startVal.y, endVal.y, easing);

        if (startVal.type === 'Vec3') {
          uniform.value.z = interpolate(startVal.z, endVal.z, easing);
        }
      } else {
        uniform.value = interpolate(startVal, endVal, easing);
      }

      this.lastTick = easing;

      // if (easing > 1) {
      //   this.complete = true;
      // } else if (easing <= 0) {
      //   this.complete = true;
      // } else {
      //   this.complete = false;
      // }
    }

    return true;
  }
}

export class StateEffectHover {
  type = 'hover';
  constructor({ prop, value, transition, id }, itemId) {
    if (id) {
      this.id = id;
    } else {
      this.id = generateUUID();
    }
    this.prop = prop;
    this.transition = transition;
    this.progress = 0;
    this.rawProgress = 0;
    this.lastProgress = null;
    this.value = cloneVector(value, this.prop === 'pos');
  }

  package() {
    return {
      id: this.id,
      prop: this.prop,
      value: packageValue(this.value),
      transition: this.transition,
    };
  }

  updateEffect(plane, startVal, enterTime) {
    if (startVal === undefined) {
      return false;
    }

    const isVector = typeof this.value === 'object' && ('x' in this.value || 'y' in this.value || 'z' in this.value);

    let currentTime = performance.now();
    let effectStart;
    let reverse = false;

    if (enterTime === null) {
      reverse = true;
      effectStart = this.lastTick || currentTime;
      this.lastProgress = this.rawProgress;
    } else {
      effectStart = enterTime + this.transition.delay;
    }

    const uniform = plane.uniforms[this.prop];
    const rawProgress = Math.max(0, Math.min(1, (currentTime - effectStart) / this.transition.duration));

    let progress = reverse ? this.rawProgress - rawProgress : this.lastProgress + rawProgress;
    this.rawProgress = Math.max(0, Math.min(1, progress));
    this.progress = getEaseFunction(this.transition.ease)(this.rawProgress);

    const updateUniform = () => {
      if (isVector) {
        uniform.value.x = interpolate(startVal.x, this.value.x, this.progress);

        if (this.prop === 'pos') {
          uniform.value.y = interpolate(startVal.y, 1 - this.value.y, this.progress);
        } else {
          uniform.value.y = interpolate(startVal.y, this.value.y, this.progress);
        }
        if (this.value.type === 'Vec3') {
          uniform.value.z = interpolate(startVal.z, this.value.z, this.progress);
        }
      } else {
        uniform.value = interpolate(startVal, this.value, this.progress);
      }
    };

    if (!uniform) return false;
    if (!enterTime && this.progress === 0) {
      if (this.lastProgress !== this.progress) {
        updateUniform();
      }
      return false;
    }
    if (!enterTime && this.transition.forwardsOnly) {
      this.progress = 0;
      this.rawProgress = 0;
    }

    updateUniform();

    this.lastTick = currentTime;
    this.lastEnterTime = enterTime;

    return this.progress > 0 && this.progress < 1;
  }

  resetState() {
    this.progress = 0;
  }
}
