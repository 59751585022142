<script>
import InputField from "./InputField.vue";
import ParamLabel from "./ParamLabel.vue";
import RadioToggle from "./RadioToggle.vue";
import DropdownMenu from "./DropdownMenu.vue";

export default {
  name: 'SizeInput',
  components: {
    InputField,
    ParamLabel,
    DropdownMenu,
    RadioToggle
  },
  props: ['modelValue', 'dimension', 'label', 'mode', 'alternateMode', 'auto'],
  emits: ['update:modelValue', 'input', 'update', 'change-mode'],
  data() {
    return {
      auto: this.auto || true,
      options: this.auto ? {
        'fixed': 'Fixed',
        'relative': 'Rel',
        'auto': 'Auto'
      } : {
        'fixed': 'Fixed',
        'relative': 'Rel',
      }
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    },
    dimensionMode: {
      get() {
        return this.mode;
      },
      set(newMode) {
        this.$emit('change-mode', newMode);
      }
    }
  },
  methods: {
    handleInput() {
      this.$emit('input');
    },
    handleUpdate() {
      this.$emit('update');
    },
    handleModeChange(newMode) {
      this.$emit('change-mode', newMode);
    },
  }
};
</script>

<template>
  <div class="parameter parameter__block">
    <ParamLabel
      :prop="dimension"
      :label="label"
      @reset-breakpoint-prop="handleUpdate"
    >{{ label }}</ParamLabel>
    <div class="size-input-wrapper w-100 flex" style="max-width: var(--param-width)">
      <InputField
        v-model="value"
        :slider="true"
        :disabled="dimensionMode === 'auto'"
        @change="handleInput"
        :output="dimensionMode === 'auto' ? alternateMode === 'fixed' ? 'px' : 'percent' : dimensionMode === 'fixed' ? 'px' : 'percent'"
      />
      <DropdownMenu
        v-model="dimensionMode"
        style="max-width: 7rem"
        class="ml-1"
        :options="options"
      />
    </div>
  </div>
</template>