import {
  computeFragColor,
  FLOATING_POINT,
  SIMPLEX_2S_NOISE,
  PERLIN_NOISE,
  EASE,
  mixRadiusProperties,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
  interactiveProperties,
  vertexShader,
} from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const ungulateShader = `#version 300 es
  precision highp float;
  
  in vec2 vTextureCoord;
  in vec3 vVertexPosition;

  uniform sampler2D uTexture;
  uniform float uMixRadius;
  uniform vec2 uPos;
  uniform int uEasing;
  uniform int uMixRadiusInvert;
  uniform float uTime;
  uniform float uPhase;
  uniform float uAngle;
  uniform float uFrequency;
  uniform float uTurbulence;
  uniform float uDirection;
  uniform int uNoiseType;

  ${UNIVERSAL_UNIFORMS}
  ${EASE}
  ${SIMPLEX_2S_NOISE}
  ${PERLIN_NOISE}

  out vec4 fragColor;

  const float PI = 3.14159265359;

  vec2 hash22(vec2 p) {
    vec3 p3 = fract(vec3(p.xyx) * vec3(.1031, .1030, .0973));
    p3 += dot(p3, p3.yzx+33.33);
    return fract((p3.xx+p3.yz)*p3.zy);
  }

  mat2 rot(float a) {
    return mat2(cos(a),-sin(a),sin(a),cos(a));
  }

  vec2 get2sNoise(vec2 uv, vec2 textureCoord) {
    vec4 noise = bccNoiseDerivatives_XYBeforeZ(vec3(uv * vec2(uDirection, 1. - uDirection) * 0.7, uPhase + uTime*0.02));
    vec2 offset = noise.xy/7. + 0.5;
    return mix(textureCoord, offset, uTurbulence);
  }

  vec2 getPerlinNoise(vec2 uv, vec2 textureCoord) {
    vec2 skew = vec2(uDirection, 1. - uDirection);
    float noiseX = perlin_noise(vec3(
        uv * skew, 
        uPhase + uTime * 0.03
    ));

    float noiseY = perlin_noise(vec3(
        (uv + vec2(4.37)) * skew, 
        uPhase + uTime * 0.03
    ));

    vec2 offset = vec2(noiseX * 1.5, noiseY * 1.5) * 0.5 + 0.5;

    return mix(textureCoord, offset, uTurbulence * 1.2);
  }

  vec3 hash3(vec2 p) {
    vec3 q = vec3(dot(p,vec2(127.1,311.7)), 
           dot(p,vec2(269.5,183.3)), 
           dot(p,vec2(419.2,371.9)));
    return fract(sin(q)*43758.5453);
  }

  float voronoise(vec2 uv, vec2 textureCoord) {
    float u = 1.;
    float v = 1.;
    uv *= vec2(uDirection, 1.0 - uDirection);
    uv *= 2.91;
    
    //uv += uTime * 0.2;

    vec2 x = uv;
    vec2 p = floor(x);
    vec2 f = fract(x);
		
    float k = 1.0 + 63.0 * pow(1.0-v,4.0);
  
    float va = 0.0;
    float wt = 0.0;
    for( int j=-2; j<=2; j++ )
        for( int i=-2; i<=2; i++ ) {
            vec2 g = vec2( float(i),float(j) );
            vec3 o = hash3( p + g ) * vec3(u,u,1.0);
            o.xy += 0.5 * vec2(
                sin(uTime * 0.1 + uPhase + o.x * 6.28),
                cos(uTime * 0.1 + uPhase + o.y * 6.28)
            );
            vec2 r = g - f + o.xy;
            float d = dot(r,r);
            float ww = pow( 1.0-smoothstep(0.0,1.414,sqrt(d)), k );
            va += o.z*ww;
            wt += ww;
        }
  
    return va/wt;
  }
  
  vec2 getVoronoiNoise(vec2 uv, vec2 textureCoord) {
    vec2 offset = vec2(voronoise(uv, textureCoord), voronoise(uv + vec2(9.2, 1.2), textureCoord));

    return mix(textureCoord, offset, uTurbulence);
  }

  vec2 getNoiseOffset(vec2 uv, vec2 textureCoord) {
    switch(uNoiseType) {
      case 0: return get2sNoise(uv, textureCoord); break;
      case 1: return getPerlinNoise(uv, textureCoord); break;
      case 2: return getVoronoiNoise(uv, textureCoord); break;
      default: return vec2(0.);
    }
  }

  void main() {
    vec2 uv = vTextureCoord;
    float aspectRatio = uResolution.x/uResolution.y;

    vec2 mPos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
    vec2 pos = uMixRadius == 1.00 ? mPos : uPos;
    
    vec2 st = (uv - pos) * vec2(aspectRatio, 1);
    st *= 12. * uFrequency;
    st = rot(uAngle * -1. * 2.0 * PI) * st;

    vec2 noise = getNoiseOffset(st, uv);
    
    float dist = ease(uEasing, max(0.,1.-distance(uv * vec2(aspectRatio, 1), mPos * vec2(aspectRatio, 1)) * 4. * (1. - uMixRadius)));

    if (uMixRadiusInvert == 1) {
        dist = max(0., (0.5 - dist));
    }

    uv = mix(uv, noise, dist);
    vec4 color = texture(uTexture, uv);
    ${computeFragColor('color')}
  }
`;

const noiseParams = {
  fragmentShader: ungulateShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    turbulence: {
      name: 'uTurbulence',
      type: '1f',
      value: 0.5,
    },
    noiseType: {
      name: 'uNoiseType',
      type: '1i',
      value: 0,
    },
    frequency: {
      name: 'uFrequency',
      type: '1f',
      value: 0.2,
    },
    direction: {
      name: 'uDirection',
      type: '1f',
      value: 0.5,
    },
    phase: {
      name: 'uPhase',
      type: '1f',
      value: 0,
    },
    angle: {
      name: 'uAngle',
      type: '1f',
      value: 0,
    },
    easing: {
      name: 'uEasing',
      type: '1i',
      value: 0,
    },
    mixRadius: {
      name: 'uMixRadius',
      type: '1f',
      value: 1,
    },
    mixRadiusInvert: {
      name: 'uMixRadiusInvert',
      type: '1i',
      value: 0,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 1,
    },
    ...universalUniformParams,
  },
};

export const NOISE = {
  id: 'noise',
  label: 'Noise',
  params: noiseParams,
  animation: {
    active: false,
    speed: 1,
  },
  aspectRatio: 1,
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    noiseType: {
      label: 'Type',
      value: 0,
      options: {
        0: 'Simplex 2S',
        1: 'Perlin',
        2: 'Voronoi',
      },
    },
    frequency: {
      label: 'Scale',
      value: 0.2,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the scale of the noise pattern',
    },
    turbulence: {
      label: 'Amplitude',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the strength of the distortion',
    },
    direction: {
      label: 'Skew',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Skews the distortion along the X or Y axis',
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
    },
    phase: {
      label: 'Phase',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the time element of the effect',
    },
    ...mixRadiusProperties,
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ...interactiveProperties,
  },
};

