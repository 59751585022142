<script>
import { StudioStore } from '../stores/StudioStore.js';
import PlayButton from './PlayButton.vue';
import { formatStateEffectValue } from '../scripts/Helpers.js';

export default {
  components: { PlayButton },
  data() {
    return {
      baseGridStep: 100,
      snapStep: 25,
      labelWidth: 120,
      rowHeight: 35,
      currentTime: 0,
      animationFrame: null,
      startTime: null,
      state: StudioStore.state,
      playheadPosition: 0,
      isHovered: false,
      collapseTimeout: null,
      isCollapsing: false,
      playback: {
        isPlaying: false,
        currentTime: 0,
        duration: 0,
        pausedAt: 0
      },
    }
  },
  watch: {
    "state.previewing": {
      handler(newVal) {
        if(newVal) {
          this.startPlayback();
        } else {
          this.stopPlayback();
        }
      }
    }
  },
  computed: {
    selectedAppearItems() {
      return StudioStore.state.selectedItems.filter(item => item.states.appear.length).length > 0;
    },
    groupedEvents() {
      const groups = {};
      if (!StudioStore.state.history) return groups;
      
      Object.entries(StudioStore.state.history).forEach(([itemId, item]) => {
        if (item && item.states && item.states.appear) {
          item.states.appear.forEach((event, index) => {
            const eventId = `${itemId}-${index}`;
            const eventWithItem = {
              ...event,
              item: item
            };
            groups[eventId] = {
              name: item.name || itemId,
              events: [eventWithItem]
            };
          });
        }
      });
      
      return groups;
    },
    totalDuration() {
      let maxTime = 1000;
      Object.values(this.groupedEvents).forEach(group => {
        group.events.forEach(event => {
          const eventEnd = event.transition.delay + event.transition.duration;
          maxTime = Math.max(maxTime, eventEnd);
        });
      });
      return Math.ceil((maxTime * 1.2) / this.baseGridStep) * this.baseGridStep;
    },
    timeMarkers() {
      const markers = [];
      for (let time = 0; time <= this.totalDuration; time += this.baseGridStep) {
        markers.push((time / 1000).toFixed(1));
      }
      return markers;
    },
    gridSize() {
      return `${100 / (this.timeMarkers.length - 1)}%`;
    },
    isPlaying() {
      return this.playback.isPlaying;
    },
    playheadStyle() {
      return {
        left: `${this?.playheadPosition}%`,
        top: !this?.selectedAppearItems && !this?.isHovered ? '-0.5rem' : '0'
      }
    },
    timelineHeight() {
      if (!this?.selectedAppearItems && !this.isHovered) {
        return '3.8rem' // Height of timeline-header
      }
      return 'auto'
    },
    playbackDuration() {
      let maxTime = 1000;
      StudioStore.state.history.forEach(item => {
        if (item.states?.appear) {
          item.states.appear.forEach(event => {
            const eventEnd = event.transition.delay + event.transition.duration;
            maxTime = Math.max(maxTime, eventEnd);
          });
        }
      });
      return maxTime;
    }
  },
  methods: {
    handleDragStart(itemId, event, dragEvent, type = 'move') {
      dragEvent.dataTransfer.setData('text/plain', '');
      const img = new Image();
      img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
      dragEvent.dataTransfer.setDragImage(img, 0, 0);
      
      // Store initial values
      this.dragStartX = dragEvent.clientX;
      this.dragType = type;
      this.dragEvent = event;
      this.initialDelay = event.transition.delay;
      this.initialDuration = event.transition.duration;
      this.dragItemId = itemId;
      this.initialTotalDuration = this.totalDuration;
    },

    handleDrag(itemId, event, dragEvent) {
      if (!dragEvent.clientX) return;
      
      const timeline = this.$el.querySelector('.timeline-grid');
      const rect = timeline.getBoundingClientRect();
      const pixelsPerMs = rect.width / this.totalDuration;

      if (this.dragType === 'move') {
        const deltaX = dragEvent.clientX - this.dragStartX;
        const deltaMs = deltaX / pixelsPerMs;
        const newDelay = this.initialDelay + deltaMs;
        const snappedDelay = Math.round(newDelay / this.snapStep) * this.snapStep;
        event.transition.delay = Math.max(0, Math.min(snappedDelay, this.totalDuration));
      } else if (this.dragType === 'resize-end') {
        const deltaX = dragEvent.clientX - this.dragStartX;
        const deltaMs = deltaX / pixelsPerMs;
        const newDuration = this.initialDuration + deltaMs;
        const snappedDuration = Math.round(newDuration / this.snapStep) * this.snapStep;
        
        event.transition.duration = Math.max(
          this.snapStep,
          Math.min(snappedDuration, this.totalDuration - event.transition.delay)
        );
      }
      
      this.$emit('update:event', { itemId, event });
    },

    handleEventClick(itemId, event) {
      let item = event.item;
      StudioStore.setSelectedItem(item);
      StudioStore.state.openStateEffect = item.states.appear.find(n => n.id === event.id);
    },

    togglePlayback() {
      if (this.isPlaying) {
        this.pausePlayback();
      } else {
        this.startPlayback();
      }
    },

    startPlayback() {
      if(!StudioStore.state.curtain?.planes) return;
      
      if (this.playback.pausedAt) {
        this.startTime = performance.now() - this.playback.pausedAt;
        
        // Adjust all animation timestamps to resume from pause point
        StudioStore.state.curtain.planes.forEach(plane => {
          plane.userData.createdAt = performance.now() - this.playback.pausedAt;
        });
        
        this.playback.pausedAt = 0;
      } else {
        // Reset animation states
        StudioStore.state.curtain.planes.forEach(plane => {
          plane.userData.createdAt = performance.now();
        });
        
        // Reset appear effects
        StudioStore.state.history
          .filter(n => n.states && n.states.appear.length)
          .forEach(item => {
            item.states.appear.forEach(effect => {
              effect.resetState();
            });
          });
        
        this.startTime = performance.now();
      }
      
      this.playback.isPlaying = true;
      this.animate();
    },

    pausePlayback() {
      this.playback.isPlaying = false;
      this.playback.pausedAt = performance.now() - this.startTime;
      
      // Freeze all animations by updating their createdAt times
      StudioStore.state.curtain.planes.forEach(plane => {
        plane.userData.createdAt = this.startTime - (performance.now() - this.playback.pausedAt);
      });
      
      if (this.animationFrame) {
        cancelAnimationFrame(this.animationFrame);
      }
    },

    stopPlayback() {
      this.playback.isPlaying = false;
      this.playback.currentTime = 0;
      this.playback.pausedAt = 0;
      if (this.animationFrame) {
        cancelAnimationFrame(this.animationFrame);
      }
    },

    updatePlaybackTime(currentTime) {
      this.playback.currentTime = currentTime;
      
      StudioStore.state.history
        .filter(n => n.states && n.states.appear.length && n.plane)
        .forEach(item => {
          item.plane.userData.createdAt = performance.now() - currentTime;
          
          item.states.appear.forEach(effect => {
            if (effect.complete && currentTime < effect.transition.delay + effect.transition.duration) {
              effect.resetState();
            }
          });
        });
    },

    animate() {
      if (!this.playback.isPlaying) return;
      
      const currentTime = performance.now() - this.startTime;
      const playbackTime = Math.min(currentTime, this.playbackDuration);
      
      // Update playhead position
      this.playheadPosition = (playbackTime / this.playbackDuration) * 100;
      
      // Update current playback time
      this.updatePlaybackTime(playbackTime);
      
      if (playbackTime >= this.playbackDuration) {
        this.stopPlayback();
        this.playheadPosition = 0;
        return;
      }
      
      this.animationFrame = requestAnimationFrame(() => this.animate());
    },

    formatValue(value, event) {
      if (!event || !event.item || !event.prop) {
        return value;
      }

      if (event.prop === 'width' || event.prop === 'height') {
        const mode = event.prop === 'width' ? event.item.widthMode : event.item.heightMode;
        return mode === 'percent' ? `${Math.round(value)}%` : `${Math.round(value)}px`;
      }
      const params = event.item.getParams();
      if (!params) {
        return value;
      }

      return formatStateEffectValue(value, event.prop, params);
    },

    handleKeydown(event) {
      if (event.code === 'Space' && this.isHovered) {
        this.togglePlayback();
      }
    },

    handleMouseEnter() {
      if (this.collapseTimeout) {
        clearTimeout(this.collapseTimeout);
      }
      this.isCollapsing = false;
      this.isHovered = true;
    },
    
    handleMouseLeave() {
      clearTimeout(this.collapseTimeout);
      if(this?.selectedAppearItems) {
        this.isHovered = false;
        return;
      };
      
      this.collapseTimeout = setTimeout(() => {
        this.isCollapsing = true;
        setTimeout(() => {
          this.isCollapsing = false;
          this.isHovered = false;
        }, 200); // Match this with CSS transition duration
      }, 500); // Delay before starting collapse
    }
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
    this.stopPlayback();
  }
}
</script>

<template>
  <div 
    class="timeline-container"
    :class="{
      'timeline-peek': !selectedAppearItems && !isHovered,
      'timeline-collapsing': isCollapsing
    }"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div class="timeline-content" :style="{ height: timelineHeight }">
      <div class="timeline-left-column">
        <div class="timeline-controls">
          <PlayButton 
            :animating="isPlaying"
            :visible="true"
            :animation="true"
            :borderless="true"
            @toggle-animation="togglePlayback"
          />
        </div>
        <template v-for="[itemId, group] in Object.entries(groupedEvents)" :key="itemId">
          <div v-for="event in group.events" 
               :key="`${itemId}-${event.id}`" 
               class="property-label">
            {{ event.item.getParams().properties[event.prop].label }}
          </div>
        </template>
      </div>

      <div class="timeline-main">
        <div class="timeline-header">
          <div class="time-markers">
            <div v-for="time in timeMarkers" 
                 :key="time" 
                 class="marker"
                 :style="{ left: `${(time * 1000 / totalDuration) * 100}%` }">
              {{ time }}
            </div>
          </div>
        </div>
        
        <div class="timeline-body">
          <div class="timeline-grid">
            <div class="grid-background"></div>
            <div class="playhead" :style="playheadStyle">
              <div class="playhead-caret"></div>
              <div class="playhead-line"></div>
            </div>
            
            <template v-for="[itemId, group] in Object.entries(groupedEvents)" :key="itemId">
              <div class="item-group">
                <div v-for="event in group.events" 
                     :key="`${itemId}-${event.id}`" 
                     class="timeline-row">
                  <div class="event-bar"
                       :style="{
                         left: `${(event.transition.delay / totalDuration) * 100}%`,
                         width: `${(event.transition.duration / totalDuration) * 100}%`,
                       }"
                       draggable="true"
                       @click="handleEventClick(itemId, event)"
                       @dragstart.stop="handleDragStart(itemId, event, $event, 'move')"
                       @drag="handleDrag(itemId, event, $event)">
                    <div class="event-bar-content" style="position: relative;">
                      <div class="event-value start">{{ formatValue(event.value, event) }}</div>
                      <div class="event-bar-duration">{{ event.transition.duration }}ms</div>
                      <div class="event-value end">{{ formatValue(event.item[event.prop], event) }}</div>
                    </div>
                    <div class="resize-handle right"
                         draggable="true"
                         @dragstart.stop="handleDragStart(itemId, event, $event, 'resize-end')"
                         @drag.stop="handleDrag(itemId, event, $event)"></div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.timeline-container {
  position: absolute;
  bottom: 0.5rem;
  left: 29rem;
  right: 29rem;
  background: var(--bg-color);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  border-radius: 0.4rem;
  transition: transform 0.2s ease-out;
  
  &.timeline-peek {
    transform: translateY(calc(100% - 3.8rem));
    
    .timeline-left-column {
      // Hide everything except the controls in collapsed state
      > *:not(.timeline-controls) {
        display: none;
      }
    }
  }

  &.timeline-collapsing {
    transform: translateY(calc(100% - 3.8rem));
    transition: transform 0.2s ease-in;
  }
}

.timeline-content {
  min-width: min-content;
  transition: height 0.2s ease-out;
  display: flex;
  flex-direction: row;
  height: 100%; // Ensure full height
}

.timeline-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.timeline-header {
  height: 3.8rem;
  min-height: 3.8rem; // Add min-height to prevent collapse
  border-bottom: 1px solid var(--border-color);
  position: sticky;
  top: 0;
  z-index: 2;
  background: var(--bg-color);
}

.time-markers {
  flex: 1;
  position: relative;
  margin-left: -1px; // Align with grid lines
  user-select: none;
  
  .marker {
    position: absolute;
    transform: translateX(-50%);
    color: var(--font-tertiary-color);
    font-size: 12px;
    padding-top: 1rem;
  }
}

.timeline-body {
  display: flex;
  flex: 1;
  //overflow: auto;
  position: relative;
  
}

.property-label {
  padding: 1rem 1.5rem;
  height: calc(v-bind(rowHeight) * 1px);
  display: flex;
  align-items: center;
  position: sticky;
  left: 0;
  z-index: 2;
  background: var(--bg-color);
}

.timeline-grid {
  flex: 1;
  position: relative;
  min-width: 800px; // Ensure minimum width for scrolling
  margin-left: 0;
}

.grid-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(90deg, var(--border-color) 1px, transparent 1px);
  background-size: v-bind(gridSize) 100%;
}

.timeline-row {
  height: calc(v-bind(rowHeight) * 1px);
  position: relative;
}

.event-bar {
  position: absolute;
  top: 8px;
  height: calc(100% - 16px);
  background: var(--primary-color);
  border-radius: 4px;
  cursor: move;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  min-width: 40px;
  position: relative;
  
  .event-bar-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .resize-handle {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 8px;
    cursor: ew-resize;
    
    &.right {
      right: -4px;
    }
    
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
  
  &:hover {
    filter: brightness(1.1);
  }
}

.playhead {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0;
  z-index: 1;
  margin-left: 1px;
  z-index: 99;
  pointer-events: none;

  .playhead-caret {
    position: absolute;
    top: 0;
    left: -5px;
    width: 10px;
    height: 6px;
    background-color: #ff3333;
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
  }

  .playhead-line {
    position: absolute;
    top: 6px;
    bottom: 0;
    left: -1px;
    width: 1px;
    background-color: #ff3333;
  }
}

.item-group-header {
  padding: 0.5rem 1rem;
  background: var(--bg-secondary-color);
  font-weight: bold;
  border-bottom: 1px solid var(--border-color);
}

.timeline-controls {
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.8rem; // Match header height
  min-height: 3.8rem; // Add min-height to prevent collapse
}

.event-bar {
  .event-value {
    position: absolute;
    font-size: 10px;
    white-space: nowrap;
    opacity: 0.7;
    
    &.start {
      left: 1rem;
    }
    
    &.end {
      right: 1rem;
    }
  }
}

.timeline-left-column {
  width: calc(v-bind(labelWidth) * 1px);
  flex-shrink: 0;
  position: sticky;
  left: 0;
  background: var(--bg-color);
  z-index: 999;
  box-shadow: 2px 0 6px var(--artboard-color);
  display: flex;
  flex-direction: column;
}

</style> 