<script>
  export default {
    props: ['modelValue', 'options', 'rolloverPreview', 'fullWidth', 'noClip'],
    data() {
      return {
        open: false,
        localVal: this.modelValue,
        originalVal: this.modelValue,
        itemHeight: 32.8
      }
    },
    created() {
      window.addEventListener('click', this.handleClick);
    },
    beforeUnmount() {
      window.removeEventListener('click', this.handleClick);
    },
    watch: {
      open() {
        if(this.open) {
          let index = Object.keys(this.options).indexOf(this.modelValue);
          this.localVal = this.modelValue;
          this.originalVal = this.modelValue;
          this.$nextTick(() => {
            this.$refs.dropdown_options.scrollTop = index * this.itemHeight;
          })
        }
      }
    },
    methods: {
      handleClick(e) {
        if(!e.target.classList.value.includes('dropdown') && this.open) {
          this.$emit('update:modelValue', this.originalVal);
          this.open = false;
        }
      },
      handlePreviewEnd() {
        if(this.open) {
          this.$emit('update:modelValue', this.originalVal);
        }
      },
      selectOption(val) {
        this.$emit('update:modelValue', val);
        this.$emit('change', val);
        this.open = false;
      },
      preview(val) {
        if(this.rolloverPreview) {
          this.$emit('update:modelValue', val);
          this.$emit('change', val);
        }
      },
    }
  }
</script>

<template>
  <div class="dropdown" :class="fullWidth ? 'dropdown__fullwidth' : ''">
    <div class="dropdown-value" @click="open = true">
      <span :class="{'clip': !noClip}">{{ options[modelValue] || modelValue }}</span>
      <svg class="dropdown-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><polyline points="208 96 128 176 48 96" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></polyline></svg>
    </div>
    <div v-if="open" class="dropdown-options dropdown-options__short" ref="dropdown_options" @mouseleave="handlePreviewEnd">
      <a href="javascript:void(0)"
        v-for="(key, val) in options"
        class="dropdown-option"
        :key="key"
        :class="{'dropdown-option__active': localVal === val}"
        @click="selectOption(val);"
        @mouseenter="preview(val)"
      >{{ key }}</a>
    </div>
  </div>
</template>

<style lang="scss">

  .dropdown {
    position: relative;
    display: flex;
    justify-content: space-between;
    max-height: 20rem;
    max-width: var(--param-width);
    width: 100%;
    user-select: none;
    text-transform: capitalize;

    &.dropdown__fullwidth {
      max-width: 25rem;
    }

    &.size-dropdown {
      width: auto;
      max-width: none;

      .dropdown-value {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        padding: 0.6rem 1rem;
        color: inherit;
        font-weight: 600;
        text-decoration: none;

        &:hover {
          background-color: var(--accent-color);
        }

        & > * {
          pointer-events: none;
        }
      }
    }

    svg {
      color: var(--font-secondary-color);
    }

    &.dropdown__subtle {

      .dropdown-value {
        background-color: transparent;
        width: auto;
      }
      
      &:hover .dropdown-value {
        box-shadow: inset 0 0 0 0.1rem var(--accent-color);
        width: 100%;
      }
    }
  }

  .clip {
    pointer-events: none;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    width: 14rem;
    padding-right: 2rem;
  }

  .dropdown-options {
    display: flex;
    position: absolute;
    min-width: 100%;
    max-width: 15rem;
    top: 0;
    left: 0;
    padding: 0.5rem;
    flex-direction: column;
    background-color: var(--menu-bg-color);
    color: var(--white);
    border-radius: 0.4rem;
    box-shadow: 0 0.5rem 1.5rem rgba(#000, 0.15);
    z-index: 99999;
    overflow: hidden;

    &:empty {
      display: none;
    }

    &.dropdown-options__short {
      overflow-y: auto;
      max-height: 60rem;
    }
  }

  .dropdown-value {
    position: relative;
    display: block;
    padding: 0.7rem 2rem 0.7rem 1rem;
    border-radius: 0.4rem;
    background-color: var(--accent-color);
    width: 100%;
    height: 100%;
    line-height: 1.33333333;
  }

  .dropdown-icon {
    position: absolute;
    right: 0.5rem;
    top: 0.85rem;
    pointer-events: none;
  }

  .dropdown-option {
    display: flex;
    justify-content: space-between;
    color: inherit;
    text-decoration: none;
    padding: 0.8rem 1.2rem;
    border-radius: 0.4rem;
    margin: 0 0.1rem;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.dropdown-option__large {
      padding: 0.8rem 1.2rem;
    }

    &:hover {
      background-color: var(--menu-hover-color);
    }
  }

  .dropdown-option.dropdown-option__active {
    background-color: var(--primary-color);
    color: var(--white);
    margin: 0;

    .gray {
      color: var(--font-color);;
    }
  }

</style>