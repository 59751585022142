import { 
  computeFragColor, 
  vertexShader, 
  interactiveProperties, 
  mixRadiusProperties, 
  SIMPLEX_2S_NOISE, 
  BLEND, 
  FLOATING_POINT, 
  EASE,
  UNIVERSAL_UNIFORMS, 
  universalUniformParams 
} from '../ShaderHelpers.js';
import { BLEND_MODES } from '../../scripts/Constants.js';
import { Vec2, Vec3 } from 'curtainsjs';

const fragmentShader = `#version 300 es
precision highp float;
in vec3 vVertexPosition;
in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform float uRefraction;
uniform vec2 uPos;
uniform float uTime;
uniform float uScale;
uniform float uSkew;
uniform float uAmbiance;
uniform float uMixRadius;
uniform float uDistortion;
uniform float uAngle;
uniform vec3 uColor;
uniform int uPass;
uniform int uEasing;
uniform int uMixRadiusInvert;
uniform int uBlendMode;
uniform float uMix;
${UNIVERSAL_UNIFORMS}
${SIMPLEX_2S_NOISE}
${EASE}
${BLEND}

const float PI = 3.14159265359;

vec4 normalizeNoise(vec4 noise, float amount) {
  return mix(noise, (noise + 0.5) * 0.5, amount);
}

mat2 rotate2d(float angle) {
  return mat2(cos(angle), -sin(angle), sin(angle), cos(angle));
}

vec4 getNoise(vec3 p) {
  vec4 noise = bccNoiseDerivatives_XYBeforeZ(p);
  return normalizeNoise(noise, uAmbiance);
}

void getCaustics(vec2 uv, out vec4 outNoise, out vec3 outColor) {
  vec2 aspect = vec2(uResolution.x/uResolution.y, 1);

  vec2 mPos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
  float mDist = ease(uEasing, max(0.,1. - distance(uv * aspect, mPos * aspect) * 4. * (1. - uMixRadius)));

  if(uMixRadiusInvert == 1) {
    mDist = max(0., (0.5 - mDist));
  }

  uv -= uPos;
  uv = uv * aspect * rotate2d(uAngle * 2. * PI) * vec2(1.-uSkew, 1.) * 16.0 * uScale;

  float refraction = mix(0.25, 1.3, uRefraction);

  vec3 p = vec3(uv, uTime * 0.05);
  vec4 noise = getNoise(p);
  vec4 baseNoise = noise;
  vec4 balanceNoise = getNoise(p - vec3(baseNoise.xyz / 32.0) * refraction);
  noise = getNoise(p - vec3(balanceNoise.xyz / 16.0) * refraction);

  float balancer = (0.5 + 0.5 * balanceNoise.w);
  float normalized = pow(0.5 + 0.5 * noise.w, 2.);
  float value = mix(0., normalized + 0.2 * (1.0 - normalized), balancer * mDist);
  
  outNoise = baseNoise * mDist;
  outColor = uColor * value;
}

out vec4 fragColor;

void main() {
  vec2 uv = vTextureCoord;
  vec4 causticNoise;
  vec3 causticColor;
  getCaustics(uv, causticNoise, causticColor);
  
  vec4 color = texture(uTexture, uv + causticNoise.xy * 0.01 * uDistortion);
  
  if(uBlendMode > 0) {
    vec3 blended = blend(uBlendMode, color.rgb, causticColor);
    color.rgb = mix(color.rgb, blended, uMix);
  } else {
    color.rgb = causticColor * uMix;
  }

  ${computeFragColor('color')}
}
`;

export const params = {
  fragmentShader,
  vertexShader,
  crossorigin: 'Anonymous',
  texturesOptions: {
    floatingPoint: FLOATING_POINT
  },
  uniforms: {
    refraction: {
      name: "uRefraction",
      type: "1f",
      value: 0.5,
    },
    scale: {
      name: "uScale",
      type: "1f",
      value: 0.5,
    },
    pos: {
      name: "uPos",
      type: "2f",
      value: new Vec2(0.5),
    },
    mix: {
      name: "uMix",
      type: "1f",
      value: 1,
    },
    angle: {
      name: "uAngle",
      type: "1f",
      value: 0,
    },
    skew: {
      name: "uSkew",
      type: "1f",
      value: 0,
    },
    distortion: {
      name: "uDistortion",
      type: "1f",
      value: 0.25,
    },
    ambiance: {
      name: "uAmbiance",
      type: "1f",
      value: 0.25,
    },
    time: {
      name: "uTime",
      type: "1f",
      value: 0,
    },
    color: {
      name: "uColor",
      type: "3f",
      value: new Vec3(0.6,0.7,0.9),
    },
    blendMode: {
      name: 'uBlendMode',
      type: '1i',
      value: 1,
    },
    easing: {
      name: 'uEasing',
      type: '1i',
      value: 0,
    },
    mixRadius: {
      name: 'uMixRadius',
      type: '1f',
      value: 1,
    },
    mixRadiusInvert: {
      name: 'uMixRadiusInvert',
      type: '1i',
      value: 0,
    },
    ...universalUniformParams
  }
}

export const CAUSTICS = {
  id: 'caustics',
  label: 'Caustics',
  params,
  aspectRatio: 1,
  animation: {
    active: false,
    speed: 1,
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    scale: {
      label: 'Scale',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    refraction: {
      label: 'Refraction',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    ambiance: {
      label: 'Ambiance',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    skew: {
      label: 'Skew',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees'
    },
    distortion: {
      label: 'Distortion',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    mix: {
      label: 'Mix',
      value: 1,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    color: {
      label: 'Color',
      value: new Vec3(0.6,0.7,0.9),
      output: 'color',
      responsiveDisabled: true,
    },
    blendMode: {
      label: 'Blend mode',
      value: 'ADD',
      options: BLEND_MODES,
      responsiveDisabled: true,
    },
    ...mixRadiusProperties,
    speed: {
      header: 'Animation',
      label: 'Speed',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    ...interactiveProperties,
  }
}
