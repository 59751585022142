import { Element, getAnchorOffsets } from './Element.js';
import { SCREEN_SIZES } from '../Constants.js';
import { StudioStore } from '../../stores/StudioStore.js';
import { watch } from 'vue';

export class Img extends Element {
  layerType = 'image';
  imageLoaded = false;

  constructor(args, id, dontInit) {
    super(args, id);

    this.local.args = args;
    let props = this.getParams(args || {}).properties;

    for (let prop in props) {
      this[prop] = props[prop].value;
    }

    this.src = args.src || '';
    this.thumb = args.thumb || '';
    this.naturalWidth = args.naturalWidth || 512;
    this.naturalHeight = args.naturalHeight || 512;

    if (Object.keys(args).length && !dontInit) {
      this.createLocalCanvas();
      this.loadImage(args);
    }

    this.local.propertiesToWatch = [
      'anchorPoint',
      'left',
      'leftMode',
      'top',
      'topMode',
      'width',
      'widthMode',
      'height',
      'heightMode',
      'fitToCanvas',
      'rotation',
      'opacity',
      'trackMouse',
      'mouseMomentum',
      'axisTilt',
    ];
  }

  default(args) {
    return {
      fitToCanvas: {
        label: 'Fit to artboard',
        value: args.fitToCanvas || 0,
        classic: true,
        options: {
          1: 'On',
          0: 'Off',
        },
        tooltip: 'Include or hide the underlying layers',
        eventDisabled: true,
      },
    };
  }

  handleBackCompat(args) {
    if(!args) return;
    const relativeScale = Math.min(1080 / this.naturalWidth, 1080 / this.naturalHeight);
    let { bcWidth, bcHeight, ratio } = this.getBackCompatData();

    let sizeX = args.size?._x ?? args.size ?? 0.25;
    let sizeY = args.size?._y ?? args.size ?? 0.25;

    if(args.size?._x) {
      sizeX = args.size._x * relativeScale * ratio;
    } else {
      sizeX = args.size * this.naturalWidth * relativeScale * ratio;
    }
    if(args.size?._y) {
      sizeY = args.size._y * relativeScale * ratio;
    } else {
      sizeY = args.size * this.naturalHeight * relativeScale * ratio;
    }

    this.width = sizeX;
    this.widthMode = 'fixed';
    this.height = sizeY;
    this.heightMode = 'fixed';

    this.translateX = args.translateX;
    this.translateY = args.translateY;
    const offsetPosition = this.getPositionOffset();

    this.left = offsetPosition.x / bcWidth;
    this.top = offsetPosition.y / bcHeight;

    this.breakpoints.forEach(breakpoint => {
      let size = SCREEN_SIZES.find(n => n.name === breakpoint.name);
      let { bcWidth, bcHeight, ratio } = this.getBackCompatData(size.dimensions[0], size.dimensions[1]);
      if ('size' in breakpoint.props) {
        if(breakpoint.props.size._x) {
          breakpoint.props.width = breakpoint.props.size._x * relativeScale * ratio;
        } else {
          breakpoint.props.width = (this.naturalWidth * breakpoint.props.size * relativeScale)/bcWidth;
        }
        breakpoint.props.widthMode = 'relative';
        if(breakpoint.props.size._y) {
          breakpoint.props.height = breakpoint.props.size._y * relativeScale * ratio;
        } else {
          breakpoint.props.height = (this.naturalHeight * breakpoint.props.size * relativeScale)/bcHeight;
        }
        breakpoint.props.heightMode = 'auto';
        delete breakpoint.props.size;
      }
      if('translateX' in breakpoint.props) {
        const bpOffsetPosition = this.getPositionOffset(breakpoint.props.translateX, breakpoint.props.translateY, size.dimensions[0], size.dimensions[1]);
        breakpoint.props.left = bpOffsetPosition.x / bcWidth;
        delete breakpoint.props.translateX;
      }
      if('translateY' in breakpoint.props) {
        const bpOffsetPosition = this.getPositionOffset(breakpoint.props.translateX, breakpoint.props.translateY, size.dimensions[0], size.dimensions[1]);
        breakpoint.props.top = bpOffsetPosition.y / bcHeight;
        delete breakpoint.props.translateY;
      }
    });

    this.setDimensionMode('width', 'relative');
    this.setDimensionMode('height', 'auto');
  }

  packageType() {
    return {
      layerType: 'image',
      fitToCanvas: this.fitToCanvas,
      src: this.src,
      thumb: this.thumb,
      naturalHeight: this.naturalHeight,
      naturalWidth: this.naturalWidth,
    };
  }

  unpackageType() {
    return this;
  }

  loadImage(args) {
    const img = new Image();
    const thumb = new Image();
    let loaded = false;

    img.crossOrigin = 'Anonymous';
    thumb.crossOrigin = 'Anonymous';

    img.addEventListener(
      'load',
      () => {
        loaded = true;
        this.local.img = img;
        this.naturalWidth = img.width;
        this.naturalHeight = img.height;

        if (args?.width !== undefined && !args?.hasOwnProperty('left') && !args?.hasOwnProperty('top')) {
          this.handleBackCompat(args || this.local.args);
          delete this.local.args;
        }

        if(args?.new) {
          let multiplier = Math.min(StudioStore.state.canvasWidth * 0.5, StudioStore.state.canvasHeight * 0.5);
          this.width = multiplier;
          this.height = multiplier * (this.naturalHeight/this.naturalWidth);
        }

        if (this.render) {
          this.render = this.renderImage;
          this.render();
          StudioStore.renderNFrames(2);
        }
      },
      false
    );

    thumb.addEventListener(
      'load',
      () => {
        if (!loaded && this.render) {
          this.local.img = thumb;
          this.naturalWidth = thumb.width;
          this.naturalHeight = thumb.height;

          this.render = this.renderImage;
          this.render();
          StudioStore.renderNFrames(1);
        }
      },
      false
    );

    thumb.src = this.thumb;
    img.src = this.src;
  }

  getImageCoords() {
    if (this.fitToCanvas) {
      return [
        [0, 0],
        [StudioStore.state.canvasWidth, 0],
        [StudioStore.state.canvasWidth, StudioStore.state.canvasHeight],
        [0, StudioStore.state.canvasHeight],
      ];
    }

    const { width, height } = this.getAbsoluteDimensions();

    this.coords = [
      [0, 0],
      [0 + width, 0],
      [0 + width, 0 + height],
      [0, 0 + height],
    ];
    return this.coords;
  }

  renderImage() {
    const rotationAngle = this.rotation * 2 * Math.PI;
    this.coords = this.getImageCoords();

    this.local.ctx.clearRect(0, 0, StudioStore.state.canvasWidth, StudioStore.state.canvasHeight);

    // Get absolute dimensions
    let { width, height } = this.getAbsoluteDimensions();
    if (this.fitToCanvas) {
      // Fit the image to the canvas dimensions while maintaining aspect ratio
      let aspectRatio = this.local.img.width / this.local.img.height;
      let canvasWidth = StudioStore.state.canvasWidth;
      let canvasHeight = StudioStore.state.canvasHeight;
      let canvasAspectRatio = canvasWidth / canvasHeight;

      if (canvasAspectRatio < aspectRatio) {
        height = canvasHeight;
        width = canvasHeight * aspectRatio;
      } else {
        width = canvasWidth;
        height = canvasWidth / aspectRatio;
      }

      const x = (canvasWidth - width) / 2;
      const y = (canvasHeight - height) / 2;

      this.local.ctx.drawImage(this.local.img, x, y, width, height);
    } else {
      let { width, height } = this.getAbsoluteDimensions();
      const renderLeft = this.local?.stateEffectProps?.left ?? this.left ?? 0;
      const renderTop = this.local?.stateEffectProps?.top ?? this.top ?? 0;
      const renderWidth = this.local?.stateEffectProps?.width ?? width ?? 0;
      const renderHeight = this.local?.stateEffectProps?.height ?? height ?? 0;

      // Get anchor offsets
      const anchorOffsets = getAnchorOffsets(this.anchorPoint);

      // Calculate position in pixels, including anchor offset
      let anchorOffsetX = anchorOffsets.x * renderWidth;
      let anchorOffsetY = anchorOffsets.y * renderHeight;

      // Adjusted position using anchor offsets
      let left = renderLeft * StudioStore.state.canvasWidth;
      let top = renderTop * StudioStore.state.canvasHeight;

      // Save canvas state for transformations
      this.local.ctx.save();

      let centerHalfWidth = renderWidth / 2;
      let centerHalfHeight = renderHeight / 2;

      // Calculate the center of rotation
      let centerX = left - anchorOffsetX + centerHalfWidth;
      let centerY = top - anchorOffsetY + centerHalfHeight;

      // Translate to the center of rotation
      this.local.ctx.translate(centerX, centerY);

      // Rotate the context
      this.local.ctx.rotate(rotationAngle);

      // Draw image at the origin adjusted for rotation
      this.local.ctx.drawImage(this.local.img, -centerHalfWidth, -centerHalfHeight, renderWidth, renderHeight);

      // Restore the canvas state
      this.local.ctx.restore();
    }
  }

  render() {
    // loading...
    this.coords = this.getImageCoords();
  }
}
