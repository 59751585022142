import { ADJUST } from './shaders/adjust.js';
import { ASCII } from './shaders/ascii.js';
import { BOKEH } from './shaders/bokeh.js';
import { BLINDS } from './shaders/blinds.js';
import { BEAM } from './shaders/beam.js';
import { BLOOM } from './shaders/bloom.js';
import { BULGE } from './shaders/bulge.js';
import { CUSTOM } from './shaders/custom.js';
import { BLUR } from './shaders/blur.js';
import { CHROMATIC_ABBERATION } from './shaders/chromatic_abberation.js';
import { DIFFUSE } from './shaders/diffuse.js';
import { DITHER } from './shaders/dither.js';
import { EXTEND } from './shaders/extend.js';
import { NEON } from './shaders/neon.js';
import { FBM } from './shaders/fbm.js';
import { FLOW } from './shaders/flow.js';
import { FREE_LOGO } from './shaders/free_logo.js';
import { GOD_RAYS } from './shaders/godrays.js';
import { GLITCH } from './shaders/glitch.js';
import { GRAIN } from './shaders/grain.js';
import { GRADIENT } from './shaders/gradient.js';
import { GRADIENT_MAP } from './shaders/gradientMap.js';
import { GRADIENT_FILL } from './shaders/gradientFill.js';
import { GUILLOCHE } from './shaders/guilloche.js';
import { HOLOGRAM } from './shaders/hologram.js';
import { MARQUEE } from './shaders/marquee.js';
import { MOUSE } from './shaders/mouse.js';
import { mouseTrailParams } from './shaders/mouseTrail.js';
import { LIQUIFY } from './shaders/liquify.js';
import { NOISE_BLUR } from './shaders/noiseBlur.js';
import { WATER_RIPPLE_PINGPONG } from './shaders/waterRipplePingPong.js';
import { WATER_RIPPLE } from './shaders/waterRipple.js';
import { PARTICLES } from './shaders/particles.js';
import { MIRROR } from './shaders/mirror.js';
import { RIPPLE } from './shaders/ripple.js';
import { SHATTER } from './shaders/shatter.js';
import { SINE } from './shaders/sine.js';
import { SPHERE } from './shaders/sphere.js';
import { STRETCH } from './shaders/stretch.js';
import { DUOTONE } from './shaders/duotone.js';
import { SWIRL } from './shaders/swirl.js';
import { STARS } from './shaders/stars.js';
import { POLAR } from './shaders/polar.js';
import { POINTLIGHT } from './shaders/pointlight.js';
import { PROJECTION } from './shaders/projection.js';
import { DOUBLEPOINTLIGHT } from './shaders/doublepointlight.js';
import { NOISE } from './shaders/ungulate.js';
import { SDF_SHAPE } from './shaders/sdf_shape.js';
import { WAVES } from './shaders/waves.js';
import { WISPS } from './shaders/wisps.js';
import { PIXELATE } from './shaders/pixelate.js';
import { PROGRESSIVE_BLUR } from './shaders/progressiveBlur.js';
import { REPLICATE } from './shaders/replicate.js';
import { RETRO_SCREEN } from './shaders/retro_screen';
import { REFLECTIVE_SURFACE } from './shaders/reflectiveSurface.js';
import { HALFTONE } from './shaders/halftone.js';
import { VIGNETTE } from './shaders/vignette.js';
import { FXAA } from './shaders/fxaa.js';
import { TWOD_LIGHT } from './shaders/2dlight.js';
import { PATTERN } from './shaders/pattern.js';
import { LANDSCAPE } from './shaders/landscape.js';
import { ZOOM_BLUR } from './shaders/zoomBlur.js';
import { CAUSTICS } from './shaders/caustics.js';
export const EFFECTS = {
  ascii: ASCII,
  custom: CUSTOM,
  coloration: ADJUST,
  bokeh: BOKEH,
  beam: BEAM,
  bulge: BULGE,
  blinds: BLINDS,
  bloom: BLOOM,
  blur: BLUR,
  chromab: CHROMATIC_ABBERATION,
  caustics: CAUSTICS,
  diffuse: DIFFUSE,
  dither: DITHER,
  duotone: DUOTONE,
  extend: EXTEND,
  neon: NEON,
  fbm: FBM,
  fxaa: FXAA,
  flowField: FLOW,
  freeLogo: FREE_LOGO,
  godrays: GOD_RAYS,
  texturize: GLITCH,
  grain: GRAIN,
  gradient: GRADIENT,
  gradientFill: GRADIENT_FILL,
  gradientMap: GRADIENT_MAP,
  guilloche: GUILLOCHE,
  hologram: HOLOGRAM,
  liquify: LIQUIFY,
  landscape: LANDSCAPE,
  mirror: MIRROR,
  marquee: MARQUEE,
  mouse: MOUSE,
  mouseTrail: mouseTrailParams,
  waterRipple: WATER_RIPPLE,
  waterRipplePingPong: WATER_RIPPLE_PINGPONG,
  particles: PARTICLES,
  noiseBlur: NOISE_BLUR,
  ripple: RIPPLE,
  retro_screen: RETRO_SCREEN,
  voronoi: SHATTER,
  sine: SINE,
  sdf_shape: SDF_SHAPE,
  sphere: SPHERE,
  scan: STRETCH,
  stars: STARS,
  swirl: SWIRL,
  spotlight: POINTLIGHT,
  doublepointlight: DOUBLEPOINTLIGHT,
  halftone: HALFTONE,
  noise: NOISE,
  noiseField: WAVES,
  pixelate: PIXELATE,
  polar: POLAR,
  projection: PROJECTION,
  pattern: PATTERN,
  progressiveBlur: PROGRESSIVE_BLUR,
  replicate: REPLICATE,
  reflectiveSurface: REFLECTIVE_SURFACE,
  twodlight: TWOD_LIGHT,
  wisps: WISPS,
  vignette: VIGNETTE,
  zoomBlur: ZOOM_BLUR,
};
