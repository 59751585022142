<script>
  import { StudioStore } from "../stores/StudioStore";
  import Icon from "./Icon.vue";
  import DropdownMenu from "./DropdownMenu.vue";
  import SliderParam from "./SliderParam.vue";
  import ClassicToggle from "./ClassicToggle.vue";
  import ParamLabel from "./ParamLabel.vue";
  import InputField from "./InputField.vue";
  import ColorInput from "./ColorInput.vue";
  import RadioToggle from "./RadioToggle.vue";
  import Button from "./Button.vue";
  import { Vec2, Vec3 } from 'curtainsjs';

  export default {
    components: {
      Icon,
      DropdownMenu,
      SliderParam,
      ClassicToggle,
      InputField,
      ParamLabel,
      ColorInput,
      Button,
      RadioToggle,
    },
    data() {
      return {
        duration: 250,
        momentum: 0,
        type: 'scroll',
        properties: {},
        modeOptions: {
          'scrollIntoView': 'Scroll in view',
          'whileScrolling': 'While scrolling',
        }
      }
    },
    emits: ['update'],
    watch: {
      "stateEffect.prop"() {
        if(this.stateEffect && this.item) {
          const value = this.item[this.stateEffect.prop];
          if (typeof value === 'object') {
            if (value.type === 'Vec2') {
              this.stateEffect.value = new Vec2(value._x, value._y);
            } else if (value.type === 'Vec3') {
              this.stateEffect.value = new Vec3(value._x, value._y, value._z);
            }
          } else {
            this.stateEffect.value = value;
          }
        }
      },
      item() {
        if(!this.item) {
          StudioStore.state.openStateEffect = null;
        }
      },
    },
    mounted() {
      document.addEventListener("mousedown", this.handleClickOutside);
    },
    beforeUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
    },
    computed: {
      item() {return this.getStateEffectItem()},
      stateEffect() { return this.item?.states?.scroll.find(n => n.id === StudioStore.state.openStateEffect?.id)},
      props() {return this.getValidProps()},
      params() {return this.item.getParams()},
      propParams() { return this.params.properties[this.stateEffect.prop]},
      offsetTop() { return `${this.stateEffect.offsetY - 15}px`},
      progressStyle() {
        return {  
          width: (this.stateEffect.progress * 100) + '%'
        }
      },
      scaleValue() {
        return 90;
      },
      sceneTop() {
        return (StudioStore.state.panTop - window.innerHeight)/window.innerHeight * this.scaleValue + this.stateEffect.sceneHeight;
      },
      sceneHeight() {
        return this.stateEffect.sceneHeight * this.scaleValue * StudioStore.state.userZoom;
      },
      sceneWidth() {
        return this.stateEffect.sceneWidth * this.scaleValue * StudioStore.state.userZoom;
      },
      scrollHeight() {
        return this.stateEffect.endScroll * (this.scaleValue + this.sceneHeight) - this.stateEffect.startScroll * (this.scaleValue + this.sceneHeight);
      },
      thumbnail() {
        return StudioStore.state.design.thumbnail
      },
      sceneFillHeight() {
        return (this.stateEffect.progress * this.scrollHeight/2)
      },
      sceneStyles() {
        return {
          top: Math.round(this.sceneTop) + 'px',
          width: Math.round(this.sceneWidth) + 'px',
          height: Math.round(this.sceneHeight) + 'px',
        }
      },
      windowStyles() {
        return {
          height: Math.round(this.scrollHeight/2) + 'px', 
          top: Math.round(-this.stateEffect.startScroll * this.scaleValue - this.scrollHeight/2) + 'px'
        }
      }
    },
    methods: {
      getValidProps() {
        let uniforms = this.params?.params.uniforms;
        for(let prop in uniforms) {
          if(this.params.properties[prop]) {
            if(uniforms[prop].type !== '1i') {
              this.properties[prop] = this.params.properties[prop].specificLabel || this.params.properties[prop].label
            } 
          }
        }
        return this.properties;
      },
      getStateEffectItem() {
        const scrollItems = StudioStore.state.history.filter(n => n.states?.scroll);
        let mapped = scrollItems.map(n => {
          return {
            id: n.local.id,
            events: n.states.scroll
          }
        });
        return StudioStore.state.history.find(n => n.local.id === mapped.find(n => n.events.find(n => n.id === StudioStore.state.openStateEffect?.id))?.id);
      },
      handleClickOutside(event) {
        if (!this.$el.contains(event.target) && !event.target.classList.contains('brush')) {
          setTimeout(() => {
            StudioStore.state.openStateEffect = null;
          }, 1);
        }
      },
      handleChange(value, axis) {
        if(this.stateEffect) {
          if(value !== undefined && !(value instanceof Event) && axis && this.stateEffect.value[axis]) {
            this.stateEffect.value[axis] = value;
            this.$emit('update');
          }
        }
      },
      handleSwatchClick() {
        StudioStore.state.colorPicker = { item: this.stateEffect, prop: 'value', right: '63.5rem' };
      },
    }
  };
</script>

<template>
  
  <div v-if="item && stateEffect" class="modal modal__pop-in modal__state-effect" :style="{top: offsetTop}">
    <div class="parameter parameter__block mb-3">
      <label class="parameter-label flex align-center">Scroll</label>
    </div>

    <div class="parameter parameter__block">
      <ParamLabel
        label="Mode"
        tooltip="The type of scroll interaction"
      >Mode</ParamLabel>
      <DropdownMenu
        v-model="stateEffect.mode"
        @change="handleChange"
        :options="modeOptions"
      />
    </div>

    <div class="scroll-diagram mt-3"
      v-if="stateEffect.mode === 'scrollIntoView'"
    >
      <div class="browser-window">
        <div class="scroll-window" :style="windowStyles">
          <div class="scene-fill" :style="{height: sceneFillHeight + 'px'}"></div>
          <div class="scroll-label scroll-label__start">Start</div>
          <div class="scroll-label scroll-label__end">End</div>
          <div class="scroll-label scroll-label__progress" :style="{bottom: (sceneFillHeight) + 'px'}">{{(stateEffect.progress * 100).toFixed(0) + '%'}}</div>
        </div>
      </div>
    </div>

    <div class="parameter parameter__block">
      <ParamLabel
        label="Property"
        tooltip="The property which the scroll event will affect. Only one event of each type (Scroll/Appear) is allowed per property."
      >Property</ParamLabel>
      <DropdownMenu
        v-model="stateEffect.prop"
        @change="handleChange"
        :options="props"
      ></DropdownMenu>
    </div>

    <div class="parameter parameter__block" v-if="(propParams.control !== 'rotation' && propParams.value.type === 'Vec3' || propParams.value.length) && stateEffect.prop !== 'blendMode'">
      <span class="icon-label slider-label">{{stateEffect.mode === 'scrollIntoView' ? 'End value' : 'Scrolling value'}}</span>
      <ColorInput
        :fill="stateEffect.value"
        :vec3="stateEffect.value.type"
        @change="handleColorChange(stateEffect.prop)"
        @click-swatch="handleSwatchClick"
      ></ColorInput>
    </div>
    <div v-else-if="stateEffect.value.type === 'Vec2'"  class="parameter parameter__block">
      <span class="icon-label slider-label">{{stateEffect.mode === 'scrollIntoView' ? 'End value' : 'Scrolling value'}}</span>
      <div class="coords-input-group">
        <InputField
          :label="'X'"
          :value="stateEffect.value.x"
          output="percent"
          :slider="true"
          @change="handleChange($event, 'x')"
        />
        <InputField
          :label="'Y'"
          :value="stateEffect.value.y"
          output="percent"
          :slider="true"
          @change="handleChange($event, 'y')"
        />
      </div>
    </div>
    <div v-else-if="stateEffect.value.type === 'Vec3' && propParams.control === 'rotation'"  class="parameter parameter__block">
      <span class="icon-label slider-label">{{stateEffect.mode === 'scrollIntoView' ? 'End value' : 'Scrolling value'}}</span>
      <div class="coords-input-group">
        <InputField
          :label="'X'"
          :value="stateEffect.value.x"
          :output="propParams.output"
          :slider="true"
          @change="handleChange($event, 'x')"
        />
        <InputField
          :label="'Y'"
          :value="stateEffect.value.y"
          :output="propParams.output"
          :slider="true"
          @change="handleChange($event, 'y')"
        />
        <InputField
          :label="'Z'"
          :value="stateEffect.value.z"
          :output="propParams.output"
          :slider="true"
          @change="handleChange($event, 'z')"
        />
      </div>
    </div>
    <SliderParam
      v-else
      v-model="stateEffect.value"
      :label="stateEffect.mode === 'scrollIntoView' ? 'End value' : 'Scrolling value'"
      @update="handleChange"
      :min="propParams.min"
      :max="propParams.max"
      :step="propParams.step"
      :output="propParams.output"
      tooltip="Scrolling will animate to this value from the intial value set in the layer panel"
    ></SliderParam>
    <ClassicToggle
      v-if="stateEffect.mode === 'whileScrolling'"
      label="Ignore direction"
      tooltip="Value will be positive when scrolling up or down. Disable to allow negative values when scrolling up."
      v-model="stateEffect.absScrollValue"
      @change="handleChange"
    ></ClassicToggle>
    <SliderParam
      v-if="stateEffect.mode === 'scrollIntoView'"
      label="Start"
      v-model="stateEffect.offset"
      min="0"
      max="1"
      step="0.01"
      output="percent"
      @update="handleChange"
      tooltip="The start position of the scroll animation, relative to the top of the viewport"
    ></SliderParam>
    <SliderParam
      v-if="stateEffect.mode === 'scrollIntoView'"
      v-model="stateEffect.range"
      label="Duration"
      min="0"
      max="1"
      step="0.01"
      output="percent"
      @update="handleChange"
      tooltip="How long the scroll animation lasts, relative to the viewport height"
    ></SliderParam>
    <SliderParam
      v-model="stateEffect.momentum"
      label="Momentum"
      min="0"
      max="1"
      step="0.01"
      output="percent"
      @update="handleChange"
      tooltip="Adds momentum / delay to the effect change"
    ></SliderParam>
  </div>

</template>


<style scoped lang="scss">

.scroll-diagram {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20rem;
  width: 100%;
  background: var(--artboard-color);
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.browser-window {
  position: relative;
  width: 16rem;
  height: 9rem;
  margin: 0 auto;
  border-radius: 0.4rem;
  border: 1px solid var(--accent-color);

  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 0.5em;
    background-color: var(--accent-color);
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
  }
}

.scene {
  position: absolute;
  left: 0;
  right: 0;
  background-color: var(--accent-color);
  width: 9rem;
  height: 5rem;
  border-radius: 0.2rem;
  margin-top: 7rem;
  margin-left: auto;
  margin-right: auto;
}

.scene-fill {
  margin-top: auto;
  width: 100%;
  z-index: 0;
  background-color: var(--primary-color-50a);
}

.scroll-label {
  position: absolute;
  padding: 0.1rem 0.2rem;
  font-size: 0.8rem;
  right: 0;
  margin-right: calc(100% + 0.5rem);
  border: 1px solid var(--primary-color-50a);
  border-radius: 0.2rem;
  color: var(--primary-color);
  text-transform: uppercase;
  display: inline-block;
  margin-top: -0.7rem;
  z-index: 1;

  &.scroll-label__start {
    bottom: 0;
    margin-bottom: -0.7rem;
  }

  &.scroll-label__progress {
    margin-right: 0;
    right: unset;
    left: 100%;
    margin-left: 0.5rem;
    margin-bottom: -0.7rem;
  }
}

.scene-image {
  width: 100%;
  height: 100%;
}

.scroll-window {
  position: absolute;
  display: flex;
  margin: 0 auto;
  left: 0;
  right: 0;
  //background-color: var(--primary-color-50a);
  border-top: 1px solid var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  z-index: 1;
}

.modal__state-effect {
  position: absolute;
  right: 29rem;
  top: 6rem;
  left: unset;
}

.button-preview-progress {
  position: absolute;
  height: 100%;
  left: 0;
  background-color: var(--accent-color);
  z-index: 0;
}

</style>