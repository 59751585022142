import { StudioStore } from '../stores/StudioStore';
import { Vec2, Vec3 } from 'curtainsjs';
import { generateUUID } from '../scripts/Helpers.js';
import { StateEffectAppear, StateEffectScroll, StateEffectHover } from '../scripts/StateEffect.js';

function array_move(arr, old_index, new_index) {
  new_index = Math.max(new_index, 0); // Ensure the new index is not negative
  new_index = Math.min(new_index, arr.length - 1); // Ensure the new index does not exceed array length - 1

  const item = arr.splice(old_index, 1)[0]; // Remove the item from the old position
  arr.splice(new_index, 0, item); // Insert the item at the new position

  return arr;
}

function deserializeNestedArray(obj) {
  if (!obj) return [];
  if (Array.isArray(obj)) {
    return obj;
  }
  if (obj && typeof obj === 'string') {
    obj = JSON.parse(obj);
  }
  return Object.values(obj);
}

function unpackBreakpoints(bps) {
  bps.forEach(breakpoint => {
    for (let prop in breakpoint.props) {
      if (breakpoint.props[prop]) {
        if (breakpoint.props[prop]?.type === 'Vec2') {
          breakpoint.props[prop] = new Vec2(breakpoint.props[prop]._x, breakpoint.props[prop]._y);
        } else if (breakpoint.props[prop]?.type === 'Vec3') {
          breakpoint.props[prop] = new Vec3(
            breakpoint.props[prop]._x,
            breakpoint.props[prop]._y,
            breakpoint.props[prop]._z
          );
        } else if (typeof breakpoint.props[prop] === 'object') {
          breakpoint.props[prop] = deserializeNestedArray(breakpoint.props[prop]);
        }
      }
    }
  });
  return bps;
}

function refreshPlanes() {
  StudioStore.refreshPlanes(
    () => {
      StudioStore.state.curtain.resize();
      StudioStore.saveDesignState();
    },
    null,
    true
  );
}

function addItem(item, index) {
  const addItem = () => {
    if (index !== undefined) {
      StudioStore.state.history.splice(index, 0, item);
    } else {
      StudioStore.state.history.push(item);
    }
    StudioStore.refreshPlanes(() => {
      if(StudioStore.state.curtain){
        StudioStore.state.curtain.resize();
      }
      StudioStore.saveDesignState();
      StudioStore.setSelectedItem(item);
      StudioStore.state.duplicating = false;
      item.initializeBreakpoints();
    });
  };

  addItem();
}

function deleteItem(item, swap) {
  const parent = item.parentLayer ? item.getParent() : null;
  if (StudioStore.isSelected(item)) {
    StudioStore.setSelectedItem('');
  }

  if (parent && !swap) {
    parent.effects = parent.effects.filter(n => n !== item.parentLayer);
    parent.resize(0.5);
  } else if (item.effects && item.effects.length) {
    item.getChildEffectItems().forEach(child => {
      StudioStore.state.history = StudioStore.state.history.filter(n => n.local.id !== child.local.id);
    });
  }

  if (item.type === 'mouse' && !swap) {
    StudioStore.state.curtain.planes
      .filter(n => n.type === 'PingPongPlane')
      .forEach(plane => {
        plane.remove();
        plane.renderer.removePlane(plane);
        plane = null;
      });
  }

  StudioStore.state.history = StudioStore.state.history.filter(n => n.local.id !== item.local.id);
}

export class CreateCommand {
  constructor(item, index) {
    this.item = item;
    this.index = index;
  }

  execute() {
    addItem(this.item, this.index);
  }

  unexecute() {
    deleteItem(this.item);
    refreshPlanes();
  }
}

export class ReplaceCommand {
  constructor(newItem, oldItem, index) {
    this.newItem = newItem;
    this.oldItem = oldItem;
    this.index = index;
  }

  execute() {
    addItem(this.newItem, this.index);
    deleteItem(this.oldItem, true);
    refreshPlanes();
  }

  unexecute() {
    addItem(this.oldItem, this.index);
    deleteItem(this.newItem, true);
    refreshPlanes();
  }
}

export class UpdateCommand {
  constructor(items) {
    // Expect items to be an array of {state, id}
    this.items = JSON.parse(JSON.stringify(items));
  }

  execute() {
    this.items.forEach(item => {
      const currentItemState = item.state;
      const currentItem = StudioStore.state.history.find(n => n.local.id === item.id);
      if (currentItem) {
        for (let prop in currentItemState) {
          if (prop === 'states') {
            if (currentItemState.states.appear.length) {
              currentItem.states.appear = currentItemState.states.appear.map(n => new StateEffectAppear(n, currentItem.isElement ? currentItem.local.id : null));
            }
            if (currentItemState.states.scroll.length) {
              currentItem.states.scroll = currentItemState.states.scroll.map(n => new StateEffectScroll(n, currentItem.isElement ? currentItem.local.id : null));
            }
            if (currentItemState.states.hover.length) {
              currentItem.states.hover = currentItemState.states.hover.map(n => new StateEffectHover(n, currentItem.isElement ? currentItem.local.id : null));
            }
          } else if (currentItemState[prop] && currentItemState[prop].type) {
            if (currentItemState[prop].type === 'Vec2') {
              currentItem[prop] = new Vec2(currentItemState[prop]._x, currentItemState[prop]._y);
            } else if (currentItemState[prop].type === 'Vec3') {
              currentItem[prop] = new Vec3(
                currentItemState[prop]._x,
                currentItemState[prop]._y,
                currentItemState[prop]._z
              );
            }
          } else if (prop === 'breakpoints') {
            currentItemState[prop] = unpackBreakpoints(currentItemState[prop]);
          } else if (currentItemState[prop]) {
            currentItem[prop] = currentItemState[prop];
          }
        }
        this.finalizeItemUpdate(currentItem);
      }
    });

    this.finalizeUpdate();
  }

  unexecute() {
    // Since there's no changes array provided for unexecute in your original command,
    // this will simply reverse the changes made by execute by setting properties back to their original states.
    this.items.forEach(item => {
      const currentItemState = item.state;
      const currentItem = StudioStore.state.history.find(n => n.local.id === item.id);
      if (currentItem) {
        for (let prop in currentItemState) {
          if (prop === 'states') {
            if (currentItemState.states.appear.length) {
              currentItem.states.appear = currentItemState.states.appear.map(n => new StateEffectAppear(n, item.isElement ? item.local.id : null));
            }
            if (currentItemState.states.scroll.length) {
              currentItem.states.scroll = currentItemState.states.scroll.map(n => new StateEffectScroll(n, item.isElement ? item.local.id : null));
            }
            if (currentItemState.states.hover.length) {
              currentItem.states.hover = currentItemState.states.hover.map(n => new StateEffectHover(n, item.isElement ? item.local.id : null));
            }
          } else if (currentItemState[prop] && currentItemState[prop].type) {
            if (currentItemState[prop].type === 'Vec2') {
              currentItem[prop] = new Vec2(currentItemState[prop]._x, currentItemState[prop]._y);
            } else if (currentItemState[prop].type === 'Vec3') {
              currentItem[prop] = new Vec3(
                currentItemState[prop]._x,
                currentItemState[prop]._y,
                currentItemState[prop]._z
              );
            }
          } else if (currentItemState[prop]) {
            currentItem[prop] = currentItemState[prop];
          }
        }
        this.finalizeItemUpdate(currentItem);
      }
    });

    this.finalizeUpdate();
  }

  finalizeItemUpdate(item) {
    if (item.isElement) {
      item.render();
    }
  }

  finalizeUpdate() {
    StudioStore.renderFrame();
    StudioStore.state.drawUIUpdater++;
  }
}

export class DeleteCommand {
  constructor(items) {
    this.items = StudioStore.state.selectedItems.length > 1 ? StudioStore.state.selectedItems : [items];
    this.itemData = this.items.map(item => ({
      item,
      index: item.getIndex(),
      parent: item.parentLayer ? item.getParent() : null,
      children: item.effects
        ? item.getChildEffectItems().map(n => ({
            item: n,
            index: n.getIndex(),
            effectIndex: item.effects.indexOf(n.parentLayer),
          }))
        : [],
      effectIndex: item.parentLayer ? item.getParent().effects.indexOf(item.parentLayer) : null,
    }));
  }

  execute() {
    this.itemData.forEach(({ item }) => {
      deleteItem(item);
    });
    refreshPlanes();
  }

  unexecute() {
    this.itemData.forEach(({ item, index }) => {
      addItem(item, index);
    });
  }
}

export class ClearCommand {
  constructor(items) {
    this.items = items;
  }

  execute() {
    StudioStore.state.history
      .filter(n => !n.isBackground)
      .forEach((item, index) => {
        deleteItem(item);
      });
    refreshPlanes();
  }

  unexecute() {
    StudioStore.state.history = this.items;
    refreshPlanes();
  }
}

export class MoveCommand {
  constructor(newIndex, oldIndex, bottom, top) {
    this.newIndex = newIndex;
    this.oldIndex = oldIndex;
    this.bottom = bottom;
    this.top = top;
    this.item = StudioStore.state.history[oldIndex];
    this.destination = StudioStore.state.history[newIndex];
    this.parent = this.item && this.item.parentLayer ? this.item.getParent() : null;
  }

  moveItem(newpos, oldpos) {
    let shift = newpos - oldpos;
    if (!shift) {
      return false;
    }
    while (StudioStore.state.history[newpos] && StudioStore.state.history[newpos].parentLayer) {
      newpos += shift;
    }
    if (StudioStore.state.history[newpos] && newpos > 0) {
      StudioStore.state.history = array_move(StudioStore.state.history, oldpos, newpos);
    }
  }

  moveChildEffect(newpos, oldpos) {
    let direction = newpos - oldpos;
    let index = this.parent.effects.indexOf(this.item.parentLayer);
    let dest = index + direction;

    if (this.top && direction < 0) {
      index--;
    }

    if (this.bottom && direction > 0) {
      index++;
    }

    if (index === dest) {
      return false;
    }

    if (index !== -1) {
      // Check if the item is found within the parent's effects
      this.parent.effects = array_move(this.parent.effects, index, dest);
    }
  }

  move(newpos, oldpos) {
    if (!this.destination) return false;
    const itemIsEffect = this.item && this.item.layerType === 'effect';
    const effectFromTopToElement =
      itemIsEffect && !this.item.parentLayer && this.destination.isElement && !this.top && !this.bottom;
    const isChildEffect = itemIsEffect && this.parent;

    if (isChildEffect) {
      const withinSameParent =
        this.destination.parentLayer && this.destination.getParent()?.local.id === this.parent?.local.id;
      const childEffectToParentElement =
        this.destination.local.id === this.parent?.local.id && this.destination.isElement && !this.top && !this.bottom;
      const childEffectToDifferentParentElement =
        this.destination.local.id !== this.parent?.local.id && this.destination.isElement && !this.top && !this.bottom;

      if (childEffectToParentElement) {
        // console.log('child to parent element')
        return false;
      } else if (childEffectToDifferentParentElement) {
        //console.log('move from parent effects to another parent');
        this.parent.effects = this.parent.effects.filter(n => n !== this.item.parentLayer);
        this.destination.effects.push(this.item.parentLayer);
        this.moveItem(newpos, this.item.getIndex());
        refreshPlanes();
        return false;
      }

      if (withinSameParent) {
        // console.log('move within parent effects')
        this.moveChildEffect(this.destination.getChildEffectIndex(), this.item.getChildEffectIndex());
        refreshPlanes();
      } else {
        // Move from parent to top level
        //console.log('remove from child effects to top level')
        this.parent.effects = this.parent.effects.filter(n => n !== this.item.parentLayer);
        this.item.parentLayer = null;
        this.moveItem(newpos, this.item.getIndex());
        refreshPlanes();
      }
    } else {
      if (effectFromTopToElement) {
        //console.log('add from top to parent effects')
        this.item.parentLayer = generateUUID();
        this.destination.effects.push(this.item.parentLayer);
        refreshPlanes();
      } else {
        if (itemIsEffect && this.destination.parentLayer) {
          //console.log('move from top level to child effect sibling');
          //order bug
          this.item.parentLayer = generateUUID();
          this.parent = this.destination.getParent();

          if(this.parent) {
            this.parent.effects.push(this.item.parentLayer);
            this.moveChildEffect(this.destination.getChildEffectIndex(), this.item.getChildEffectIndex());
            refreshPlanes();
          }
        } else {
          //console.log('move within top level')
          this.moveItem(newpos, oldpos);
          refreshPlanes();
        }
      }
    }
  }

  execute() {
    this.move(this.newIndex, this.oldIndex);
  }

  unexecute() {
    this.item = StudioStore.state.history[this.oldIndex];
    this.destination = StudioStore.state.history[this.newIndex];
    this.parent = this.item && this.item.parentLayer ? this.item.getParent() : null;
    this.move(this.oldIndex, this.newIndex);
  }
}

export class MaskCommand {
  constructor(newValue, oldValue, item) {
    this.newValue = newValue;
    this.oldValue = oldValue;
    this.item = item;
  }

  execute() {
    this.item.mask = this.newValue;
    refreshPlanes();
  }

  unexecute() {
    this.item.mask = this.oldValue;
    refreshPlanes();
  }
}

export class VisibilityToggleCommand {
  constructor(item, newVal, oldVal) {
    this.item = item;
    this.newVal = newVal;
    this.oldVal = oldVal;
  }

  handlePlanes() {
    const item = this.item;

    if (!item.isBackground || (item.isBackground && StudioStore.state.history.filter(n => n.visible).length <= 1)) {
      item.getChildEffectItems().forEach(effect => {
        effect.getPlanes().forEach(plane => {
          plane.visible = item.visible;
        });
      });

      item.getPlanes().forEach(plane => {
        plane.visible = item.visible;
      });
    }
  }

  refresh() {
    if (!this.item.visible && StudioStore.isSelected(this.item)) {
      StudioStore.setSelectedItem('');
    }

    refreshPlanes();
  }

  execute() {
    this.item.visible = this.newVal;
    this.handlePlanes();
    refreshPlanes();
  }

  unexecute() {
    this.item.visible = this.oldVal;
    this.handlePlanes();
    refreshPlanes();
  }
}

export class AddStateEffectCommand {
  constructor(effect, newStateEffect) {
    this.effect = effect;
    this.newStateEffect = newStateEffect;
  }

  execute() {
    this.effect.states[this.newStateEffect.type].push(this.newStateEffect);
    StudioStore.save();
  }

  unexecute() {
    this.effect.states[this.newStateEffect.type] = this.effect.states[this.newStateEffect.type].filter(
      effect => effect.id !== this.newStateEffect.id
    );
    StudioStore.save();
  }
}

export class RemoveStateEffectCommand {
  constructor(effect, stateEffectId) {
    this.effect = effect;
    this.stateEffectId = stateEffectId;
    this.stateEffect = [...effect.states.appear, ...effect.states.scroll, ...effect.states.hover].find(
      effect => effect.id === stateEffectId
    );
  }

  execute() {
    this.effect.states[this.stateEffect.type] = this.effect.states[this.stateEffect.type].filter(
      effect => effect.id !== this.stateEffectId
    );
    StudioStore.save();
  }

  unexecute() {
    this.effect.states[this.stateEffect.type].push(this.stateEffect);
    StudioStore.save();
  }
}

export class ResetPropCommand {
  constructor(effect, prop, oldValue, newValue) {
    this.effect = effect;
    this.prop = prop;
    this.oldValue = oldValue;
    this.newValue = newValue;
  }

  execute() {
    this.effect[this.prop] = this.newValue;
    this.effect.updateUniforms();
    StudioStore.save();
  }

  unexecute() {
    this.effect[this.prop] = this.oldValue;
    this.effect.updateUniforms();
    StudioStore.save();
  }
}
