import { Element, getAnchorOffsets } from './Element.js';
import { getShapeFill } from '../../scripts/Draw.js';
import { SCREEN_SIZES } from '../../scripts/Constants.js';
import { deserializeNestedArray, serializeNestedArray } from '../../scripts/Helpers';
import { StudioStore } from '../../stores/StudioStore.js';

export class TextBox extends Element {
  layerType = 'text';
  justCreated = false;
  isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  constructor(args, id) {
    super(args, id);
    let props = this.getParams(args || {}).properties;
    for (let prop in props) {
      this[prop] = props[prop].value;
    }

    this.fill = args.fill || ['#777777'];
    this.letterSpacing = args.letterSpacing || 0;
    this.fontFamily = args.fontFamily || 'Inter';
    this.fontStyle = args.fontStyle || 'regular';
    this.fontWeight = args.fontWeight || '400';
    this.fontSizeMode = args.fontSizeMode || 'fixed';
    this.textAlign = args.textAlign || 'left';
    this.textContent = args.textContent || 'New text';
    this.gradientAngle = args.gradientAngle || 0;
    this.gradientType = args.gradientType || 'linear';
    
    this.fontCSS = args.fontCSS || {
      family: 'Inter',
      src: '"http://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf"',
    };

    if (Object.keys(args).length) {
      this.createLocalCanvas();
      requestAnimationFrame(() => {
        this.render();
      });
    }
    if (!args.hasOwnProperty('left') && !args.hasOwnProperty('top')) {
      this.handleBackCompat(args);
    }

    this.local.propertiesToWatch = [
      'anchorPoint',
      'fontSize',
      'fontSizeMode',
      'lineHeight',
      'letterSpacing',
      'fontFamily',
      'fill',
      'fontStyle',
      'fontWeight',
      'gradientAngle',
      'gradientType',
      'textAlign',
      'textContent',
      'rotation',
      'trackMouse',
      'mouseMomentum',
      'left',
      'leftMode',
      'top',
      'topMode',
      'width',
      'widthMode',
    ];
  }

  default(args) {
    return {
      fontSize: {
        label: 'Font size',
        value: args.fontSize || 24,
        min: 1,
        max: 200,
        step: 1,
        output: 'px',
      },
      lineHeight: {
        label: 'Line height',
        value: args.lineHeight || 25,
        min: 1,
        max: 200,
        step: 1,
        output: 'px',
      },
      letterSpacing: {
        label: 'Letter spacing',
        value: args.letterSpacing || 0,
        min: -20,
        max: 100,
        step: 1,
        output: 'px',
      },
      fontFamily: {
        label: 'Font family',
        value: args.fontFamily || 'Inter',
      },
      fontStyle: {
        label: 'Font style',
        value: args.fontStyle || 'regular',
      },
      fontWeight: {
        label: 'Font weight',
        value: args.fontWeight || '400',
      },
      textAlign: {
        label: 'Text align',
        value: args.textAlign || 'left',
      },
      textContent: {
        label: 'Text content',
        value: args.textContent || 'New text',
      },
      fill: {
        label: 'Fill color',
        value: args.fill || ['#777777'],
      },
      gradientAngle: {
        label: 'Gradient angle',
        value: args.gradientAngle || 0,
        min: 0,
        max: 360,
        step: 1,
      },
      gradientType: {
        label: 'Gradient type',
        value: args.gradientType || 'linear',
      }
    };
  }

  handleBackCompat(args) {
    let { bcWidth, bcHeight, ratio } = this.getBackCompatData();
    this.translateX = args.translateX ?? null;
    this.translateY = args.translateY ?? null;
    const offsetPosition = this.getPositionOffset();
    this.width = (args.width ?? args.size._x) * ratio;
    this.height = (args.height ?? args.size._y) * ratio;
    this.left = args.pos?._x ?? offsetPosition.x / bcWidth;
    this.top = args.pos?._y ?? offsetPosition.y / bcHeight;
    this.fontSize = args.fontSize * ratio;
    this.lineHeight = args.lineHeight * ratio;

    this.left += ((args.width ?? args.size._x) / 2)/bcWidth;
    this.top += ((args.height ?? args.size._y) / 2)/bcHeight;

    this.widthMode = 'fixed';

    this.breakpoints.forEach(breakpoint => {
      let size = SCREEN_SIZES.find(n => n.name === breakpoint.name);
      let { bcWidth, bcHeight, ratio } = this.getBackCompatData(size.dimensions[0], size.dimensions[1]);
      let oldFontSize = this.fontSize;
      if('translateX' in breakpoint.props) {
        const bpOffsetPosition = this.getPositionOffset(breakpoint.props.translateX, breakpoint.props.translateY, size.dimensions[0], size.dimensions[1]);
        breakpoint.props.left = bpOffsetPosition.x / bcWidth;
        delete breakpoint.props.translateX;
      }
      if('translateY' in breakpoint.props) {
        const bpOffsetPosition = this.getPositionOffset(breakpoint.props.translateX, breakpoint.props.translateY, size.dimensions[0], size.dimensions[1]);
        breakpoint.props.top = bpOffsetPosition.y / bcHeight;
        delete breakpoint.props.translateY;
      }
      if('size' in breakpoint.props) {
        breakpoint.props.width = breakpoint.props.size._x * ratio;
        breakpoint.props.height = breakpoint.props.size._y * ratio;
        delete breakpoint.props.size;
      }
      if ('width' in breakpoint.props) {
        
        breakpoint.props.width = breakpoint.props.width/bcWidth;
        
        if(breakpoint.props.left !== undefined) {
          breakpoint.props.left += breakpoint.props.width/2;
        }

        breakpoint.props.widthMode = 'relative';

      } else if(breakpoint.props.left !== undefined) {
        breakpoint.props.left += ((breakpoint.props.width || this.width) / 2)/StudioStore.state.canvasWidth;
      }
      if(breakpoint.props.top !== undefined) {
        breakpoint.props.top += (this.height / 2)/StudioStore.state.canvasHeight;
      }
      if('fontSize' in breakpoint.props) {
        oldFontSize = breakpoint.props.fontSize;
        breakpoint.props.fontSize = breakpoint.props.fontSize/bcWidth;
        breakpoint.props.fontSizeMode = 'relative';
      }
      if('lineHeight' in breakpoint.props) {
        breakpoint.props.lineHeight = (breakpoint.props.lineHeight)/oldFontSize;
        breakpoint.props.fontSizeMode = 'relative';
      }

    });

    this.setDimensionMode('width', 'relative');
    this.setFontMode('relative');
  }

  packageType(skipSerialization) {
    return {
      layerType: 'text',
      fill: serializeNestedArray(this.fill, skipSerialization),
      gradientAngle: this.gradientAngle,
      gradientType: this.gradientType,
      fontFamily: this.fontFamily,
      fontCSS: this.fontCSS,
      fontSize: this.fontSize,
      fontSizeMode: this.fontSizeMode,
      lineHeight: this.lineHeight,
      letterSpacing: this.letterSpacing,
      fontStyle: this.fontStyle,
      fontWeight: this.fontWeight,
      textAlign: this.textAlign,
      textContent: this.textContent
    };
  }

  unpackageType() {
    this.fill = deserializeNestedArray(this.fill);
    return this;
  }

  setFontMode(mode) {
    if (this.fontSizeMode === mode) return;
    
    const oldFontSize = this.fontSize;

    if (mode === 'relative') {
      // Convert fontSize to relative to canvas width
      this.fontSize = this.fontSize / StudioStore.state.canvasWidth;
      // Convert lineHeight and letterSpacing to be relative to fontSize
      this.lineHeight = this.lineHeight / oldFontSize;
      this.letterSpacing = this.letterSpacing / oldFontSize;
    } else {
      // Convert fontSize to fixed pixels
      this.fontSize = this.fontSize * StudioStore.state.canvasWidth;
      
      // Convert lineHeight and letterSpacing to fixed pixels
      this.lineHeight = this.lineHeight * this.fontSize;
      this.letterSpacing = this.letterSpacing * this.fontSize;
    }

    this.fontSizeMode = mode;

    this.render();
  }

  getAbsoluteFontValues() {
    const renderFontSize = this.local?.stateEffectProps?.fontSize || this.fontSize;
    const renderLineHeight = this.local?.stateEffectProps?.lineHeight || this.lineHeight;
    const renderLetterSpacing = this.local?.stateEffectProps?.letterSpacing || this.letterSpacing;

    const absoluteFontSize = this.fontSizeMode === 'fixed' ? 
      renderFontSize : 
      renderFontSize * StudioStore.state.canvasWidth;

    return {
      fontSize: absoluteFontSize,
      lineHeight: this.fontSizeMode === 'fixed' ? 
        renderLineHeight : 
        renderLineHeight * absoluteFontSize,
      letterSpacing: this.fontSizeMode === 'fixed' ? 
        renderLetterSpacing : 
        renderLetterSpacing * absoluteFontSize
    };
  }

  render() {
    if (!this?.local?.ctx) {
      console.warn('Canvas context not available for TextBox render');
      return;
    }
    let { left, top, width } = this.box();
    let height = this.height;
    const anchorOffsets = getAnchorOffsets(this.anchorPoint);
    
    const renderLeft = this.local?.stateEffectProps?.left ?? left;
    const renderTop = this.local?.stateEffectProps?.top ?? top;
    const renderWidth = this.local?.stateEffectProps?.width ?? width;
    const renderHeight = this.local?.stateEffectProps?.height ?? height;
    const renderRotation = this.local?.stateEffectProps?.rotation ?? this.rotation;

    let x = renderLeft * StudioStore.state.canvasWidth - anchorOffsets.x * renderWidth;
    let y = renderTop * StudioStore.state.canvasHeight - anchorOffsets.y * renderHeight;

    let index = 0;
    let { fontSize, lineHeight, letterSpacing } = this.getAbsoluteFontValues();
    let style = this.fontStyle.includes('italic') ? 'italic' : 'normal';
    let weight = '400';
    let family = this.local.tempFontFamily || this.fontFamily;

    this.local.textBoxPos = { x, y };

    this.local.ctx.clearRect(0, 0, StudioStore.state.canvasWidth, StudioStore.state.canvasHeight);
    this.local.ctx.font = `${style} ${weight} ${fontSize}px/${lineHeight}px ${family}, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial`;

    if (!this.isSafari) {
      this.local.ctx.textAlign = this.textAlign;
      this.local.ctx.letterSpacing = letterSpacing + 'px';
    }

    const minWidth = this.local.ctx.measureText('m').width;
    width = Math.max(width, minWidth);

    this.local.ctx.save();
    let centerX = x + width / 2;
    let centerY = y + height / 2;
    this.local.ctx.translate(centerX, centerY);
    this.local.ctx.rotate((renderRotation * 360 * Math.PI) / 180);
    this.local.ctx.translate(-centerX, -centerY);

    if (this.textAlign === 'center') {
      x += width / 2;
    }
    if (this.textAlign === 'right') {
      x += width;
    }

    this.local.ctx.fillStyle = getShapeFill(this.local.ctx, this, this.coords);

    const drawTextWithSpacing = (ctx, text, startX, startY, spacing, align, containerWidth) => {
      let textTotalWidth = text
        .split('')
        .reduce((acc, char, i) => acc + ctx.measureText(char).width + (i < text.length - 1 ? spacing : 0), 0);

      let currentX;
      if (align === 'center') {
        currentX = startX + (containerWidth - textTotalWidth) / 2 - containerWidth / 2;
      } else if (align === 'right') {
        currentX = startX;
      } else {
        currentX = startX;
      }

      if (align === 'right') {
        for (let i = text.length - 1; i >= 0; i--) {
          const char = text[i];
          currentX -= ctx.measureText(char).width;
          ctx.fillText(char, currentX, startY);
          if (i > 0) currentX -= spacing;
        }
      } else {
        for (let i = 0; i < text.length; i++) {
          ctx.fillText(text[i], currentX, startY);
          currentX += ctx.measureText(text[i]).width + spacing;
        }
      }
    };

    const render = (content, index) => {
      let textY = y + lineHeight * index + lineHeight / 2 + fontSize / 3;

      if (this.isSafari) {
        drawTextWithSpacing(this.local.ctx, content, x, textY, letterSpacing, this.textAlign, renderWidth);
      } else {
        this.local.ctx.fillText(content, x, textY);
      }
    };

    const lines = this.textContent ? this.textContent.split('\n') : [''];
    let line_count = lines.length;

    const measureTextWithSpacing = (ctx, text, spacing) => {
      let totalWidth = text.split('').reduce((acc, char, index) => {
        acc += ctx.measureText(char).width;
        if (index < text.length - 1) acc += spacing;
        return acc;
      }, 0);
      return totalWidth;
    };

    for (let i = 0; i < line_count; i++) {
      let line = '';
      let words = lines[i].split(/(\s|\n)/);

      for (let wordidx = 0; wordidx < words.length; wordidx++) {
        const word = words[wordidx];
        const potentialLine = line + word;

        let potentialLineWidth =
          this.isSafari && letterSpacing
            ? measureTextWithSpacing(this.local.ctx, potentialLine, letterSpacing)
            : this.local.ctx.measureText(potentialLine).width;

        if (potentialLineWidth > width || word === '\n') {
          if (line !== '') {
            lines[i] = line.trim();

            if (wordidx !== words.length - 1) {
              lines.splice(i + 1, 0, words.slice(wordidx).join(''));
              line_count++;
            } else if (word !== '\n') {
              lines.push(word);
            }
          } else {
            let remainingWord = word;
            let currentLine = i;

            while (remainingWord.length > 0) {
              let wordFragment = '';
              for (let c = 0; c < remainingWord.length; c++) {
                if (this.local.ctx.measureText(wordFragment + remainingWord[c]).width <= width || c == 0) {
                  wordFragment += remainingWord[c];
                } else {
                  break;
                }
              }

              remainingWord = remainingWord.slice(wordFragment.length);
              lines[currentLine] = wordFragment.trim();

              if (remainingWord.length > 0) {
                lines.splice(currentLine + 1, 0, remainingWord);
                currentLine++;
                line_count++;
              }
            }

            if (words.slice(wordidx + 1).length > 0) {
              lines[currentLine] += words.slice(wordidx + 1).join('');
            }
          }
          break;
        } else {
          line = potentialLine;
        }

        if (wordidx === words.length - 1) {
          lines[i] = line.trim();
        }
      }
    }

    lines.forEach((ln, i) => {
      render(ln, index);
      if (i < lines.length - 1) {
        index++;
      }
    });

    this.local.ctx.translate(-(x + width / 2), -(y + height / 2));
    this.local.ctx.restore();

    this.height = lineHeight * index + lineHeight;

    if (this.justCreated) {
      this.width = this.local.ctx.measureText(this.textContent).width + 20;
      this.height = lineHeight;
      this.coords = [
        [-2, 0],
        [-2 + this.width, 0],
        [-2 + this.width, 0 + this.height],
        [-2, 0 + this.height],
      ];
    } else {
      this.coords = [
        [0, 0],
        [0 + renderWidth, 0],
        [0 + renderWidth, 0 + renderHeight],
        [0, 0 + renderHeight],
      ];
    }
  }
}
