<script>
import Button from "../components/Button.vue";
import Input from "../components/Input.vue";
import { FirebaseStore } from "../stores/FirebaseStore.js";
import { logsnagTrack } from '../scripts/LogSnag.js';
import * as UnicornStudio from '../lib/unicornStudio';

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export default {
  components: {
    Button,
    Input,
  },
  data() {
    return {
      email: '',
      signedup: false,
      recaptchaToken: '',
      submitting: false,
      unicornStudio: '',
      honeypotField: '', // Honeypot field for spam detection
      formRenderTime: null, // Used for the time-based challenge
      validation: {
        invalidEmail: false,
        error: "",
      },
    };
  },
  mounted() {
    this.unicornStudio = UnicornStudio;
    if(window.location.hostname.includes('unicorn.studio') && window.UnicornStudio) {
      this.unicornStudio = window.UnicornStudio;
    }
    if(this.unicornStudio && this.unicornStudio.init) {
      this.unicornStudio.init();
    }

    this.formRenderTime = Date.now();
  },
  unmounted() {
    if(this.unicornStudio && this.unicornStudio.destroy) {
      this.unicornStudio.destroy();
    }
    if(document.getElementById('recaptcha')) {
      document.getElementById('recaptcha').remove();
    }
  },
  methods: {
    submitForm(e) {
      e.preventDefault();
      if (this.honeypotField) {
        console.warn('Spam detected - honeypot filled.');
        return;
      }

      const currentTime = Date.now();
      if (currentTime - this.formRenderTime < 1000) {
        console.warn('Spam detected - form submitted too quickly.');
        return;
      }

      if (this.email) {
        this.validation.error = '';
        if (isValidEmail(this.email)) {
          this.submitting = true;
          this.submitSignup();
        } else {
          this.validation.error = 'Please enter a valid email address';
        }
      } else {
        this.validation.error = 'Please enter a valid email address';
      }
    },
    async submitSignup() {
      try {
        const resp = await FirebaseStore.createPreSignup({ email: this.email, createdAt: new Date() });
        this.signedup = true;
        this.submitting = false;
        
        logsnagTrack({
          channel: "pre-signup",
          event: "New access request",
          description: `email: ${this.email}`,
          icon: "🔥",
          notify: true,
          tags: {
            email: this.email,
            uid: resp.id
          }
        });
      } catch (err) {
        console.log(err);
        this.submitting = false;
      }
    }
  },
};
</script>

<template>
  <div class="wrapper">
    <div class="hero-container">
      <div class="home-header">
        <div class="flex align-center">
          <div class="home-logo mr-2">
            <svg width="30px" height="30px" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M298.219 257.12 300 43.718C300 14.46 278.01.34 258.018.34c-23.59-1.243-35.984 15.615-41.982 30.971l-84.592 242.82L176.5 48C182.5 19 164 .05 134.79.05c-32.61-.206-71.413.29-93.808.29 0 0-15.493.66-27.988 12.16C-.162 24.61.106 40.387.106 40.387-.357 63.15.847 156.9.847 156.9.847 257.12 58 289.298 99.94 295.571c24.007 3.59 41.128-6.88 51.237-21.44l137.828-202.99c-24.157 58.337-69.97 165.148-72.251 170.58C203.542 275.727 231.748 300 256.237 300c30.769 0 42.149-28.587 41.982-42.88Z" fill="#fff"/></svg>
          </div>
          <img class="wordmark" src="/images/uswordmark.svg">
        </div>
        <div class="flex align-center">
          <a class="a login-button" href="/login">Login</a>
        </div>
      </div>
      <div v-if="submitting" class="loading-bar"></div>
      <div class="selection-box-container">
        <div class="canvas-container" id="hero-container" data-us-project-src="../hero-text-1.4.0.json" data-us-altText="Make your site dazzle">
          <h1 class="visually-hidden">Make your site dazzle</h1>
        </div>
      </div>
      <div class="usp-container">
        <p class="p p__large">Elevate your web designs with enchanting WebGL effects, motion, and interactivity — with no code.</p>
        <form class="form flex align-center mt-4" @submit="submitForm">
          <div class="field">
            <Input 
              class="field-input w-100"
              type="email"
              placeholder="Email"
              :disabled="signedup"
              v-model="email"
            />
          </div>
          <input type="text" v-model="honeypotField" style="display:none" aria-hidden="true" />
          <Button class="large request-button relative ml-1" data-sitekey="6Lc_ASglAAAAABsEOWFnj8m0ko3YvHaTKphERMJ8" :disabled="signedup || submitting" type="submit">Join waitlist</Button>
        </form>
          <p v-if="!submitting && !signedup" class="font-tertiary-color small-text">Over <span class="gray">40,000</span> others have already joined</p>
        <div v-if="submitting" class="mt-2">Submiting...</div>
        <div v-if="signedup" class="mt-2">
          Success — you're on the list! 
        </div>
        <div v-if="validation.error" class="mt-1" style="color: var(--primary-color)">{{ validation.error }}</div>
      </div>
    </div>
    <div class="section">
      <div class="ui-wrapper">
        <div class="browser-header">
          <div class="dots">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
          <div class="url-bar flex align-center justify-between">
            <div>unicorn.studio</div>
            <svg class="ml-auto" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="var(--accent-color)" viewBox="0 0 256 256"><path d="M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z"></path></svg>
          </div>
        </div>
        <!-- <div id="realtime" class="realtime-effects" data-us-project="BN52IT4X3WAHQ5oJE1Hl" data-us-scale="0.8"></div> -->
        <div class="teaser-vid-container">
          <video class="teaser-vid" src="https://assets.unicorn.studio/media/us_teaser.mp4" autoplay muted loop playsinline></video>
        </div>
        <div class="ui-image-wrapper w-100">
          <img src="/images/ui_2.png">
        </div>
      </div>
    </div>
    <section class="section">
      <div class="section section__split mb-0">
      <div class="section section__half section-border relative mb-0">
        <div class="feature-text">
          <h3 class="h3">Explore 53+ powerful effects</h3>
          <p class="p">Mix, match, and stack endless combos for a unique result.</p>
        </div>
        <div class="images-section flex">
          <div class="image-a">
            <img class="image-border w-100" src="/images/imagea.png">
          </div>
          <div class="image-b">
            <video class="image-border w-100" src="/videos/imageb.mp4" autoplay muted loop playsinline></video>
          </div>
          <div class="image-c">
            <img class="image-border w-100" src="/images/shoe.webp">
          </div>
        </div>
        <div class="box-fade"></div>
      </div>
      <div class="section section__half section-border relative mb-0">
        <div class="feature-text">
          <h3 class="h3">Super easy motion and interactivity</h3>
          <p class="p">Including time and interaction based events</p>
        </div>
        <div class="embed-browser">
          <div class="browser-header">
            <div class="dots">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
            <div class="url-bar flex align-center justify-between">
              <div>yourwebsite.com</div>
              <svg class="ml-auto" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="var(--accent-color)" viewBox="0 0 256 256"><path d="M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z"></path></svg>
            </div>
          </div>
          <img class="website-overlay" src="/images/livebg.png">
          <div class="website-overlay-canvas">
            <div class="w-100" style="height: 100%" data-us-lazyload="true" data-us-production="true" data-us-project="gs09iakMONg1C1hu0nfG" data-us-scale="0.75"></div>
          </div>
        </div>
        <div class="box-fade"></div>
      </div>
    </div>
    <div class="section section__split">
      <div class="section section__half section-border section-border__no-pad section-border__dark relative">
        <div class="feature-text">
          <h3 class="h3">Built for speed and performance</h3>
          <p class="p mb-0">Super light library (36k gzipped), shader and asset optimization.</p>
        </div>
        <div class="bento-scene-container" data-us-lazyload="true" data-us-production="true" data-us-project="9V8JN9yUSGff6cWS2wMm"></div>
      </div>
      <div class="section section__half section-border section-border__dark relative">
        <div class="feature-text">
          <h3 class="h3">Embed with a few clicks</h3>
          <p class="p">Framer, Webflow, or your coded site.</p>
        </div>
        <div class="bento-scene-container bento-scene-container_padded " data-us-production="true" data-us-project="xDMqNwDKoSRUcDQ3nH6B"></div>
      </div>
    </div>
    <div class="section section__split">
      <div class="section section__half section-border section-triple">
        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="var(--font-secondary-color)" viewBox="0 0 256 256"><path d="M211.89,119.09a4,4,0,0,0-2.49-2.84l-60.81-22.8,15.33-76.67a4,4,0,0,0-6.84-3.51l-112,120a4,4,0,0,0-1,3.64,4,4,0,0,0,2.49,2.84l60.81,22.8L92.08,239.22a4,4,0,0,0,6.84,3.51l112-120A4,4,0,0,0,211.89,119.09ZM102.68,227l13.24-66.2a4,4,0,0,0-2.52-4.53L55,134.36,153.32,29l-13.24,66.2a4,4,0,0,0,2.52,4.53L201,121.64Z"></path></svg>
        </div>
        <h4 class="h4">Runs fast right in your browser</h4>
        <p class="p">No downloads, everything saved in the cloud.</p>
      </div>
      <div class="section section__half section-border section-triple">
        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="var(--font-secondary-color)" viewBox="0 0 256 256"><path d="M220,152v56a12,12,0,0,1-12,12H48a12,12,0,0,1-12-12V152a4,4,0,0,1,8,0v56a4,4,0,0,0,4,4H208a4,4,0,0,0,4-4V152a4,4,0,0,1,8,0Zm-94.83,2.83a4,4,0,0,0,5.66,0l40-40a4,4,0,1,0-5.66-5.66L132,142.34V40a4,4,0,0,0-8,0V142.34L90.83,109.17a4,4,0,0,0-5.66,5.66Z"></path></svg>
        </div>
        <h4 class="h4">Hi-res image and video exports</h4>
        <p class="p">Many sizes and formats available.</p>
      </div>
      <div class="section section__half section-border section-triple">
        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="var(--font-secondary-color)" viewBox="0 0 256 256"><path d="M210.83,85.17l-56-56A4,4,0,0,0,152,28H56A12,12,0,0,0,44,40v72a4,4,0,0,0,8,0V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52V216a4,4,0,0,1-4,4H176a4,4,0,0,0,0,8h24a12,12,0,0,0,12-12V88A4,4,0,0,0,210.83,85.17ZM156,41.65,198.34,84H156ZM143.84,195.8a17,17,0,0,1-7.43,12.41c-4.39,2.91-10,3.77-15.22,3.77A57.89,57.89,0,0,1,107,210.11a4,4,0,0,1,2.15-7.7c4.22,1.17,16.56,3.29,22.83-.88a8.94,8.94,0,0,0,3.91-6.75c.83-6.45-4.38-8.69-15.64-11.94-9.68-2.8-21.72-6.28-20.14-18.77a16.66,16.66,0,0,1,7.22-12.13c4.56-3.07,11-4.36,19.1-3.82a61.33,61.33,0,0,1,10.48,1.61,4,4,0,0,1-2.05,7.74c-4.29-1.13-16.81-3.12-23.06,1.11a8.51,8.51,0,0,0-3.75,6.49c-.66,5.17,3.89,7,14.42,10.08C132.26,178,145.64,181.84,143.84,195.8ZM76,152v38a22,22,0,0,1-44,0,4,4,0,0,1,8,0,14,14,0,0,0,28,0V152a4,4,0,0,1,8,0Z"></path></svg>
        </div>
        <h4 class="h4">SDK for native code embeds</h4>
        <p class="p">Run code on your site for max performance.</p>
      </div>
    </div>
    </section>
    <footer class="section relative">
      <section class="section section__centered relative">
        <h2 class="h1 mx-auto">Start exploring</h2>
        <form class="form flex align-center mt-4" @submit="submitForm">
          <div class="field">
            <Input 
              class="field-input w-100"
              autocomplete="username"
              type="email"
              placeholder="Email"
              :disabled="signedup"
              v-model="email"
            />
          </div>
          <Button class="large request-button-bottom relative ml-1" data-sitekey="6Lc_ASglAAAAABsEOWFnj8m0ko3YvHaTKphERMJ8" :disabled="signedup || submitting" type="submit">
            Join waitlist
          </Button>
        </form>
        <div v-if="submitting" class="mt-2">Submiting...</div>
        <div v-if="signedup" class="mt-2">
          Thanks, you're on the list! 
        </div>
        <div v-if="validation.error" class="mt-1" style="color: var(--primary-color)">{{ validation.error }}</div>
      </section>
      <section class="footer-links w-100 flex justify-between">
        <div class="font-secondary-color">george@unicorn.studio</div>
        <div class="flex align-center">
          <a href="/terms" target="_blank">Terms of Service</a>
          <a href="/privacy.pdf" class="ml-5" target="_blank">Privacy Policy</a>
        </div>
      </section>
      <div class="mx-auto footer-light" data-us-production="true" data-us-lazyload="true" data-us-project="e0O8J6aF9ez9uXNQPYqp" data-us-scale="0.5" data-us-dpi="1"></div>
    </footer>
  </div>

</template>

<style scoped lang="scss">


@font-face {
  font-family: 'TASA Orbiter Display';
  src: url('https://assets.unicorn.studio/fonts/studio_picks/TASA%20Orbiter%20Display%2FTASAOrbiterDisplay-Regular.otf');
}

.visually-hidden {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.a {
  text-decoration: none;
  color: var(--font-secondary-color);
  transition: color 0.25s ease;

  &:hover {
    color: var(--font-color);
  }
}

.h1 {
  font-family: 'TASA Orbiter Display', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";;
  font-size: 10rem;
  line-height: 1.1em;
  font-weight: 400;
  letter-spacing:  -0.3rem;
  margin-bottom: 0;
  background: linear-gradient(0deg,white 30%, var(--font-secondary-color) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  //letter-spacing: -0.6rem;
}

.h2 {
  font-family: 'TASA Orbiter Display', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";;
  font-size: 5.5rem;
  font-weight: 400;
  max-width: 120rem;
  letter-spacing:  -0.3rem;
  margin: 0 auto;
}

.h3 {
  font-family: 'TASA Orbiter Display', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";;
  font-size: 4rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing:  -0.1rem;
  //margin: 0 auto;
}

.h4 {
  font-family: 'TASA Orbiter Display', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";;
  font-size: 2.8rem;
  font-weight: 400;
  margin: 0 auto;
}

.p {
  font-size: 1.8rem;
  line-height: 1.2;
  width: 100%;
  //margin: 1em auto;
  color: var(--font-secondary-color);

  &.p__large {
    font-size: 2.1rem;
    line-height: 1.4;
    color: #bcbbcb;
  }
}

.wrapper {
  background-color: var(--bg-color);
  height: auto;
}

.ui-wrapper {
  position: relative;
  padding: 4rem;
}

.ui-image-wrapper {
  overflow: hidden;
  aspect-ratio: 2;
  padding: 0.5rem;
  background-color: #0B0B11;
  img {
    width: calc(100% + 2px);
    margin-left: -1px;
    margin-top: -1px;
  }
}

.browser-header {
  position: relative;
  display: flex;
  background-color: var(--bg-color);
  padding: 0.5rem 2rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-top: 1px solid;
  border-image-slice: 1;
  border-width: 1px;
  border-image-source: radial-gradient(#FFFFFF40 60%, #baa2f625 90%, #baa2f600 98%);
}

.dots {
  position: absolute;
  left: 2rem;
  top: 1.7rem;
  display: flex;
  align-items: center;
}

.dot {
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;

  &:first-child {
    background-color: hotpink;
  }
  &:nth-child(2) {
    background-color: gold;
  }
  &:nth-child(3) {
    background-color: green;
  }

  & + .dot {
    margin-left: 0.7rem;
  }
}

.url-bar {
  padding: 1rem 1.5rem;
  width: 25rem;
  margin: 0 auto;
  text-align: left;
  border-radius: 0.6rem;
  background-color: var(--artboard-color);
  color: var(--font-tertiary-color);
  font-size: 1.3rem;
}

.hero-container {
  margin: 0 auto;
  padding-bottom: 5rem;
  position: relative;
  text-align: center;
  background-image: linear-gradient(180deg, var(--artboard-color), var(--bg-color));
  // background-image: radial-gradient(
  //   farthest-side at 50% 100%,
  //   var(--bg-color) 50%,
  //   var(--artboard-color) 400%
  // );
}

.hero-iframe {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100vh;
  border-radius: 8rem;
  margin: 0 auto 10rem auto;
  position: relative;
  background-color: var(--artboard-color);
}

.section {
  max-width: 140rem;
  width: 100%;
  margin-top: 0rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15rem;
  
  &.section__half {
    width: 50%;
    overflow: hidden;
    margin-bottom: 1.5rem;

    & + & {
      margin-left: 1.5rem;
    }
  }
  &.section__centered {
    text-align: center;
  }

  &.section__split {
    display: flex;
    margin-bottom: 0;
  }

  &.section-border {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 6rem var(--unit6) 6rem;
    border-radius: 2rem;
    text-align: center;
    background-color: #1f1f26;
    border-top: 1px solid;
    border-image-slice: 1;
    border-width: 1px;
    border-image-source: radial-gradient(#FFFFFF30 60%, #baa2f625 90%, #baa2f600 95%);

    &.section-border__no-pad {
      padding-bottom: 0;
    }
  }

  &.section-border__dark {
    background-color: #08080a;
    border-image-source: radial-gradient(#FFFFFF30 60%, #baa2f625 90%, #baa2f600 95%);
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color);
  border-top: 1px solid;
  margin: 0 auto 2rem auto;
  border-image-slice: 1;
  border-width: 1px;
  border-image-source: radial-gradient(#FFFFFF40 50%, #baa2f650 60%, #baa2f600 85%);
  width: 5rem;
  height: 5rem;
  border-radius: 1rem;
  box-shadow: 0px 2px 0.4rem -0.15rem rgba(0,0,0,.4);
}

.login-button {
  padding: 0.8rem 1.6rem;
  background-color: #212128;
  border-top: 1px solid;
  border-image-slice: 1;
  border-width: 1px;
  border-radius: 0.3rem;
  color: var(--font-primary-color);
  border-image-source: radial-gradient(#FFFFFF30 60%, #baa2f625 90%, #baa2f600 100%);
}

.request-button {
  border-top: 1px solid;
  border-image-slice: 1;
  border-width: 1px;
  background-color: #212128;
  border-image-source: radial-gradient(#FFFFFF30 60%, #baa2f625 90%, #baa2f600 100%);
}

.request-button-bottom {
  border-bottom: 1px solid;
  border-image-slice: 1;
  border-width: 1px;
  background-color: #212128;
  border-image-source: radial-gradient(#FFFFFF30 60%, #baa2f625 90%, #baa2f600 100%);
}

.canvas-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //aspect-ratio: 2.8;
  z-index: 1;
  position: relative;
}

.selection-box-container {
  max-width: 80rem;
  width: 100%;
  aspect-ratio: 2.6;
  margin: 6rem auto 0rem;
  position: relative;
}

.selection-handle {
  position: absolute;
  width: 1rem;
  height: 1rem;
  border: 1px solid #ffffff60;
  background-color: var(--bg-color);

  &:nth-child(1) {
    top: -0.4rem;
    left: -0.4rem;
  }
  &:nth-child(2) {
    top: -0.4rem;
    left: calc(100% - 0.4rem);
  }
  &:nth-child(3) {
    top: calc(100% - 0.4rem);
    left: calc(100% - 0.4rem);
  }
  &:nth-child(4) {
    top: calc(100% - 0.4rem);
    left: -0.4rem;
  }
}

.home-header {
  display: flex;
  max-width: 144rem;
  widows: 100%;
  padding: 2rem;
  margin: 0 auto;
  justify-content: space-between;
  z-index: 1;
}

.home-logo {
  width: 4rem;
}

.usp-container {
  position: relative;
  max-width: 60rem;
  text-align: center;
  margin: -3rem auto 0 auto;
  color: #fff;
  z-index: 1;
  padding: 2rem 4rem;

}

.teaser-vid-container {
  position: absolute;
  left: 0;
  right: 0;
  width: 55%;
  top: 15rem;
  margin: 0 auto;
  overflow: hidden;
}

.teaser-vid {
  width: 136%;
  margin-left: -18%;
  pointer-events: none;
}

.ui-image {
  position: absolute;
  width: calc(100% - 8rem);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 99;
  pointer-events: none;
}

.form {
  margin-top: 4rem;
}

.text-section {
  //height: 45rem;
  width: 100%;
  height: 32vw;
}

.realtime-effects {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  width: 32vw;
  height: 32vw;
  margin: 0 auto;
}

.embed-browser {
  margin-top: 8rem;
  position: relative;
  margin-left: 6rem;
  margin-right: 6rem;
  box-shadow: 0 0.1rem 0.3rem #00000050, 0 0.3rem 0.9rem #00000025;
  border-radius: 1rem;
}

.website-overlay {
  position: absolute;
  top: 5rem;
  left: 0rem;
  right: 0;
  margin: 0 auto;
  width: calc(100% - 8rem);
}

.website-overlay-canvas {
  //max-width: 55.2rem;
  //height: 28rem;
  width: 100%;
  aspect-ratio: 2;
}

.images-section {
  margin-top: 6rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: flex-end;
  width: 80%;
  justify-content: center;
  z-index: 0;
  //margin-bottom: -4.6rem;
}

.image-border {
  box-shadow: 0 0.1rem 0.3rem #00000050, 0 0.3rem 0.9rem #00000025;
  border: 2px solid #eaeaea;
  border-radius: 0.3rem;
}

.image-a {
  width: 21rem;
  margin-right: -12rem;
  transform: translateY(8rem) translateX(2rem);
  z-index: 2;
}

.image-b {
  width: 31rem;
  z-index: 1;
  transform: translateY(-8rem) translateX(0rem);

  video {
    width: 100%;
  }
}

.image-c {
  width: 24rem;
  margin-left: -10rem;
  transform: translateY(3rem) translateX(-2rem);
  z-index: 0;
}

.galaxy-container {
  display: flex;
  position: relative;
  aspect-ratio: 2.5;
  z-index: 0;
  width: 100%;
}

.editor-hp-container {
  margin: 8rem 4rem 0 4rem;
}

.galaxy {
  position: absolute;
  width: 35%;
  right: 12rem;
  bottom: -2rem;
  z-index: 0;
}

.code-editor-image {
  position: absolute;
  top: 0;
  width: 65%;
  left: 7rem;
}

.bento-scene-container {
  height: 32rem;
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
}

iframe {
  border: 0;
  outline: 0;
}

.box-fade {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 120%;
  box-shadow: inset 0 -5rem 8rem 3rem #1f1f26, inset -3rem 0rem 6rem 2rem #1f1f26, inset 3rem 0rem 6rem 2rem #1f1f26;
  pointer-events: none;
}

.box-shadow {
  box-shadow: inset 0 -5rem 8rem 3rem var(--bg-color), inset -3rem 0rem 6rem 2rem var(--bg-color), inset 3rem 0rem 6rem 2rem var(--bg-color);
}

.remix-wheel {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto 0 -25%;
  width: 150%;
  top: 0;
  animation: spin 50s linear infinite;
}

.footer-light {
  //transform: translateY(-30rem);
  margin-top: -60rem;
  height: 80rem;
  width: 100%;
}

.footer-links {
  position: absolute;
  bottom: 10rem;
  margin: 0 auto;
  left: 0;
  right: 0;

  a {
    color: var(--font-secondary-color);
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: var(--font-primary-color);
    }
  }
}

footer.section {
  overflow-x: clip;
}

@keyframes spin {
  from {
    transform: rotateZ(0deg);
  } to {
    transform: rotateZ(360deg);
  }
}

@media only screen and (max-width: 58rem) {

  .h1 {
    font-size: 4.8rem;
    margin-top: 1em;
  }

  .h2 {
    font-size: 3.6rem;
  }

  .p.p__large {
    font-size: 1.8rem;
  }

  .text-section {
    width: 92%;
  }

  .home-logo {
    width: 3.2rem;
  }

  .selection-box-container {
    aspect-ratio: 2.4;
  }

  .hero-container {
    padding-bottom: 0;
  }

  .bento-scene-container_padded {
    width: calc(100% - 6rem);
    margin: 0 auto;
    height: 26rem;
  }

  .section {
    margin-bottom: 8rem;
    overflow-x: hidden;

    &.section__half {
      width: calc(100% - 2rem);
      margin-bottom: 1rem;
      margin-left: 1rem !important;
      margin-right: 1rem;
      padding: 6rem 4rem;
    }
  }

  .section.section-border {
    text-align: left;
  }

  .embed-browser {
    margin-left: 0rem;
    margin-right: 0rem;
    height: 22rem;
  }

  .editor-hp-container {
    margin: 8rem 0 0;
  }
  
  .usp-container {
    margin-top: -3rem;
    padding: 2rem;
  }

  .url-bar {
    padding: 0.5rem 1rem;
  }

  .dots {
    top: 1.5rem;
  }

  .dot {
    width: 0.8rem;
    height: 0.8rem;
  }

  .ui-wrapper {
    width: 110%;
    margin-left: -5%;
  }

  .browser-header {
    align-items: center;
  }

  .teaser-vid-container {
    width: 44%;
    top: 10rem;
  }

  .home-container {
    padding: 2rem 3rem;
    border-radius: 4rem;
    height: 120vh;
  }

  .text-section {
    width: 100%;
    height: 25rem;
  }

  .section__split {
    flex-direction: column;
  }

  .section-border__no-pad {
    padding-bottom: 0;
  }

  .section-border.section-border__dark {
    padding: 0;

    .feature-text {
      padding: 6rem 4rem 4rem 4rem;
    }
  }

  .section.section-triple {
    padding: 4rem 2rem;

    & > .p {
      margin: 1em 0 0 0;
    }

    & > .h4 {
      margin: 0em 0 0 0;
    }
  }

  .images-section {
    width: 100%;
  }

  .image-a {
    width: 16rem;
    margin-right: -7rem;
  }

  .image-b {
    width: 20rem;
    transform: translateY(-4rem) translateX(0rem);
  }

  .image-c {
    width: 16rem;
    margin-left: -5rem;
  }

  .remix-wheel {
    top: 25%;
  }

  .galaxy-container {
    aspect-ratio: 1.5;
  }

  .galaxy {
    width: 50%;
    right: 5rem;
    bottom: 0;
  }

  .code-editor-image {
    width: 100%;
    left: 0rem;
  }

  .feature-text {
    margin: 0 auto;
  }

  .box-fade {
    box-shadow: inset 0 -5rem 8rem 3rem #212128, inset -1rem 0rem 4rem 1rem #212128, inset 1rem 0rem 4em 1rem #212128;
  }

  .icon {
    margin-right: auto;
    margin-left: 0;
  }

  .footer-links {
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;

    .flex {
      flex-direction: column;
      justify-content: flex-start;
    }
    
    div {
      color: var(--body-text-color);
    }

    a {
      color: var(--body-text-color);
      margin-left: 0;
      margin-top: 0.5em;
    }
  }
}

.recaptcha {
  position: fixed;
  bottom: 0rem;
  right: 0rem;
  border-top-left-radius: 1rem;
  max-width: 30rem;
  background-color: var(--bg-color);
  padding: 1rem 2rem;
  z-index: 9999;
  font-size: 1.2rem;
  color: var(--font-secondary-color)
}

.wordmark {
  width: 12rem;
}

</style>