import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  EASE,
  mixRadiusProperties,
  PERLIN_NOISE,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const fbmShader = `#version 300 es
precision mediump float;

in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform float uTime;
uniform float uAmplitude;
uniform float uPhase;
uniform float uFrequency;
uniform float uTurbulence;
uniform float uMixRadius;
uniform vec2 uPos;
uniform int uEasing;
uniform int uMixRadiusInvert;
uniform float uAngle;
${UNIVERSAL_UNIFORMS}
${EASE}
${PERLIN_NOISE}

const int OCTAVES = 6;
const float PI = 3.14159265359;
mat2 rot(float a) {
  return mat2(cos(a),-sin(a),sin(a),cos(a));
}

mat2 rotHalf = mat2(cos(0.5), sin(0.5),
                  -sin(0.5), cos(0.5));

float fbm (in vec3 st) {
  float value = 0.0;
  float amp = .25;
  float frequency = 0.;
  float aM = (0.1 + uAmplitude * .65);
  vec2 shift = vec2(100.0);
  
  for (int i = 0; i < OCTAVES; i++) {
      value += amp * perlin_noise(st);
      st.xy *= rotHalf * 2.5;
      st.xy += shift;
      amp *= aM;
  }
  return value;
}

out vec4 fragColor;

void main() {
  vec2 uv = vTextureCoord;
  
  float aspectRatio = uResolution.x/uResolution.y;
  
  float multiplier = 6.0 * (uFrequency / ((aspectRatio + 1.) / 2.));

  vec2 mPos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
  vec2 pos = uMixRadius == 1.00 ? mPos : uPos;
  float mDist = ease(uEasing, max(0.,1.-distance(uv * vec2(aspectRatio, 1), mPos * vec2(aspectRatio, 1)) * 4. * (1. - uMixRadius)));

  // #ifelseopen
  if(uMixRadiusInvert == 1) {
    mDist = max(0., (0.5 - mDist));
  }
  // #ifelseclose
  
  //mDist *= texture(uMaskTexture, uv).r;

  vec2 st = (uv * vec2(aspectRatio, 1) + (1. - pos) - vec2(1)) * multiplier * aspectRatio;
  
  st = rot(uAngle * -1. * 2.0 * PI) * st;
  
  vec2 drift = vec2(uTime * 0.005);

  float time = uTime * 0.025;

  vec2 r = vec2(
    fbm(vec3(st - drift + vec2(1.7, 9.2), uPhase*25. + time)),
    fbm(vec3(st - drift + vec2(8.2, 1.3), uPhase*25. + time))
  );

  float f = fbm(vec3(st + r - drift, uPhase*25. + time)) * uTurbulence;

  vec2 offset = (f * 2. + (r * uTurbulence));

  vec4 color = texture(uTexture, uv + offset * mDist);
  ${computeFragColor('color')}
}
`;

const fbmParams = {
  fragmentShader: fbmShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    amplitude: {
      name: 'uAmplitude',
      type: '1f',
      value: 0.5,
    },
    turbulence: {
      name: 'uTurbulence',
      type: '1f',
      value: 0.5,
    },
    frequency: {
      name: 'uFrequency',
      type: '1f',
      value: 0.2,
    },
    phase: {
      name: 'uPhase',
      type: '1f',
      value: 0,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 1,
    },
    angle: {
      name: 'uAngle',
      type: '1f',
      value: 0,
    },
    easing: {
      name: 'uEasing',
      type: '1i',
      value: 0,
    },
    mixRadius: {
      name: 'uMixRadius',
      type: '1f',
      value: 1,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    mixRadiusInvert: {
      name: 'uMixRadiusInvert',
      type: '1i',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const FBM = {
  id: 'fbm',
  label: 'FBM',
  params: fbmParams,
  animation: {
    active: false,
    speed: 1,
  },
  aspectRatio: 1,
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    frequency: {
      label: 'Scale',
      value: 0.2,
      min: 0.01,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the scale of the noise pattern',
    },
    amplitude: {
      label: 'Gain',
      value: 0.5,
      min: 0.01,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the strength of fine or high frequency noise pattern',
    },
    turbulence: {
      label: 'Amplitude',
      value: 0.5,
      min: 0.1,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the strength of low frequency noise pattern',
    },
    phase: {
      label: 'Phase',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the time element of the effect',
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
    },
    ...mixRadiusProperties,
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ...interactiveProperties,
  },
};
