import { Vec2, Vec3 } from 'curtainsjs';
import {
  vertexShader,
  UNIVERSAL_UNIFORMS,
  FLOATING_POINT,
  universalUniformParams,
  interactiveProperties,
  computeFragColor,
} from '../ShaderHelpers.js';

const fragmentShader = `#version 300 es
precision mediump float;

in vec3 vVertexPosition;
in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform float uFov;
uniform float uScale;
uniform float uTime;
uniform float uSkew;
uniform vec2 uPos;
uniform int uRepeat;
uniform vec2 uDirection;
${UNIVERSAL_UNIFORMS}

const float PI = 3.14159265;

vec3 getRayDirection(vec2 uv, vec2 mousePos, float aspect) {
    vec2 screenPos = (uv - 0.5) * 2.0;
    screenPos.x *= aspect;
    screenPos.y *= -1.0;
    
    float minFOV = radians(20.0);
    float maxFOV = radians(120.0);
    float fov = mix(minFOV, maxFOV, uFov);
    
    vec3 rayDir = normalize(vec3(screenPos.x * tan(fov/2.0), 
                                screenPos.y * tan(fov/2.0), 
                                -1.0));
    
    float rotX = (mousePos.y - 0.5) * PI;
    float rotY = (mousePos.x - 0.5) * PI * 2.0;
    
    mat3 rotateY = mat3(
        cos(rotY), 0.0, -sin(rotY),
        0.0, 1.0, 0.0,
        sin(rotY), 0.0, cos(rotY)
    );
    
    mat3 rotateX = mat3(
        1.0, 0.0, 0.0,
        0.0, cos(rotX), sin(rotX),
        0.0, -sin(rotX), cos(rotX)
    );
    
    return normalize(rotateX * rotateY * rayDir);
}

vec2 directionToUVHorizontal(vec3 dir) {
  float longitude = atan(dir.z, dir.x);
  float latitude = acos(dir.y);
  
  vec2 uv;
  uv.x = longitude / (2.0 * PI) + 0.5;
  uv.y = latitude / PI;
  
  // Align the primary view
  uv.x += 0.25;
  
  return uv;
}

vec2 directionToUVVertical(vec3 dir) {
  float longitude = atan(dir.z, dir.y);
  float latitude = acos(dir.x);
  
  vec2 uv;
  uv.y = longitude / PI * -1.;
  uv.x = (latitude / (2.0 * PI) + 0.5) * -1.;
  
  uv.x = fract(uv.x + 0.25);
  
  return uv;
}

out vec4 fragColor;

void main() {
  float aspect = 2.;
  vec2 mPos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse * 0.5);
  
  vec3 rayDir = getRayDirection(vTextureCoord, mPos, aspect);
  vec2 uvHorizontal = directionToUVHorizontal(rayDir);
  vec2 uvVertical = directionToUVVertical(rayDir);
  
  vec2 sphereUV = mix(uvHorizontal, uvVertical, uSkew);
  
  // Add FOV compensation to scale
  float minFOV = radians(20.0);
  float maxFOV = radians(120.0);
  float currentFOV = mix(minFOV, maxFOV, uFov);
  float fovCompensation = tan(currentFOV/2.0);
  
  // Adjust scale based on FOV
  float compensatedScale = (mix(-0.1, 0.4, uScale) * 12.0 + 2.0) * (1.0/fovCompensation);
  sphereUV = (sphereUV - 0.5) * compensatedScale + 0.5;

  sphereUV += uDirection * uTime * 0.005;

  // Handle different repeat modes
  vec2 finalUV;
  if (uRepeat == 0) {
    finalUV = sphereUV;
  } else if (uRepeat == 1) {
    // Repeat horizontal only
    finalUV = vec2(fract(sphereUV.x), sphereUV.y);
  } else if (uRepeat == 2) {
    // Repeat vertical only
    finalUV = vec2(sphereUV.x, fract(sphereUV.y));
  } else {
    // Repeat both (original behavior)
    finalUV = fract(sphereUV);
  }
  
  vec4 col = texture(uTexture, finalUV);
  
  ${computeFragColor('col')}
}`;

export const params = {
  fragmentShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depth: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    scale: {
      name: 'uScale',
      type: '1f',
      value: 0.5,
    },
    fov: {
      name: 'uFov',
      type: '1f',
      value: 0.5,
    },
    direction: {
      name: 'uDirection',
      type: '2f',
      value: new Vec2(0.5, 0),
    },
    repeat: {
      name: 'uRepeat',
      type: '1i',
      value: 1,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    skew: {
      name: 'uSkew',
      type: '1f',
      value: 0.5,
    },
    direction: {
      name: 'uDirection',
      type: '2f',
      value: new Vec2(0.5, 0),
    },
    ...universalUniformParams,
  },
};

export const PROJECTION = {
  id: 'projection',
  label: 'Projection',
  params: params,
  animation: {
    active: false,
    speed: 1,
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    scale: {
      label: 'Scale',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Resolution of the cells',
    },
    fov: {
      label: 'Warp',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Field of view',
    },
    skew: {
      label: 'Skew',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Resolution of the cells',
    },
    repeat: {
      label: 'Repeat',
      value: 1,
      options: {
        0: 'Off',
        1: 'Repeat Horiz.',
        2: 'Repeat Vert.',
        3: 'Repeat Both',
      },
      tooltip: 'Include or hide the underlying layers',
    },
    direction: {
      label: 'Speed',
      header: 'Animation',
      value: new Vec2(0.5, 0),
      output: 'percent',
    },
    ...interactiveProperties,
  },
};
