import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
} from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const adjustmentsShader = `#version 300 es
precision mediump float;

in vec3 vVertexPosition;
in vec2 vTextureCoord;

uniform sampler2D uTexture;

uniform float uHue;
uniform float uSaturation;
uniform float uBrightness;
uniform float uContrast;
uniform float temperature;
uniform float uSharpness;
uniform float uGamma;
${UNIVERSAL_UNIFORMS}

// Helper function for the above HSL to RGB conversion
float hueToRgb(float p, float q, float t) {
    if (t < 0.0) t += 1.0;
    if (t > 1.0) t -= 1.0;
    if (t < 1.0 / 6.0) return p + (q - p) * 6.0 * t;
    if (t < 1.0 / 2.0) return q;
    if (t < 2.0 / 3.0) return p + (q - p) * (2.0 / 3.0 - t) * 6.0;
    return p;
}

// Convert from HSL to RGB
vec3 hslToRgb(vec3 hsl) {
    float h = hsl.x;
    float s = hsl.y;
    float l = hsl.z;
    
    vec3 rgb = vec3(l);  // achromatic color (grey)
    if (s != 0.0) {
        float q = l < 0.5 ? l * (1.0 + s) : l + s - l * s;
        float p = 2.0 * l - q;
        rgb.r = hueToRgb(p, q, h + 1.0 / 3.0);
        rgb.g = hueToRgb(p, q, h);
        rgb.b = hueToRgb(p, q, h - 1.0 / 3.0);
    }
    return rgb;
}

// Convert from RGB to HSL
vec3 rgbToHsl(vec3 rgb) {
    float max = max(max(rgb.r, rgb.g), rgb.b);
    float min = min(min(rgb.r, rgb.g), rgb.b);
    float h, s, l = (max + min) / 2.0;

    if (max == min) {
        h = s = 0.0; // achromatic
    } else {
        float d = max - min;
        s = l > 0.5 ? d / (2.0 - max - min) : d / (max + min);
        if (max == rgb.r) {
            h = (rgb.g - rgb.b) / d + (rgb.g < rgb.b ? 6.0 : 0.0);
        } else if (max == rgb.g) {
            h = (rgb.b - rgb.r) / d + 2.0;
        } else if (max == rgb.b) {
            h = (rgb.r - rgb.g) / d + 4.0;
        }
        h /= 6.0;
    }

    return vec3(h, s, l);
}

out vec4 fragColor;

void main() {
  vec2 uv = vTextureCoord;
  vec4 color = texture(uTexture, uv);

  // Convert to OKHSL and adjust uHue, uSaturation, and uBrightness
  color.rgb = rgbToHsl(color.rgb);
  color.x = fract(color.x + uHue);
  color.y = clamp(color.y * uSaturation, 0.0, 1.0);
  color.z = clamp(color.z + uBrightness, 0.0, 1.0);
  color.rgb = hslToRgb(color.rgb);

  // Adjust uContrast
  color.rgb = uContrast * (color.rgb - 0.5) + 0.5;

  // Adjust temperature
  color.r = clamp(color.r + temperature, 0.0, 1.0);
  color.b = clamp(color.b - temperature, 0.0, 1.0);

  // uGamma correction
  color.rgb = pow(max(color.rgb, 0.0001), vec3(1.0 / (max(uGamma, 0.0) + 1.0)));

  // Sharpen
  vec3 avgSurround = (
      texture(uTexture, uv + vec2(-1.0, 0.0) / uResolution).rgb +
      texture(uTexture, uv + vec2(1.0, 0.0) / uResolution).rgb +
      texture(uTexture, uv + vec2(0.0, -1.0) / uResolution).rgb +
      texture(uTexture, uv + vec2(0.0, 1.0) / uResolution).rgb) / 4.0;
  color.rgb += uSharpness * (color.rgb - avgSurround);

  color = vec4(clamp(color.rgb, 0.0, 1.0), color.a);
  ${computeFragColor('color')}
}`;

const adjustmentsParams = {
  fragmentShader: adjustmentsShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    hue: {
      name: 'uHue',
      type: '1f',
      value: 1,
    },
    saturation: {
      name: 'uSaturation',
      type: '1f',
      value: 1,
    },
    temperature: {
      name: 'temperature',
      type: '1f',
      value: 0,
    },
    lightness: {
      name: 'uBrightness',
      type: '1f',
      value: 0,
    },
    contrast: {
      name: 'uContrast',
      type: '1f',
      value: 1,
    },
    sharpen: {
      name: 'uSharpness',
      type: '1f',
      value: 0,
    },
    gamma: {
      name: 'uGamma',
      type: '1f',
      value: 0,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    ...universalUniformParams,
  },
};

export const ADJUST = {
  id: 'coloration',
  label: 'Adjust',
  params: adjustmentsParams,
  properties: {
    hue: {
      label: 'Hue',
      value: 1,
      min: 1,
      max: 2,
      step: 0.0027,
      output: 'degrees',
    },
    saturation: {
      label: 'Saturation',
      value: 1,
      min: 0,
      max: 2,
      step: 0.01,
      output: 'percent',
    },
    temperature: {
      label: 'Temperature',
      value: 0,
      min: -0.25,
      max: 0.25,
      step: 0.01,
      output: 'percent',
    },
    lightness: {
      label: 'Exposure',
      value: 0,
      min: -0.5,
      max: 0.5,
      step: 0.01,
      output: 'percent',
    },
    contrast: {
      label: 'Contrast',
      value: 1,
      min: 0,
      max: 2,
      step: 0.01,
      output: 'percent',
    },
    sharpen: {
      label: 'Sharpness',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    gamma: {
      label: 'Gamma',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
  },
};
