import { universalUniformParams, vertexShaderNoMatrix, UNIVERSAL_UNIFORMS } from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const fragmentShader = `#version 300 es
precision highp float;

in vec3 vVertexPosition;
in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform sampler2D uPingPongTexture;
uniform vec2 uPreviousMousePos;
uniform float uRadius;
uniform float uDissipate;
uniform float uDecay;
uniform float uScale;
uniform float uLiquidity;
uniform float uSpeed;
${UNIVERSAL_UNIFORMS}

const float PI = 3.1415926;
const float TWOPI = 6.2831852;

out vec4 fragColor;

void main() {
    vec2 aspect = vec2(uResolution.x/uResolution.y, 1);
    vec2 texelSize = (1.0 / (vec2(1080) * aspect)) * mix(1., 8., uSpeed);
    vec2 vUv = vTextureCoord;
    vec2 mPos = mix(uMousePos, (uMousePos - 0.5) * 0.5 + 0.5, uScale);
    vec2 pmPos = mix(uPreviousMousePos, (uPreviousMousePos - 0.5) * 0.5 + 0.5, uScale);
    vec2 scaledUv = mix(vUv, (vUv - 0.5) * 0.5 + 0.5, uScale);

    float waveSpeed = 1.;
    float damping = mix(0.8, 0.999, uDecay);
    float velocityDamping = damping;
    float heightDamping = damping;
    float time = 0.5;

    vec4 data = texture(uPingPongTexture, vUv);
    float height = data.r;
    float velocity = data.g;

    float laplacian = 0.0;
    float totalWeight = 0.0;
    float scaleDiff = uScale * 0.25;
    vec2 clampRegionMin = vec2(uScale * 0.5 - scaleDiff);
    vec2 clampRegionMax = vec2(1.0 - uScale * 0.5 + scaleDiff);

    // Right sample
    vec2 offset = vec2(texelSize.x, 0.0);
    vec2 neighborUv = clamp(vUv + offset, clampRegionMin, clampRegionMax);
    float weight = 1.0 - length(offset) / (length(texelSize) * 2.0);
    laplacian += texture(uPingPongTexture, neighborUv).r * weight;
    totalWeight += weight;

    // Left sample
    offset = vec2(-texelSize.x, 0.0);
    neighborUv = clamp(vUv + offset, clampRegionMin, clampRegionMax);
    weight = 1.0 - length(offset) / (length(texelSize) * 2.0);
    laplacian += texture(uPingPongTexture, neighborUv).r * weight;
    totalWeight += weight;

    // Up sample
    offset = vec2(0.0, texelSize.y);
    neighborUv = clamp(vUv + offset, clampRegionMin, clampRegionMax);
    weight = 1.0 - length(offset) / (length(texelSize) * 2.0);
    laplacian += texture(uPingPongTexture, neighborUv).r * weight;
    totalWeight += weight;

    // Down sample
    offset = vec2(0.0, -texelSize.y);
    neighborUv = clamp(vUv + offset, clampRegionMin, clampRegionMax);
    weight = 1.0 - length(offset) / (length(texelSize) * 2.0);
    laplacian += texture(uPingPongTexture, neighborUv).r * weight;
    totalWeight += weight;

    float avgNeighbors = laplacian / totalWeight;
    laplacian = avgNeighbors - height;

    velocity += waveSpeed * waveSpeed * laplacian;
    velocity *= velocityDamping;
    
    height += velocity;
    height *= heightDamping;

    float mouseSpeed = distance(mPos, pmPos);
    float dist = distance(vUv * aspect, mPos * aspect);
    float radius = 0.025;
    
    if (dist < radius && mouseSpeed > 0.0001) {
        float drop = cos(dist / radius * PI * time);
        float intensity = mouseSpeed * 20.;
        height += drop * intensity;
    }

    fragColor = vec4(height, velocity, 0.0, 1.0);
}
`;

export const WATER_RIPPLE_PINGPONG = {
  fragmentShader: fragmentShader,
  vertexShader: vertexShaderNoMatrix,
  crossorigin: 'Anonymous',
  texturesOptions: {
    floatingPoint: 'half-float',
    premultiplyAlpha: true,
  },
  uniforms: {
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    previousMousePos: {
      name: 'uPreviousMousePos',
      type: '2f',
      value: new Vec2(0.5),
    },
    speed: {
      name: 'uSpeed',
      type: '1f',
      value: 1,
    },
    scale: {
      name: 'uScale',
      type: '1f',
      value: 0.75,
    },
    decay: {
      name: 'uDecay',
      type: '1f',
      value: 0.75,
    },
    ...universalUniformParams,
  },
};
