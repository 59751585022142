import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
} from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const fragmentShader = `#version 300 es
precision mediump float;
in vec2 vTextureCoord;
uniform sampler2D uTexture;
uniform float uSpacing;
uniform float uAngle;
uniform float uWidth;
uniform float uTime;
uniform float uRepeatCount;
${UNIVERSAL_UNIFORMS}

out vec4 fragColor;

void main() {
  vec2 uv = vTextureCoord;
  float rotation = (uAngle * 2. * 3.141592653);
  float aspectRatio = uResolution.x/uResolution.y;
  vec2 aberrated = vec2(0);
  vec4 col = vec4(0);
  float repeatSpacing = uSpacing * 0.35 * mix(1., aspectRatio, 0.5);
  float time = (uTime * 0.025) / (repeatSpacing + 0.001);

  for(float i = 0.; i < uRepeatCount; i++){
      float offset = repeatSpacing * (i - uRepeatCount/2. + fract(time));
      aberrated = vec2(offset * sin(rotation), offset * cos(rotation));
      col += texture(uTexture, uv + aberrated) * (1. - col.a);
  }
  
  ${computeFragColor('col')}
}

`;

const params = {
  fragmentShader: fragmentShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    amount: {
      name: 'uSpacing',
      type: '1f',
      value: 0.5,
    },
    angle: {
      name: 'uAngle',
      type: '1f',
      value: 0,
    },
    width: {
      name: 'uWidth',
      type: '1f',
      value: 0,
    },
    repeatCount: {
      name: 'uRepeatCount',
      type: '1f',
      value: 16,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const REPLICATE = {
  id: 'replicate',
  label: 'Replicate',
  params: params,
  aspectRatio: 1,
  animation: {
    active: false,
    speed: 1,
  },
  properties: {
    amount: {
      label: 'Spacing',
      value: 1,
      min: 0,
      max: 1,
      step: 0.005,
      output: 'percent',
    },
    repeatCount: {
      label: 'Replicates',
      value: 16,
      min: 1,
      max: 100,
      step: 1,
      output: 'number',
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
    },
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      hidden: true,
    },
    speed: {
      label: 'Speed',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
  },
};
