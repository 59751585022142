import { Layer } from './Layer.js';
import { Vec2, Vec3 } from 'curtainsjs';
import { EFFECTS } from '../Shaders.js';
import { hexToRgb } from '../../scripts/ColorHelpers';
import { ResetPropCommand } from '../../scripts/Commands';
import { optimizeShaders } from '../../scripts/ShaderOptimize';
import { StudioStore } from '../../stores/StudioStore.js';
import { StateEffectAppear, StateEffectScroll, StateEffectHover } from '../../scripts/StateEffect';

// Helper function to convert hex to rgb float array
function hexToRgbFloat(hex) {
  const rgb = hexToRgb(hex);
  return rgb.map(val => val / 255);
}

// Convert fill colors to Float32Array
function convertFillColors(fill) {
  if (Array.isArray(fill)) {
    return new Float32Array(fill.map(hexToRgbFloat).flat());
  }
  return fill;
}

export class Effect extends Layer {
  layerType = 'effect';

  constructor(args, id) {
    super(args, id);

    this.type = args.type;

    if (args.isBackground) {
      this.isBackground = true;
      this.type = 'gradient';
    } else {
      this.isBackground = false;
    }

    const effectProperties = this.getParams().properties;

    if (effectProperties) {
      for (let prop in effectProperties) {
        if (
          prop === 'fill' &&
          (this.type === 'gradient' || this.type === 'gradientFill' || this.type === 'gradientMap') &&
          args.fill
        ) {
          this.fill = Object.values(args.fill);
        } else {
          this[prop] = prop in args ? args[prop] : effectProperties[prop].value;
        }
      }
    }

    this.effects = args.effects || [];
    this.texture = args.texture || false;
    this.parentLayer = args.parentLayer || false;
    this.animating = this.type === 'mouse' ? false : args.animating || false;
    this.mouseMomentum = args.mouseMomentum || 0;
    this.isMask = args.isMask || 0;
    this.states = {
      appear: args.states && args.states.appear ? args.states.appear.map(n => new StateEffectAppear(n)) : [],
      scroll: args.states && args.states.scroll ? args.states.scroll.map(n => new StateEffectScroll(n)) : [],
      hover: args.states && args.states.hover ? args.states.hover.map(n => new StateEffectHover(n)) : [],
    };
    this.customFragmentShaders = args.customFragmentShader
      ? [args.customFragmentShader]
      : args.customFragmentShaders || [];
    this.customVertexShaders = args.customVertexShader ? [args.customVertexShader] : args.customVertexShaders || [];
    this.compiledFragmentShaders = args.compiledFragmentShaders || [];
    this.compiledVertexShaders = args.compiledVertexShaders || [];

    if (effectProperties) {
      let filteredProperties = Object.keys(effectProperties).filter(key => !effectProperties[key].responsiveDisabled);
      this.local.propertiesToWatch = filteredProperties;
    }

    if (this.type === 'custom' && !this.customFragmentShaders.length) {
      this.customFragmentShaders = [this.getParams().params.fragmentShader];
      this.customVertexShaders = [this.getParams().params.vertexShader];
    }
  }

  getParams() {
    if (this.type === 'mouse') {
      let params = EFFECTS.mouse;
      params.params.uniforms = { ...params.params.uniforms, ...EFFECTS.mouseTrail.uniforms };
      return params;
    }
    return EFFECTS[this.type];
  }

  resetProp(prop) {
    const params = this.getParams();
    const originalValue = params.properties[prop].value;

    const command = new ResetPropCommand(this, prop, this[prop], originalValue);
    StudioStore.performAction(command);
  }

  updateUniforms() {
    const parent = this.getParent();
    this.getPlanes().forEach(plane => {
      StudioStore.updatePlaneUniforms(plane, this, parent);
    });
  }

  package(skipSerialization) {
    const params = this.getParams();
    let packaged = {
      states: {
        appear: this.states.appear ? this.states.appear.map(n => n.package()) : [],
        scroll: this.states.scroll ? this.states.scroll.map(n => n.package()) : [],
        hover: this.states.hover ? this.states.hover.map(n => n.package()) : [],
      },
      breakpoints: this.prepBreakpoints(this.breakpoints),
      layerType: this.layerType,
      layerName: this.layerName,
      customFragmentShaders: this.customFragmentShaders,
      customVertexShaders: this.customVertexShaders,
      visible: this.visible,
      locked: this.locked,
      isBackground: this.isBackground,
      aspectRatio: this.aspectRatio,
      type: this.type,
      texture: this.texture,
      mouseMomentum: this.mouseMomentum,
      isMask: this.isMask,
      parentLayer: this.parentLayer,
      animating: this.animating,
    };

    for (let prop in params.properties) {
      if (!skipSerialization) {
        if (params.properties[prop].value.type === 'Vec2') {
          packaged[prop] = {
            type: 'Vec2',
            _x: this[prop]?._x ?? params.properties[prop].value._x,
            _y: this[prop]?._y ?? params.properties[prop].value._y,
          };
        } else if (params.properties[prop].value.type === 'Vec3') {
          packaged[prop] = {
            type: 'Vec3',
            _x: this[prop]?._x ?? params.properties[prop].value._x,
            _y: this[prop]?._y ?? params.properties[prop].value._y,
            _z: this[prop]?._z ?? params.properties[prop].value._z,
          };
        } else {
          packaged[prop] = this[prop];
        }
      } else {
        packaged[prop] = this[prop];
      }
    }
    if (this.type === 'gradient' || this.type === 'gradientMap' || this.type === 'gradientFill') {
      packaged.count = this.fill.length;
    }

    return packaged;
  }

  unpackage() {
    for (let prop in this) {
      if (this[prop].type === 'Vec2') {
        if (this.getParams().properties[prop].value.type === 'Vec3') {
          this[prop] = new Vec3(this[prop]._x, this[prop]._y, this.getParams().properties[prop].value._z);
        } else {
          this[prop] = new Vec2(this[prop]._x, this[prop]._y);
        }
      } else if (this[prop].type === 'Vec3') {
        this[prop] = new Vec3(this[prop]._x, this[prop]._y, this[prop]._z);
      }
    }
    
    this.breakpoints.forEach(bp => {
      for(let prop in bp.props) {
        if (bp.props[prop].type === 'Vec2') {
          if (this.getParams().properties[prop].value.type === 'Vec3') {
            bp.props[prop] = new Vec3(bp.props[prop]._x, bp.props[prop]._y, this.getParams().properties[prop].value._z);
          }
        }
      }
    });
    
    return this;
  }

  getParent() {
    return StudioStore.state.history
      .filter(n => n.effects && n.effects.length)
      .find(n => n.effects.includes(this.parentLayer));
  }

  getChildEffectIndex() {
    const parentEffects = this.getParent().effects;
    return parentEffects.indexOf(this.parentLayer);
  }

  resetStateEffects() {
    this.getPlanes().forEach(plane => {
      plane.userData.createdAt = performance.now();
    });
    if (this.states) {
      this.states.appear
        .filter(n => n.resetState)
        .forEach(effect => {
          effect.resetState();
        });
      this.states.scroll
        .filter(n => n.resetState)
        .forEach(effect => {
          effect.resetState();
        });
    }
  }

  packageShaders() {
    const params = this.getParams();

    if (this.type === 'gradient' || this.type === 'gradientMap' || this.type === 'gradientFill') {
      this.count = this.fill.length;
      this.fill.forEach((color, i) => {
        const rgb = hexToRgb(color).map(v => v / 255);
        if (params.params.uniforms[`color${i}`]) {
          params.params.uniforms[`color${i}`].value = new Vec3(...rgb);
        }
      });
    }

    if (this.isMask && this.parentLayer && this.getParent()) {
      this.parentTrackMouse = this.getParent().trackMouse;
    }

    let pingpongParams = null;
    switch (this.type) {
      case 'mouse':
        pingpongParams = EFFECTS.mouseTrail;
        break;
      case 'waterRipple':
        pingpongParams = EFFECTS.waterRipplePingPong;
        break;
      default:
        null;
    }

    optimizeShaders(this, params, pingpongParams);

    let data = {
      downSample: params.downSample,
      depth: this.type === 'bulge',
      uniforms: {},
    };

    if (params.passes) {
      data.passes = params.passes;
    }
    if (params.texture) {
      data.texture = params.texture;
    }

    const retainedProps = [
      'animating',
      'aspectRatio',
      'breakpoints',
      'compiledFragmentShaders',
      'compiledVertexShaders',
      'data',
      'isMask',
      'speed',
      'visible',
      'mouseMomentum',
      'type',
      'layerType',
      'trackMouseMove',
      'trackMouse',
      'speed',
    ];

    if (this.type === 'custom') {
      retainedProps.push(...['customFragmentShaders', 'customVertexShaders', 'texture']);
    }

    if (this.texture && !(this.type === 'sdf_shape' && this.shape === 20)) {
      retainedProps.push('texture');
    }

    if (this.parentLayer) {
      retainedProps.push('parentLayer');
    }

    // Filter out breakpoints that have no props or have empty props
    this.breakpoints = this.breakpoints.filter(bp => bp.props && Object.keys(bp.props).length);

    // Convert fill colors if the prop is "fill" and ensure uniform properties are set for non-Desktop breakpoints
    this.breakpoints.forEach(bp => {
      if (bp.props.fill) {
        bp.props.fill = convertFillColors(bp.props.fill);
      }
      if (bp.name !== 'Desktop') {
        Object.keys(bp.props).forEach(prop => {
          if (!data.uniforms[prop]) {
            data.uniforms[prop] = params.params.uniforms[prop];
          }
        });
      }
    });

    if (this.states && [...this.states.appear, ...this.states.scroll, ...this.states.hover].length) {
      retainedProps.push('states');

      [...this.states.appear, ...this.states.scroll, ...this.states.hover].forEach(stateEffect => {
        retainedProps.push(stateEffect.prop);

        let props = params.params.uniforms[stateEffect.prop];
        if (props) {
          stateEffect.uniformData = {
            type: props.type,
            name: props.name,
          };
        }
      });
    }

    for (let prop in this) {
      if (!retainedProps.includes(prop)) {
        delete this[prop];
      }
    }

    this.data = data;
  }
}
