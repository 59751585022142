import hotkeys from 'hotkeys-js';
import { reactive, computed } from 'vue';
import { DesignsStore } from './DesignsStore.js';
import { TextBox } from '../scripts/layerTypes/Text.js';
import { Shape } from '../scripts/layerTypes/Shape.js';
import { Effect } from '../scripts/layerTypes/Effect.js';
import { Img } from '../scripts/layerTypes/Image.js';
import { EFFECTS } from '../scripts/Shaders.js';
import { basicParams } from '../scripts/BasicShader.js';
import { Debouncer, compressThumbnail, generateUUID } from '../scripts/Helpers.js';
import { hexToRgb } from '../scripts/ColorHelpers.js';
import { ClearCommand, CreateCommand, DeleteCommand, ReplaceCommand } from '../scripts/Commands';
import { BLEND_MODES, createSize, SCREEN_SIZES } from '../scripts/Constants';
import { Curtains, Plane, PingPongPlane, RenderTarget, Vec3 } from 'curtainsjs';

export function unpackageHistory(history) {
  const unpackagedHistory = [];
  history.forEach((item, index) => {
    switch (item.layerType) {
      case 'text':
        unpackagedHistory.push(new TextBox(item).unpackage());
        break;
      case 'image':
        unpackagedHistory.push(new Img(item).unpackage());
        break;
      case 'fill':
        unpackagedHistory.push(new Effect(item, null, true).unpackage());
        break;
      case 'shape':
        unpackagedHistory.push(new Shape(item).unpackage());
        break;
      case 'effect':
        unpackagedHistory.push(new Effect(item, null, item.isBackground || !index).unpackage());
        break;
    }
  });
  return unpackagedHistory;
}

function weightedLerp(current, last, amount) {
  for (let i = 0; i < amount; i++) {
    current = (current + last) / 2;
  }
  return +((current + last) / 2).toFixed(2);
}

// Add a helper method to check if curtains instance is valid
function isCurtainsValid(curtain) {
  return curtain && 
         curtain.renderer && 
         curtain.renderer.nextRender && 
         typeof curtain.renderer.nextRender.execute === 'function';
}

const StudioStore = {
  blendModeKeys: Object.keys(BLEND_MODES),

  state: reactive({
    animatingEffects: computed(() =>
      StudioStore.state.history.filter(n => n.visible && ((n.isAnimating && n.isAnimating()) || n.animating))
    ),
    actionHistory: [],
    browsingEffects: false,
    browsingFonts: false,
    browsingSizes: false,
    colorPicker: {
      prop: 'fill',
      item: undefined,
    },
    copied: false,
    copying: false,
    canvasWidth: computed(() => Math.round(StudioStore.state.currentSize.dimensions[0])),
    canvasHeight: computed(() => Math.round(StudioStore.state.currentSize.dimensions[1])),
    currentSize: computed(() => StudioStore.getCurrentSize()),
    customWidth: 1080,
    customHeight: 1080,
    openStateEffect: null,
    curtain: undefined,
    curtainRaf: undefined,
    baseDpi: 1.5,
    dpi: computed(() => Math.min(StudioStore.state.baseDpi, window.devicePixelRatio || 1)),
    designName: 'Untitled design',
    design: {},
    downloadSize: 1,
    downloading: false,
    drawing: false,
    draw: {},
    drawUIUpdater: 0,
    customCodeItemId: '',
    editingItemId: undefined,
    effect: {},
    embedded: false,
    export: {
      active: false,
      resolution: 1,
      downloading: false,
      progress: null,
      duration: 10,
      fps: 24,
      type: 'image',
      quality: 0.8,
      imageType: 'png',
      videoType: 'webm',
    },
    frame_blobs: [],
    fullRedrawEnabled: false,
    hd: true,
    history: [],
    hotkeys: hotkeys,
    imageType: 'png',
    image: {},
    images: [],
    imagesBrowser: {
      open: false,
      replaceId: '',
      createMsdf: false,
    },
    imageUploading: false,
    imageUploadProgress: 0,
    initialized: false,
    loading: true,
    mainMenuOpen: false,
    mouse: {
      downPos: { x: 0, y: 0 },
      movePos: { x: 0, y: 0 },
      lastPos: { x: 0, y: 0 },
      delta: { x: 0, y: 0 },
      enterTime: null,
      dragging: false,
      moved: false,
      trail: [],
      pos: { x: window.innerWidth / 2, y: window.innerHeight / 2 },
    },
    orientation: 0,
    panLeft: 0,
    panTop: 0,
    previewingBrush: false,
    promptModal: {
      open: false,
      message: 'Are you sure?',
      callback: undefined,
    },
    renaming: false,
    replaceImageId: '',
    saving: false,
    sdfShapeBrowser: false,
    saveDesignDebouncer: {},
    selectedItems: [],
    initialChangeStates: [],
    initialHistory: [],
    signUpForPro: false,
    scale: 1,
    screenSizes: computed(() => SCREEN_SIZES),
    shape: {},
    size: 'Desktop',
    text: {},
    tick: undefined,
    tool: 'selector',
    undoPointer: -1,
    userZoom: 1,
    versionId: '',
    versions: [],
    versionsOpen: false,
  }),
  init(id, callback) {
    DesignsStore.getDesign(id)
      .then(design => {
        this.state.design = design;
        this.state.design.id = id;

        if (StudioStore.state.screenSizes.find(n => n.name === this.state.design.size)) {
          this.state.size = this.state.design.size;
        } else {
          this.state.size = 'Desktop';
        }

        if (this.state.size === 'Custom') {
          this.state.customWidth = design.customWidth || 1080;
          this.state.customHeight = design.customHeight || 1080;
        }

        this.unpackVersion(design.versionId).then(history => {
          this.state.history = history;
          this.createFontScript(this.state.history.filter(n => n.fontFamily));

          if (!this.state.history.length) {
            this.state.history = [
              new Effect({
                type: 'gradient',
                fill: ['#000000'],
                isBackground: true,
              }),
            ];
          }

          if (!this.state.design.editorVersion) {
            this.state.design.editorVersion = DesignsStore.state.version;
          }

          this.state.saveDesignDebouncer = new Debouncer({
            fn: this.saveDesignState,
            interval: 500,
          });

          if (callback) {
            callback();
          }
        });
      })
      .catch(error => {
        console.log(error);
      });
  },
  refreshDesign(callback) {
    DesignsStore.getDesign(this.state.design.id).then(design => {
      StudioStore.unpackVersion(design.versionId).then(history => {
        if (callback) {
          callback(history);
        }
      });
    });
  },
  async unpackVersion(vid) {
    return await DesignsStore.getVersion(vid).then(versionDoc => {
      let version = versionDoc.data();
      this.versionId = versionDoc.id;
      return unpackageHistory(version.history);
    });
  },
  unpackageHistory(history) {
    return unpackageHistory(history);
  },
  createFontScript(items) {
    if (items.length) {
      for (let i = 0; i < items.length; i++) {
        items[i].fontCSS.src = items[i].fontCSS.src.split(' ').join('%20');
        const fontFace = new FontFace(items[i].fontFamily, `url(${items[i].fontCSS.src.replace('http:', 'https:')})`, {
          style: items[i].fontStyle.includes('italic') ? 'italic' : 'normal',
          weight: isNaN(parseInt(items[i].fontStyle)) ? 400 : parseInt(items[i].fontStyle),
        });
        document.fonts.add(fontFace);
        fontFace.load().then(() => {
          requestAnimationFrame(() => {
            items[i].render();
            StudioStore.renderNFrames(2);
          });
        });
      }
    }
  },

  createCurtains(scale, dpi, animate) {
    try {
      const curtain = new Curtains({
        container: 'filter-output',
        premultipliedAlpha: true,
        antialias: false,
        autoRender: false,
        autoResize: false,
        watchScroll: false,
        renderingScale: Math.min(1, scale) || 1,
        production: window.location.hostname.includes('unicorn.studio'),
        pixelRatio: Math.min(Math.min(dpi || 1.5, 2), this.state.dpi),
      });

      this.state.curtain = curtain;

      // Add error handling for disposal
      this.state.curtain.onError(() => {
        console.warn('Curtains encountered an error');
        this.state.webglError = true;
      });

      this.state.curtain.onContextLost(() => {
        console.warn('Curtains lost WebGL context');
        this.state.curtain.restoreContext();
        this.state.webglError = true;
      });

      this.state.curtain.onContextRestored(() => {
        this.state.webglError = false;
      });

      this.state.curtain.setPixelRatio(StudioStore.getPixelRatio());
    } catch (err) {
      console.error('Error creating Curtains instance:', err);
      this.state.webglError = true;
    }
  },
  getCurrentSize() {
    if (StudioStore.state.size === 'Custom') {
      return createSize('Custom', [StudioStore.state.customWidth, StudioStore.state.customHeight]);
    }
    return StudioStore.state.screenSizes.find(n => n.name === StudioStore.state.size);
  },
  getPixelRatio(scale) {
    let realPixels = StudioStore.state.curtain.container.getBoundingClientRect().width;
    const curtainResolution = this.state.currentSize.realDimensions[0] / realPixels;
    let dpi = this.state.dpi;
    if (this.state.export.active) {
      dpi = curtainResolution;
    }
    return dpi * (scale || StudioStore.state.scale);
  },
  fullRedraw() {
    if (!isCurtainsValid(this.state.curtain)) {
      console.warn('Curtains instance not valid during fullRedraw');
      return;
    }
    this.state.fullRedrawEnabled = true;
    this.renderFrame();
    this.state.fullRedrawEnabled = false;
  },
  clearAll() {
    const command = new ClearCommand(this.state.history);
    StudioStore.performAction(command);
    StudioStore.saveDesignDebounced(null, true);
  },
  duplicateItem(item) {
    let toDuplicate = item || this.getSelectedItem();
    if (toDuplicate?.isBackground) {
      return false;
    }
    if (toDuplicate && !this.state.duplicating) {
      this.state.duplicating = true;

      const duplicateCopy = toDuplicate.copy(null);
      if (duplicateCopy) {
        // Duplicate and update child effects if they exist
        if (toDuplicate.effects && toDuplicate.effects.length) {
          const newChildIds = [];
          const childEffects = toDuplicate.getChildEffectItems();
          childEffects.forEach(childEffect => {
            const childCopy = childEffect.copy(null);

            childCopy.parentLayer = childCopy.local.id;

            let childCommand = new CreateCommand(childCopy);
            StudioStore.performAction(childCommand);

            newChildIds.push(childCopy.local.id);
          });
          duplicateCopy.effects = newChildIds;
        } else if (toDuplicate.parentLayer) {
          const parent = toDuplicate.getParent();
          duplicateCopy.parentLayer = generateUUID();
          parent.effects.push(duplicateCopy.parentLayer);
        }

        if (duplicateCopy.states) {
          duplicateCopy.states.appear.forEach(n => (n.id = generateUUID()));
          duplicateCopy.states.scroll.forEach(n => (n.id = generateUUID()));
          duplicateCopy.states.hover.forEach(n => (n.id = generateUUID()));
        }

        // Execute the create command for the parent copy
        let parentCommand = new CreateCommand(duplicateCopy, toDuplicate.getIndex() + 1);
        StudioStore.performAction(parentCommand);
      }
      this.state.duplicating = false;
    }
  },
  getItemById(id) {
    return this.state.history.find(n => n.local.id === id);
  },
  removeItem(item) {
    if (this.state.customCodeItemId === item.local.id || item.isBackground) {
      return false;
    }

    if (this.state.openStateEffect) {
      return false;
    }

    const command = new DeleteCommand(item);
    StudioStore.performAction(command);
  },
  addItem(item, index) {
    const command = new CreateCommand(item, index);
    StudioStore.performAction(command);
  },
  replaceItem(newItem, oldItem) {
    const command = new ReplaceCommand(newItem, oldItem, oldItem.getIndex());
    StudioStore.performAction(command);
  },
  saveDesignDebounced(callback, savestate) {
    this.state.saveDesignDebouncer.fire([callback, savestate]);
  },
  saveDesignState() {
    if (StudioStore.state.initialized) {
      StudioStore.state.saving = true;
      StudioStore.renderNFrames(2, () => {
        compressThumbnail(StudioStore.state.curtain.canvas.toDataURL('image/webp', 0.6), 300, thumb => {
          if (thumb) {
            StudioStore.state.design.thumbnail = thumb;
          } else {
            console.error('Failed to compress thumbnail');
          }
          StudioStore.save();
        });
      });
    }
  },
  save() {
    StudioStore.state.design.updatedAt = new Date();
    DesignsStore.saveDesign(StudioStore.state.design, StudioStore.state.history, resp => {
      StudioStore.state.saving = false;
    });
  },
  setSelectedItem(item) {
    const selection = this.getSelectedItem();

    // Deselect the current item if it exists
    if (selection && selection.deselect) {
      selection.deselect();
    }

    // Check if the current item is already selected to avoid unnecessary updates
    if (item && item.local.id && (!selection || selection.local.id !== item.local.id)) {
      // Set the selected state for the new item
      item.local.isSelected = true;

      // Update the state only if there's a change
      this.state.selectedItems = [item];
    } else if (!item) {
      // Clear selection if no item is provided
      this.state.selectedItems = [];
    }
  },

  isSelected(item) {
    return (item && this.state.selectedItems.find(n => n?.local?.id === item?.local?.id)) || false;
  },
  getSelectedItem() {
    return this.state.selectedItems[0];
  },
  getCustomCodeItem() {
    return this.state.history.length ? this.state.history.find(n => n.local.id === this.state.customCodeItemId) : [];
  },
  getCanvasUIItems() {
    if (this.state.customCodeItemId) {
      return [this.getCustomCodeItem()];
    } else {
      return this.state.history.filter(
        n =>
          n.visible &&
          !n.locked &&
          !n.fitToCanvas &&
          n.type !== 'fxaa' &&
          (!n.parentLayer || (n.parentLayer && n.getParent() && n.getParent().visible))
      );
    }
  },
  // Plane handling
  getPassPlane(item, index) {
    return this.state.curtain.planes.find(n => n.userData.id === item.local.id && n.userData.passIndex === index);
  },
  getTarget(item) {
    return this.state.curtain.renderTargets.find(n => n.userData.id === item.local.id);
  },
  getPlaneTarget(plane) {
    return this.state.curtain.renderTargets.find(n => n.userData.id === plane.userData.id);
  },
  getTexture(plane, sampler) {
    return plane.textures.find(n => n._samplerName === sampler);
  },

  getRenderTargets() {
    return this.state.curtain.renderTargets.filter(n => n.userData.id);
  },

  getPlanes() {
    return this.state.curtain.planes.filter(n => n.type !== 'PingPongPlane');
  },

  removeUnusedPlanes() {
    const toRemove = this.getPlanes().filter(n => {
      return !this.state.history.find(o => o.local.id === n.userData.id);
    });

    toRemove.forEach(plane => {
      if (plane.target) {
        plane.target.remove();
      } else {
        if (this.getRenderTargets().at(-1)) {
          this.getRenderTargets().at(-1).remove();
        }

        if (plane.userData.passIndex !== undefined && this.getRenderTargets().at(-1)) {
          this.getRenderTargets().at(-1).remove();
        }
      }
      plane.remove();
    });
  },

  createPlane(item, renderOrder, passValues) {
    let params = basicParams;

    if (!item.isElement) {
      if (item.type === 'custom') {
        params = Object.assign({}, EFFECTS.custom.params);
        if (item.customFragmentShaders.length) {
          params.fragmentShader = item.customFragmentShaders[passValues ? passValues.index : 0];
        }
        if (item.customVertexShaders.length) {
          params.vertexShader = item.customVertexShaders[passValues ? passValues.index : 0];
        }
      } else {
        params = EFFECTS[item.type].params;
      }

      params.crossOrigin = '';
    }

    if (params.downSample) {
      this.state.curtain.renderer._renderingScale = 0.5;
      this.state.curtain.renderer.setSize();
    } else {
      this.state.curtain.renderer._renderingScale = 1;
      this.state.curtain.renderer.setSize();
    }

    if(!document.getElementById('plane_source')) {
      throw new Error('Can\'t find plane source');
    }

    const plane = new Plane(this.state.curtain, document.getElementById('plane_source'), params);

    if (!plane || !plane.userData || !plane.textures) {
      throw new Error('Plane not properly initialized');
    }
    plane.textures.length = 0;
    plane.userData.id = item.local.id;
    plane.userData.layerType = item.layerType;
    plane.userData.type = item.type;

    if (item.isBackground) {
      plane.setRenderOrder(-9999);
    } else {
      plane.setRenderOrder(renderOrder);
    }

    //item.setBreakpointValues();

    return plane;
  },

  createPingPongPlane(item, i) {
    let params;
    switch(item.type) {
      case 'waterRipple':
        params = EFFECTS.waterRipplePingPong;
        break;
      default:
        params = EFFECTS.mouseTrail;
    }
    const plane = new PingPongPlane(this.state.curtain, document.getElementById('plane_source'), params);

    const parent = item.getParent();

    if (!plane) return;

    plane.userData.id = item.local.id;
    plane.userData.pingPongId = item.local.id;
    plane.setRenderOrder(i);

    plane
      .onReady(() => {
        plane.userData.isReady = true;
        plane.userData.createdAt = performance.now();
      })
      .onRender(() => this.setEffectPlaneUniforms(plane, item, parent));

    return plane;
  },

  createEffectPlane(item, i, passValues) {
    const plane = this.createPlane(item, i, passValues);
    const parent = item.getParent();

    if (!plane) return;
    if (EFFECTS[item.type].downSample) {
      plane.userData.downSample = EFFECTS[item.type].downSample;
    }

    if (passValues) {
      plane.userData.passIndex = passValues.index;
      plane.userData.downSample = passValues.downSample;
      plane.userData.includeBg = passValues.includeBg;
      plane.userData.length = EFFECTS[item.type].passes.length;

      Object.entries(passValues).forEach(([prop, value]) => {
        if (plane.uniforms[prop]) plane.uniforms[prop].value = value;
      });
    }

    plane
      .onReady(() => {
        plane.userData.isReady = true;
        plane.userData.createdAt = performance.now();
      })
      .onRender(() => this.setEffectPlaneUniforms(plane, item, parent, passValues));
  },

  createElementPlane(item, i) {
    const plane = this.createPlane(item, i);
    if (plane) {
      plane
        .onReady(() => {
          plane.userData.isReady = true;
          plane.userData.createdAt = performance.now();
        })
        .onRender(() => this.setElementPlaneUniforms(plane, item));
    }
  },

  handleEffectPlane(item, i, data) {
    const plane = 'passIndex' in data ? this.getPassPlane(item, data.passIndex) : item.getPlane();
    let target = this.getRenderTargets()[i - 1];
    let pingpong = this.state.curtain.planes.find(n => n.userData?.pingPongId === item.local.id);

    if (target) {
      plane.createTexture({
        sampler: 'uTexture',
        premultipliedAlpha: true,
        fromTexture: target.getTexture(),
      });
    } else {
      plane.createTexture({
        sampler: 'uTexture',
      });
    }
    if (pingpong && (item.type === 'mouse' || item.type === 'waterRipple')) {
      plane.createTexture({
        sampler: 'uPingPongTexture',
        premultipliedAlpha: true,
        fromTexture: pingpong.getTexture(),
      });
    }

    let bgTarget = this.getRenderTargets()[i - (1 + data.passIndex)];
    if (data.length && bgTarget) {
      if (bgTarget.uuid !== target.uuid) {
        plane.createTexture({
          sampler: 'uBgTexture',
          premultipliedAlpha: true,
          fromTexture: bgTarget.getTexture(),
        });
      }
    }

    [item.texture, EFFECTS[item.type].texture]
      .filter(n => n?.src)
      .forEach(texture => {
        plane.loadImage(
          texture.src,
          {
            sampler: texture.sampler,
            premultipliedAlpha: false,
          },
          tex => {
            this.renderFrame();
          }
        );
      });
  },

  handleElementPlane(item, i) {
    const plane = item.getPlane();
    const effects = item.getChildEffectItems().filter(n => n.visible);
    const items = this.state.history.filter(n => !n.parentLayer);
    let target = this.getRenderTargets()[i - 1];
    let previousLayer = items[items.indexOf(item) - 2];
    let previousLayerTarget;

    if (item.mask && previousLayer) {
      previousLayerTarget = previousLayer.local.lastTarget;
    }

    if (target && effects.length) {
      plane.createTexture({
        sampler: 'uTexture',
        premultipliedAlpha: true,
        fromTexture: target.getTexture(),
      });
    } else {
      if (item.src && item.src.includes('.webm')) {
        plane.loadVideo(
          item.src,
          {
            premultipliedAlpha: true,
            sampler: 'uTexture',
          },
          vid => {
            plane.videos.at(-1).play();
          }
        );
      } else {
        plane.loadCanvas(item.local.canvas, {
          premultipliedAlpha: true,
          sampler: 'uTexture',
        });
        
      }
    }

    if (target) {
      if (effects.length) {
        let planeCount = effects.reduce((a, b) => a + b.getPlanes().length, 0);
        const mouseTrails = effects.filter(n => n.type === 'mouse' || n.type === 'waterRipple').length;
        target = this.getRenderTargets()[i - (1 + planeCount - mouseTrails)];
      }
      if (target) {
        plane.createTexture({
          sampler: 'uBgTexture',
          premultipliedAlpha: true,
          fromTexture: target.getTexture(),
        });

        if (previousLayerTarget && item.mask) {
          plane.createTexture({
            sampler: 'uPreviousLayerTexture',
            premultipliedAlpha: true,
            fromTexture: previousLayerTarget.getTexture(),
          });
        }
      }
    }
  },

  handleChildEffectPlane(item, i, data) {
    const plane = 'passIndex' in data ? this.getPassPlane(item, data.passIndex) : item.getPlane();
    const parent = item.getParent();
    if(!parent) return;
    let target = this.getRenderTargets()[i - 1];
    let pingpong = this.state.curtain.planes.find(
      n => n.userData.pingPongId && n.userData.pingPongId === item.local.id
    );
    let effects = parent?.effects?.filter(n => {
      if (this.state.history.find(o => o.parentLayer === n)) {
        return this.state.history.find(o => o.parentLayer === n).visible;
      }
    }) || [];
    let effectIndex = effects.indexOf(item.parentLayer);
    let lastEffect = effects.at(-1) === effects[effectIndex];
    let lastPass = data.passIndex === data.length;

    if (pingpong && (item.type === 'mouse' || item.type === 'waterRipple')) {
      plane.createTexture({
        sampler: 'uPingPongTexture',
        premultipliedAlpha: true,
        fromTexture: pingpong.getTexture(),
      });
    }

    if (plane.userData.includeBg) {
      plane.loadCanvas(parent.local.canvas, {
        premultipliedAlpha: true,
        sampler: 'uBgTexture',
      });
    }

    if (target && (effectIndex || data.passIndex)) {
      plane.createTexture({
        sampler: 'uTexture',
        premultipliedAlpha: true,
        fromTexture: target.getTexture(),
      });
      if (item.isMask) {
        if (!data.length || (lastEffect && lastPass)) {
          plane.loadCanvas(parent.local.canvas, {
            premultipliedAlpha: true,
            sampler: 'uMaskTexture',
          });
        }
      }
    } else {
      if (item.isMask) {
        if (lastEffect && lastPass) {
          plane.loadCanvas(parent.local.canvas, {
            premultipliedAlpha: true,
            sampler: 'uMaskTexture',
          });
        }
        if (target) {
          plane.createTexture({
            sampler: 'uTexture',
            premultipliedAlpha: true,
            fromTexture: target.getTexture(),
          });
        }
      } else {
        plane.loadCanvas(parent.local.canvas, {
          premultipliedAlpha: true,
          sampler: 'uTexture',
        });
      }
    }

    [item.texture, EFFECTS[item.type].texture]
      .filter(n => n?.src)
      .forEach(texture => {
        plane.loadImage(
          texture.src,
          {
            sampler: texture.sampler,
            premultipliedAlpha: false,
          },
          tex => {
            this.renderFrame();
          }
        );
      });

    if (item.type === 'custom' && this.getRenderTargets()[i]) {
      plane.createTexture({
        sampler: 'uBgTexture',
        premultipliedAlpha: true,
        fromTexture: this.getRenderTargets()[i],
      });
    }
  },

  createPlanes() {
    this.getOrderedItems().forEach((item, i) => {
      if (!item.getPlanes().length) {
        if (item.isElement) {
          this.createElementPlane(item, i);
        } else {
          this.createEffectPlanes(item, i);
        }
      } else {
        if (item.isBackground) {
          item.getPlane().setRenderOrder(-9999);
        } else {
          item.getPlanes().forEach(plane => plane.setRenderOrder(i));
        }
      }
    });
  },

  createEffectPlanes(item, i) {
    const effect = EFFECTS[item.type];
    if (effect.passes && effect.passes.length) {
      this.createEffectPlane(item, i, {
        index: 0,
        downSample: effect.downSample,
        length: effect.passes.length + 1,
      });
      effect.passes.forEach((pass, index) => {
        this.createEffectPlane(item, i, {
          index: index + 1,
          length: effect.passes.length + 1,
          downSample: pass.downSample,
          [pass.prop]: pass.value,
          includeBg: pass.includeBg,
        });
      });
    } else {
      this.createEffectPlane(item, i);
    }
    if (item.type === 'mouse' || item.type === 'waterRipple') {
      this.createPingPongPlane(item, i);
    }
  },

  createTextures() {
    const orderedPlanes = this.getPlanes()
      .filter(n => n.visible)
      .sort((a, b) => a.renderOrder - b.renderOrder);

    const len = orderedPlanes.length;

    for (let i = 0; i < len; i++) {
      const plane = orderedPlanes[i];
      let item = this.state.history.find(n => n.local.id === plane.userData.id);
      if (i < len - 1) {
        this.assignRenderTargetToPlane(i, plane);
      }
      this.handleTextures(item, i, plane.userData);
      item.local.lastTarget = plane.target;
    }
  },

  assignRenderTargetToPlane(i, plane) {
    let downSample = 1;
    if(plane.userData.downSample) {
      downSample = typeof plane.userData.downSample === 'number' ? plane.userData.downSample : 0.5;
    }
    let renderTarget =
      this.getRenderTargets()[i] ||
      new RenderTarget(this.state.curtain, {
        depth: plane.userData?.type === 'bulge',
        maxWidth: this.state.curtain.canvas.width * downSample,
        maxHeight: this.state.curtain.canvas.height * downSample,
      });
    renderTarget.userData.id = plane.userData.id;
    plane.setRenderTarget(renderTarget);
  },

  handleTextures(item, i, planeUserData) {
    if (item.isElement) {
      this.handleElementPlane(item, i);
    } else {
      item.parentLayer
        ? this.handleChildEffectPlane(item, i, planeUserData)
        : this.handleEffectPlane(item, i, planeUserData);
    }
  },

  handleItemPlanes(callback, args) {
    if (!isCurtainsValid(this.state.curtain)) {
      console.warn('Curtains instance not valid during handleItemPlanes');
      if (callback) callback();
      return;
    }
    this.state.handlingPlanes = true;

    if (this.state.initialized) {
      this.removeUnusedPlanes();
    }

    if (args) this.handleArgs(args);

    this.createPlanes();
    this.createTextures();
    this.checkIfReady(callback);
  },

  checkIfReady(callback) {
    const performCheck = () => {
      if (this.state.curtain.planes.filter(n => !n.userData.isReady).length) {
        if (!this.state.initialized || !this.state.animatingEffects.length) {
          this.renderFrame();
        }
        requestAnimationFrame(performCheck);
      } else {
        this.state.handlingPlanes = false;
        callback();
      }
    };

    performCheck();
  },

  handleStateEffects(plane, item) {
    if (
      !item.states.appear.some(effect => !effect.complete) &&
      !item.states?.scroll.length &&
      !item.states?.hover.length
    )
      return;

    item.states.appear.forEach(effect => {
      effect.updateEffect(plane, item[effect.prop]);
    });

    item.states.hover.forEach(effect => {
      effect.updateEffect(plane, item[effect.prop], this.state.mouse.enterTime);
    });

    item.states.scroll.forEach(effect => {
      const dims = StudioStore.state.curtain.container.getBoundingClientRect();
      let height = dims.height / 2;
      let width = dims.width / 2;
      let top = 0;

      effect.updateEffect(plane, item[effect.prop], {
        top,
        height,
        width,
        scroll: -this.state.panTop,
      });
    });
  },

  setElementPlaneUniforms(plane, item) {
    plane.uniforms.opacity.value = item.visible ? item.opacity : 0;
    plane.uniforms.axisTilt.value = item.axisTilt || 0;

    let childeffects = item.getChildEffectItems();

    if (childeffects.some(n => n.isMask)) {
      plane.uniforms.trackMouse.value = 0;
    } else {
      plane.uniforms.trackMouse.value = item.trackMouse || 0;
    }

    if (plane.uniforms.mousePos) {
      let xPos = this.state.mouse.pos.x;
      let yPos = this.state.mouse.pos.y;

      if (item.mouseMomentum && item.local.lastMousePos) {
        let lastXPos = item.local.lastMousePos.x * this.state.canvasWidth;
        let lastYPos = (1 - item.local.lastMousePos.y) * this.state.canvasHeight;

        xPos = weightedLerp(xPos, lastXPos, item.mouseMomentum * 2);
        yPos = weightedLerp(yPos, lastYPos, item.mouseMomentum * 2);
      } else {
        item.local.lastMousePos = {
          x: 0.5,
          y: 0.5,
        };
      }

      plane.uniforms.mousePos.value.x = xPos / this.state.canvasWidth;
      plane.uniforms.mousePos.value.y = 1 - yPos / this.state.canvasHeight;

      item.local.lastMousePos.x = xPos / this.state.canvasWidth;
      item.local.lastMousePos.y = 1 - yPos / this.state.canvasHeight;
    }

    if (plane.uniforms.displace) {
      plane.uniforms.displace.value = item.displace;
      plane.uniforms.bgDisplace.value = item.bgDisplace;
      plane.uniforms.dispersion.value = item.dispersion;
    }

    if (plane.uniforms.blendMode) {
      plane.uniforms.blendMode.value = this.blendModeKeys.indexOf(item.blendMode);
    }
    if (plane.renderOrder - childeffects.length === 0) {
      plane.uniforms.sampleBg.value = 0;
    } else {
      plane.uniforms.sampleBg.value = 1;
    }

    if (plane.uniforms.mask && 'mask' in item) {
      plane.uniforms.mask.value = item.mask;
      plane.uniforms.maskDepth.value = item.maskDepth;
      plane.uniforms.maskAlpha.value = item.maskAlpha || 0;
      plane.uniforms.maskBackground.value.x = item.maskBackground.x;
      plane.uniforms.maskBackground.value.y = item.maskBackground.y;
      plane.uniforms.maskBackground.value.z = item.maskBackground.z;
    }

    plane.uniforms.resolution.value.x = this.state.curtain.canvas.width;
    plane.uniforms.resolution.value.y = this.state.curtain.canvas.height;

    if (
      item.local.ctx &&
      (this.state.fullRedrawEnabled || (this.isSelected(item) && !this.state.animatingEffects.length))
    ) {
      item.render();
    }
    this.handleStateEffects(plane, item);

  },

  setEffectPlaneUniforms(plane, item, parent) {
    if (!plane.userData.uniformsSet) {
      this.updatePlaneUniforms(plane, item, parent);

      plane.userData.uniformsSet = true;
    }
    
    if (item.animating && plane.uniforms.time) {
      const multiplier = this.state.export.downloading ? 60/this.state.export.fps : 1;
      plane.uniforms.time.value += (item.speed || 1) * multiplier;
    }

    plane.uniforms.resolution.value.x = this.state.curtain.canvas.width;
    plane.uniforms.resolution.value.y = this.state.curtain.canvas.height;

    this.updateMouseUniforms(plane, item);
    this.handleStateEffects(plane, item);
  },

  updatePlaneUniforms(plane, item, parent) {
    for (let prop in item) {
      if (!(prop in plane.uniforms)) continue;
      if (item.states.appear.some(effect => effect.prop === prop && !effect.complete)) continue;
      if (item.states.scroll.some(effect => effect.prop === prop)) continue;

      if (prop === 'trackMouse') {
        if (item.isMask && plane.uniforms.parentTrackMouse && parent && 'trackMouse' in parent) {
          plane.uniforms.parentTrackMouse.value = parent.trackMouse;
        }
        plane.uniforms.trackMouse.value = item.trackMouse;
      } else if (prop === 'pos') {
        plane.uniforms[prop].value.x = item.pos.x;
        plane.uniforms[prop].value.y = 1 - item.pos.y;
      } else {
        plane.uniforms[prop].value = item[prop];
      }
    }

    if (parent && 'index' in plane.userData && plane.userData.index < plane.userData.length - 1) {
      plane.uniforms.isMask.value = 0;
    }

    if (plane.uniforms.count && item.fill) {
      const definedColors = item.fill.length;

      // Convert colors and set uniforms
      item.fill.forEach((color, i) => {
        let rgb = hexToRgb(color);
        if (rgb) {
          const rgb3f = rgb.map(v => v / 255);
          if (plane.uniforms[`color${i}`]) {
            plane.uniforms[`color${i}`].value = new Vec3(...rgb3f);
          }
        }
      });

      // Update count
      plane.uniforms.count.value = definedColors;
    }

    if (plane.uniforms.blendMode) {
      plane.uniforms.blendMode.value = this.blendModeKeys.indexOf(item.blendMode);
    }

    if (item.animating && plane.uniforms.time) {
      plane.uniforms.time.value +=
        ((item.speed || 1) * 60) / (this.state.export.downloading ? this.state.export.fps : 60);
    }

    if (parent && item.isMask && !item.mouseMomentum) {
      item.mouseMomentum = parent.mouseMomentum;
    }
  },

  updateMouseUniforms(plane, item) {
    if (plane.uniforms.mousePos && this.state.mouse.moved) {
      let xPos = this.state.mouse.pos.x;
      let yPos = this.state.mouse.pos.y;

      if (item.mouseMomentum && item.type !== 'mouse') {
        if (!item.local.lastMousePos) {
          item.local.lastMousePos = {
            x: xPos / this.state.canvasWidth,
            y: 1 - yPos / this.state.canvasHeight,
          };
        }

        let lastXPos = item.local.lastMousePos.x * this.state.canvasWidth;
        let lastYPos = (1 - item.local.lastMousePos.y) * this.state.canvasHeight;

        if (lastXPos === xPos && lastYPos === yPos) {
          return;
        }

        xPos = weightedLerp(xPos, lastXPos, item.mouseMomentum * 2);
        yPos = weightedLerp(yPos, lastYPos, item.mouseMomentum * 2);

        item.local.lastMousePos.x = xPos / this.state.canvasWidth;
        item.local.lastMousePos.y = 1 - yPos / this.state.canvasHeight;
      }

      plane.uniforms.mousePos.value.x = xPos / this.state.canvasWidth;
      plane.uniforms.mousePos.value.y = 1 - yPos / this.state.canvasHeight;
    }

    if (plane.uniforms.previousMousePos) {
      if (this.state.mouse.trail.length > 1) {
        let lastPos = this.state.mouse.trail.at(1);
        plane.uniforms.previousMousePos.value.x = lastPos[0];
        plane.uniforms.previousMousePos.value.y = lastPos[1];
      } else {
        plane.uniforms.previousMousePos.value.x = plane.uniforms.mousePos.value.x;
        plane.uniforms.previousMousePos.value.y = plane.uniforms.mousePos.value.y;
      }
    }
  },

  isHiddenFirstChildEffect(item) {
    if (item.parentLayer && item.getParent().effects.length > 1) {
      const isFirst = item.getParent().effects.indexOf(item.parentLayer) === 0;
      return isFirst;
    } else {
      return false;
    }
  },

  removeRenderTargets() {
    this.getRenderTargets().forEach(target => target.remove());
  },

  clearAllTextures() {
    this.getPlanes().forEach(plane => {
      plane.textures = plane.textures.filter(n => n._samplerName === 'uBlueNoise');
    });
  },

  handleArgs(args) {
    if (args.reorder || (args.changed && this.isHiddenFirstChildEffect(args.changed))) {
      this.removeRenderTargets();
      this.clearAllTextures();
    }
    if (args.changed && this.isHiddenFirstChildEffect(args.changed)) {
      this.clearAllTextures();
    }
  },

  getOrderedItems() {
    let orderedItems = [];
    this.state.history
      .filter(n => !n.parentLayer && n.visible)
      .forEach(item => {
        if (item.effects && item.effects.length) {
          orderedItems.push(...item.getChildEffectItems());
        }
        orderedItems.push(item);
      });
    return orderedItems;
  },

  renderCurtainFPS(fps) {
    let lastTime = performance.now();
    cancelAnimationFrame(this.state.curtainRafId);

    const frameDuration = 1000 / (fps || 60);

    const animateCurtain = now => {
      if (this.state.curtain && this.state.animatingEffects.length) {
        const elapsed = now - lastTime;
        if (elapsed >= frameDuration) {
          this.renderFrame();  

          if (this.state.mouse.moved) {
            this.state.mouse.trail.unshift([
              this.state.mouse.pos.x / this.state.canvasWidth,
              1 - this.state.mouse.pos.y / this.state.canvasHeight,
            ]);
            if (this.state.mouse.trail.length > 4) {
              this.state.mouse.trail.pop();
            }
          }

          lastTime = now;
        }
        this.state.curtainRafId = requestAnimationFrame(animateCurtain);
      } else {
        cancelAnimationFrame(this.state.curtainRafId);
      }
    };

    this.fullRedraw();
    this.state.curtainRafId = requestAnimationFrame(animateCurtain);
  },

  renderFrame() {
    if(isCurtainsValid(this.state.curtain)) {
      this.state.curtain.render();
    } else {
      console.warn('Curtains instance is not valid');
    }
  },

  renderNFrames(count, callback) {
    let index = 0;
    const renderFrame = () => {
      this.renderFrame();
      if (index < count) {
        index++;
        requestAnimationFrame(renderFrame);
      } else if (callback) {
        callback();
      }
    };
    renderFrame();
  },

  refreshPlanes(callback, item, reorder) {
    StudioStore.handleItemPlanes(
      () => {
        this.state.history
          .filter(n => n.render)
          .forEach(item => {
            if (item.resize) {
              item.resize();
            }
            item.render();
          });
        StudioStore.renderNFrames(4);

        if (callback) {
          callback();
        }
      },
      { changed: item, reorder: reorder }
    );
  },

  setScale(scale, callback) {
    this.state.scale = +scale;

    if (this.state.export.active) {
    }
    this.state.curtain.setPixelRatio(this.getPixelRatio(scale));

    requestAnimationFrame(() => {
      this.state.history
        .filter(n => n.render)
        .forEach(item => {
          if (item.resize) {
            item.resize(scale);
          }
          item.render();
        });

      requestAnimationFrame(() => {
        this.renderFrame();
        if (callback) {
          callback();
        }
      });
    });
  },

  performAction(command, silent) {
    if (!silent) {
      command.execute();
    }
    this.state.actionHistory.splice(this.state.undoPointer + 1);
    this.state.actionHistory.push(command);
    this.state.undoPointer++;
  },
  undo() {
    if (this.state.undoPointer >= 0) {
      const command = this.state.actionHistory[this.state.undoPointer];
      command.unexecute();
      this.state.undoPointer--;
      this.renderFrame();
    } else {
      console.log('No more undos');
    }
  },
  redo() {
    if (this.state.undoPointer + 1 < this.state.actionHistory.length) {
      this.state.undoPointer++;
      const command = this.state.actionHistory[this.state.undoPointer];
      command.execute();
    } else {
      console.log('No more redos');
    }
  },
  handlePreview() {
    this.state.previewing = !this.state.previewing;

    if (this.state.previewing) {
      this.state.curtain.planes.forEach(plane => {
        plane.userData.createdAt = performance.now();
      });
      this.state.history
        .filter(n => n.states && n.states.appear.length)
        .forEach(item => {
          item.states.appear.forEach(effect => {
            effect.resetState();
          });
        });
    }
  },
};

export { StudioStore };
